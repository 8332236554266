export interface GoogleRegisterDto {
    name: string
    surname?: string
    gender?: string
    birthDate?: Date
    email: string
    companyWebsite: string
    googleToken: string
}

export function validateGoogleRegisterDto(dto: GoogleRegisterDto): boolean {
    const { name, email, companyWebsite } = dto;
    return !(name == null || email == null || companyWebsite == null);


}
