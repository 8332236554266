import {CompanySize} from "./CompanySize";

export enum CompanyType {
    PUBLIC_COMPANY = 'Public Company',
    SELF_EMPLOYED = 'Self-employed',
    GOVERNMENT_AGENCY = 'Government Agency',
    NONPROFIT = 'Nonprofit',
    SOLE_PROPRIETORSHIP = 'Sole Proprietorship'
}

export const companyTypeMap = new Map<string, string>(
    Object.keys(CompanyType).map(key => [key, CompanyType[key as keyof typeof CompanyType]])
);

export function getCompanyTypeByOrdinal(ordinal: number): string | null {
    const companySizes = Object.keys(CompanyType) as Array<keyof typeof CompanyType>;

    if (ordinal >= 0 && ordinal < companySizes.length) {
        const key = companySizes[ordinal];
        return CompanyType[key];
    }

    return null; // Return null if the ordinal is out of range
}