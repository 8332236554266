<script lang="ts">
    import { writable } from "svelte/store";
    import { onMount } from "svelte";
    import {
        isAgeValid,
        isDayValid,
        isYearValid
    } from "../../../../validators/common/AgeValidation";

    export let isValid = false;

    let day = writable(undefined);
    let month = writable(undefined);
    let year = writable(undefined);

    export let birthDate: string;
    let validationResult: ValidationResult = { isValid: false, message: '' };

    $: {
        birthDate = convertDateStringToDateDayMonthYear(`${$year}-${$month}-${$day}`);

        const dayValid = isDayValid(parseInt($day), parseInt($month), parseInt($year));
        const yearValid = isYearValid(parseInt($year));

        if (!dayValid.isValid) {
            validationResult = dayValid;
            isValid = false;
        } else if (!yearValid.isValid) {
            validationResult = yearValid;
            isValid = false;
        } else {
            const ageValidation = isAgeValid(new Date(birthDate));
            validationResult = ageValidation;
            isValid = ageValidation.isValid;
        }
    }

    export let dayInput: HTMLInputElement

    onMount(() => {
        isValid = false;
        dayInput.focus()
    });

    function convertDateStringToDateDayMonthYear(dateString: string): string {
        const [year, month, day] = dateString.split('-').map(Number);
        return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
</script>

<div class="date-input-container flex gap-3 items-center flex-col w-full mt-5">
    <div class="flex gap-4 w-full justify-center">
        <input
                type="number"
                class="date-input day-month-input"
                placeholder="DD"
                bind:this={dayInput}
                bind:value={$day}
                min="1"
                max="31"
        />
        <input
                type="number"
                class="date-input day-month-input"
                placeholder="MM"
                bind:value={$month}
                min="1"
                max="12"
        />
        <input
                type="number"
                class="date-input year-input"
                placeholder="YYYY"
                max="{new Date().getFullYear()}"
                bind:value={$year}
        />
    </div>

    <!-- Display validation message using DaisyUI alerts -->
    {#if $day !== undefined || $month !== undefined || $year !== undefined}
        {#if validationResult.message}
            <div class=" shadow-lg max-w-96 {`alert ${isValid ? 'alert-success' : 'alert-error'}`}">
                <div>
                    <span>{validationResult.message}</span>
                </div>
            </div>
        {/if}
    {/if}
</div>

<style>
    .date-input-container {
        /* Adjust container styling as needed */
    }

    .date-input {
        padding: 0.75rem; /* Larger padding for better touch target */
        font-size: 1.25rem; /* Larger text for better readability */
        text-align: center; /* Center the text */
        border: 1px solid #ccc; /* Basic border styling */
        border-radius: 6px; /* Rounded corners */
    }

    .day-month-input {
        width: 100px; /* Adjust width for day and month */
    }

    .year-input {
        width: 150px; /* Wider input for year */
    }
</style>
