import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = get(t);

export function isWebsiteValid(url: string): ValidationResult {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;

    if (!urlPattern.test(url)) {
        return {
            isValid: false,
            message: translate('invalidWebsiteURL'), // Translation for "Website URL is not valid. Please enter a valid URL."
        };
    }

    return {
        isValid: true,
        message: translate('validWebsiteURL'), // Translation for "Website URL is valid."
    };
}
