import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = get(t);

export function validateNameAndSurname(name: string, surname: string): ValidationResult {
    const minLength = 1;
    const maxLength = 50;
    const namePattern = /^[a-zA-Z\s'’áéíóúÁÉÍÓÚ]+$/;

    // Validate name
    if (name.length < minLength) {
        return {
            isValid: false,
            message: translate('nameTooShort').replace('{minLength}', minLength.toString()), // Translation for "Name must be at least {minLength} character long."
        };
    }

    if (name.length > maxLength) {
        return {
            isValid: false,
            message: translate('nameTooLong').replace('{maxLength}', maxLength.toString()), // Translation for "Name must be no more than {maxLength} characters long."
        };
    }

    if (!namePattern.test(name)) {
        return {
            isValid: false,
            message: translate('invalidNameFormat'), // Translation for "Name can only contain letters, spaces, hyphens, and apostrophes."
        };
    }

    // Validate surname
    if (!surname || surname.length < minLength) {
        return {
            isValid: false,
            message: translate('surnameTooShort').replace('{minLength}', minLength.toString()), // Translation for "Surname must be at least {minLength} character long."
        };
    }

    if (surname.length > maxLength) {
        return {
            isValid: false,
            message: translate('surnameTooLong').replace('{maxLength}', maxLength.toString())
        };
    }

    if (!namePattern.test(surname)) {
        return {
            isValid: false,
            message: translate('invalidSurnameFormat')
        };
    }

    return {
        isValid: true,
        message: translate('nameSurnameValid')
    };
}
