<script lang="ts">
    import {onMount} from "svelte";
    import {validateNameAndSurname} from "../../../../validators/common/NameValidators";
    import { t } from 'svelte-i18n';

    export let isValid = false;
    export let name = '';
    export let surname = '';

    let validationResult = {
        isValid: false,
        message: '',
    };

    function handleNameValidation() {
        validationResult = validateNameAndSurname(name, surname);
        isValid = validationResult.isValid;
    }

    $: handleNameValidation();

    let nameInput: HTMLInputElement;

    onMount(() => {
        isValid = false;
        if (nameInput) {
            nameInput.focus();
        }
    });
</script>

<div class="flex flex-col gap-8 px-6 md:px-12">
    <!-- Name Input -->
    <div class="flex flex-wrap -mx-4">
        <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <label class="label" for="name">
                <span class="label-text text-xl">{$t("Name")}</span>
            </label>
            <input
                    type="text"
                    id="name"
                    class="input input-bordered w-full text-xl h-16"
                    bind:this={nameInput}
                    bind:value={name}
                    on:input={() => handleNameValidation()}
            />
        </div>
        <div class="w-full md:w-1/2 px-4">
            <label class="label" for="surname">
                <span class="label-text text-xl">{$t("Surname")}</span>
            </label>
            <input
                    type="text"
                    id="surname"
                    class="input input-bordered w-full text-xl h-16"
                    bind:value={surname}
                    on:input={() => handleNameValidation()}
            />
        </div>
    </div>

    <!-- Display validation message using DaisyUI alerts -->
    {#if name !== "" || surname !== ""}
        {#if validationResult.message}
            <div class={`alert ${isValid ? 'alert-success' : 'alert-error'} shadow-lg`}>
                <div>
                    <span>{validationResult.message}</span>
                </div>
            </div>
        {/if}
    {/if}
</div>

<style>
    .name-input-container {
        width: 100%;
    }

    .input {
        padding: 1rem;
        font-size: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 8px;
    }
</style>
