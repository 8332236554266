<!--SkillsInputManager-->
<script lang="ts">
    import { createEventDispatcher } from 'svelte';

    export let skills: string[] = ['hola', 'chau'];

    let newSkill = '';
    const dispatch = createEventDispatcher();

    function addSkill() {
        if (newSkill.trim() && !skills.includes(newSkill)) {
            skills = [...skills, newSkill];
            dispatch('skillsChange', skills);
            newSkill = '';
        }
    }

    function removeSkill(skill: string) {
        skills = skills.filter(s => s !== skill);
        dispatch('skillsChange', skills);
    }
</script>

<div class="p-4">
    <div class="flex flex-wrap gap-2">
        {#each skills as skill}
            <div class="badge badge-primary gap-2 cursor-pointer" on:click={() => removeSkill(skill)}>
                {skill}
                <button class="ml-2 bg-transparent border-none text-white" aria-label="Remove">
                    ✕
                </button>
            </div>
        {/each}
    </div>

    <div class="mt-4 flex">
        <input
                type="text"
                class="input input-bordered w-full max-w-xs"
                bind:value={newSkill}
                placeholder="Add a skill"
                on:keydown={(e) => e.key === 'Enter' && addSkill()}
        />
        <button class="btn btn-primary ml-2" on:click={addSkill}>
            Add Skill
        </button>
    </div>
</div>
