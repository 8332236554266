<script lang="ts">
    import { t } from 'svelte-i18n';
    import { closePosition, refreshOpenPositionsShort } from "../../service/JobPostService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import { pageStore } from "../../redux/stores";
    import OpenPositions from "../content/PositionsPage.svelte";
    import type { PositionPageData } from "../../model/jobs/PositionPageData";
    import { PositionStatus } from "../../model/jobs/Position";

    export let position: PositionPageData;
    export let toogle: boolean;
    let sendEmail: boolean = false; // Default value for checkbox
    let input: HTMLInputElement;

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('close_position_modal').close();
        toogle = false;
    }

    async function close() {
        closePosition(position.id, sendEmail)
            .then(() => {
                position.status = PositionStatus.CLOSED;
                refreshOpenPositionsShort();
                pushSuccessToast($t('PositionClosed').replace("{title}", position.title));
                pageStore.set(OpenPositions);
            })
            .catch(() => pushErrorToast($t('ErrorClosingPosition')))
            .finally(() => closeModal());
    }
</script>

<input type="hidden" class="btn" onclick="close_position_modal.showModal()" bind:this={input} />
<dialog id="close_position_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{$t('ClosePositionTitle').replace("{title}", position.title)}</h3>
        <p class="py-4">{$t('ClosePositionConfirmation').replace("{title}", position.title)}</p>

        <!-- Checkbox for sending email -->
        <div class="form-control">
            <label class="cursor-pointer label">
                <span class="label-text">{$t('SendEmailToNotHiredCandidates')}</span>
                <input type="checkbox" class="checkbox" bind:checked={sendEmail} />
            </label>
        </div>

        <div class="modal-action">
            <form method="dialog">
                <button class="btn btn-primary" on:click={() => close()}>{$t('YesClosePosition')}</button>
            </form>
            <button class="btn btn-secondary" on:click={() => closeModal()}>{$t('Cancel')}</button>
        </div>
    </div>
</dialog>
