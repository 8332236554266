<script lang="ts">
    import {pushErrorToast, pushSuccessToast} from "../../service/ToastService";
    import { t } from 'svelte-i18n';

    export let email: string;
    export let phone: string;

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
            .then(() => {
             pushSuccessToast(text+" copied to clipboard!")
            })
            .catch((err) => {
                pushErrorToast("Failed to copy "+text+" to clipboard!")
            });
    }
</script>

<div class="dropdown dropdown-end">
    <label tabindex="0" class="btn btn-outline dropdown-toggle">{$t('More...')}</label>
    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        <li><a on:click={() => copyToClipboard(email)}>{email}</a></li>
        <li><a on:click={() => copyToClipboard(phone)}>{phone}</a></li>
    </ul>
</div>
