<script lang="ts">
    import { t } from 'svelte-i18n';
    import {
        breadcrumbs, loadPositionDataInModern,
        pageStore,
        positionToEdit,
        selectedPosition,
        selectedPositionId,
        userInfo
    } from "../redux/stores.js";
    import { getPositionById } from "../service/JobPostService";
    import LoadingAnimation from "../lib/LoadingAnimation.svelte";
    import { locationToString } from "../model/jobs/Location";
    import Landing from "../lib/content/Landing.svelte";
    import OpenPositions from "../lib/content/PositionsPage.svelte";
    import {
        fetchApplicantsForPositionPageDataSortedByRelevance
    } from "../service/ApplicantService";
    import UpdatePositionStatusDropdown from "../lib/content/UpdatePositionStatusDropdown.svelte";
    import Modern from "../lib/content/Modern.svelte";
    import type { PositionPageData } from "../model/jobs/PositionPageData";
    import { PositionStatus } from "../model/jobs/Position";
    import { getTimeAgo } from "../utils/DateUtils";
    import ScreeningQuestionsSection from "../lib/content/PositionDetails/ScreeningQuestionsSection.svelte";
    import HiringMoreSection from "../lib/content/PositionDetails/HiringMoreSection.svelte";
    import JobPerformanceSection from "../lib/content/PositionDetails/JobPerformanceSection.svelte";
    import JobDescriptionSection from "../lib/content/PositionDetails/JobDescriptionSection.svelte";
    import JobPostEditForm from "./JobPostEditForm.svelte";
    import { mapPositionPageDataToJobPostGeneratorDto } from "../model/jobs/JobPostGeneratorDto";
    import LinkedinForm from "../lib/CreatePosition/LinkedinForm.svelte";

    breadcrumbs.set([
        { name: $t('Home'), component: Landing },
        { name: $t('OpenPositions'), component: OpenPositions },
        { name: $t('PositionPage'), component: undefined }
    ]);

    let selectedTab = 0;

    fetchApplicantsForPositionPageDataSortedByRelevance($selectedPositionId, 0); // Fetch for applicants page
    $loadPositionDataInModern = true
    const viewApplicants = (position: PositionPageData) => {
        $selectedPosition = position;
        $pageStore = Modern;
    };

    const editPosition = (position: PositionPageData) => {
        $positionToEdit = mapPositionPageDataToJobPostGeneratorDto(position);
        pageStore.set(JobPostEditForm);
    };

    function changeTab(tab: number) {
        selectedTab = tab;
    }
</script>

{#await getPositionById($selectedPositionId)}
    <LoadingAnimation/>
{:then position}

    <!-- Top Bar Section -->
    <div class="flex flex-col min-h-screen bg-base-200 p-4">
        <div role="tablist" class="tabs tabs-boxed">
            <a role="tab"
               class="tab {selectedTab === 0 ? 'tab-active' : ''}"
               href="#"
               aria-selected="{selectedTab === 0}"
               aria-controls="position-info-tab"
               tabindex="{selectedTab === 0 ? '0' : '-1'}"
               on:click={() => changeTab(0)}
               on:keydown={(e) => (e.key === 'Enter' || e.key === ' ') && changeTab(0)}
               on:mouseover={() => document.querySelector('.tab').style.cursor = 'pointer'}>
                {$t('PositionInfo')}
            </a>
            <a role="tab"
               class="tab {selectedTab === 1 ? 'tab-active' : ''}"
               href="#"
               aria-selected="{selectedTab === 1}"
               aria-controls="linkedin-tab"
               tabindex="{selectedTab === 1 ? '0' : '-1'}"
               on:click={() => changeTab(1)}
               on:keydown={(e) => (e.key === 'Enter' || e.key === ' ') && changeTab(1)}
               on:mouseover={() => document.querySelector('.tab').style.cursor = 'pointer'}>
                {$t('LinkedIn')}
            </a>
        </div>
        <br>
        {#if selectedTab === 0}
            <div id="position-info-tab" class="flex justify-between items-center bg-base-100 p-4 shadow-lg rounded-lg mb-6 transition-opacity duration-300">
                <div>
                    <h1 class="text-2xl font-bold">{position.title}</h1>
                    <p class="text-sm text-gray-500">
                        {$userInfo.companyName} ·
                        {locationToString(position.location)} ({position.workplaceType.toString().replace("_", " ")})
                        -
                        {#if position.status === PositionStatus.OPEN}
                            {$t('Opened')} {getTimeAgo(position.createdAt)}
                        {/if}
                        {#if position.status === PositionStatus.CLOSED}
                            {$t('Closed')} {getTimeAgo(position.closedAt)}
                        {/if}
                        · {$t('ARS')} {position.paymentDataForPositionPageDto.dailyAverage.toFixed(2)} {$t('SpentOnAverage')}
                    </p>
                </div>
                <div class="space-x-4">
                    <button class="btn btn-primary" on:click={() => viewApplicants(position)}>
                        {$t('ViewApplicants')}
                    </button>
                    <UpdatePositionStatusDropdown position={position}/>
                    <!-- New Edit Job Button -->
                    <button class="btn btn-secondary" on:click={() => editPosition(position)}>
                        {$t('EditJob')}
                    </button>
                </div>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <!-- Left Section: Job Description -->
                <div class="lg:col-span-2">
                    <JobDescriptionSection position={position}/>
                </div>
                <!-- Right Section: Performance and Billing -->
                <div class="space-y-4">
                    <!-- Job Performance -->
                    <JobPerformanceSection position={position}/>
                    <!-- Hiring Section -->
                    <HiringMoreSection/>
                </div>
            </div>

            <!-- Screening Question Section -->
            <ScreeningQuestionsSection questions={position.screeningQuestions}/>
        {:else if selectedTab === 1}
            <div id="linkedin-tab" class="transition-opacity duration-300">
                <LinkedinForm positionId={$selectedPositionId} showPositionData={false}/>
            </div>
        {:else}
        {/if}
    </div>

{:catch error}
    <p style="color: red">{error.message}</p>
{/await}
