import type {Invalidator, Subscriber, Unsubscriber, Updater, Writable} from "svelte/store";

export class SelectedTheme implements Writable<string>{
    writable: Writable<string>

    constructor(writable: Writable<string>) {
        this.writable = writable
    }

    set(value: string): void {
        localStorage.setItem('theme', value)
        document.documentElement.setAttribute('data-theme', value);
        this.writable.set(value)
    }

    subscribe(run: Subscriber<string>, invalidate?: Invalidator<string>): Unsubscriber {
      return this.writable.subscribe(run, invalidate)
    }

    update(updater: Updater<string>): void {
        throw Error('Method not allowed')
    }

}