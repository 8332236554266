<script>
    import { onMount } from 'svelte';

    onMount(() => {
        // Create a script element
        const script = document.createElement('script');
        script.defer = true;
        script.setAttribute('data-domain', 'talent.astrovel.net');
        script.src = 'https://plausible.io/js/script.js';

        // Append the script to the document head
        document.head.appendChild(script);

        // Optional: Cleanup if the component is destroyed
        return () => {
            document.head.removeChild(script);
        };
    });
</script>