<script lang="ts">
    import RecruiterManagmentProfilePic from "./RecruiterManagmentProfilePic.svelte";
    import TransferOwnerModal from "./TransferOwnerModal.svelte";
    import type { Recruiter } from "../../model/recruiter/Recruiter";
    import {
        lockRecruiter,
        makeRecruiterAdmin,
        removeRecruiterAdmin,
        unlockRecruiter,
        transferOwnership
    } from "../../service/RecruiterService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import { userId, userInfo } from "../../redux/stores";
    import { t } from "svelte-i18n";
    export let recruiter: Recruiter;

    let showTransferModal = false;

    function formatDate(dateString: string): string {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    async function toggleLockRecruiter() {
        const recruiterFullName = `${recruiter.name} ${recruiter.surname}`;
        try {
            if (recruiter.locked) {
                await unlockRecruiter(recruiter.id);
                recruiter = { ...recruiter, locked: false };
                pushSuccessToast($t('recruiterUnlocked').replace("{recruiterFullName}", recruiterFullName));
            } else {
                await lockRecruiter(recruiter.id);
                recruiter = { ...recruiter, locked: true };
                pushSuccessToast($t('recruiterLocked').replace("{recruiterFullName}", recruiterFullName));
            }
        } catch (e) {
            pushErrorToast($t('toggleLockError')
                .replace("{status}", recruiter.locked ? $t('unlock') : $t('lock'))
                .replace("{recruiterFullName}", recruiterFullName)
            );
        }
    }

    async function toggleAdminRecruiter() {
        const recruiterFullName = `${recruiter.name} ${recruiter.surname}`;
        try {
            if (recruiter.isAdmin) {
                await removeRecruiterAdmin(recruiter.id);
                recruiter = { ...recruiter, isAdmin: false };
                pushSuccessToast($t('adminRightsRemoved').replace("{recruiterFullName}", recruiterFullName));
            } else {
                await makeRecruiterAdmin(recruiter.id);
                recruiter = { ...recruiter, isAdmin: true };
                pushSuccessToast($t('adminRightsGranted').replace("{recruiterFullName}", recruiterFullName));
            }
        } catch (e) {
            pushErrorToast($t('toggleAdminError')
                .replace("{status}", recruiter.isAdmin ? $t('removeAdmin') : $t('makeAdmin'))
                .replace("{recruiterFullName}", recruiterFullName)
            );
        }
    }

    function initiateTransferOwnership() {
        showTransferModal = true;
    }

    async function handleTransferConfirmation() {
        try {
            await transferOwnership(recruiter.id);
            pushSuccessToast($t('ownershipTransferred').replace("{recruiterFullName}", `${recruiter.name} ${recruiter.surname}`));
            showTransferModal = false;
        } catch (e) {
            pushErrorToast($t('ownershipTransferError'));
        }
    }
</script>

<!-- Recruiter table row -->
<tr>
    <td>
        <RecruiterManagmentProfilePic profilePic={recruiter.profilePic} name={recruiter.name} />
    </td>

    <td>{recruiter.name} {recruiter.surname}
        {#if recruiter.isOwner}
        <span class={`badge badge-success`}>
          {$t('workspaceOwner')}
        </span>
        {/if}
    </td>

    <td>{recruiter.email}</td>
    <td>{formatDate(recruiter.birthday)}</td>
    <td>{formatDate(recruiter.createdAt)}</td>

    <td>
        <span class={`badge ${recruiter.isAdmin ? 'badge-success' : 'badge-neutral'}`}>
            {recruiter.isAdmin ? $t('admin') : $t('user')}
        </span>
    </td>
    <td>
        <span class={`badge ${recruiter.locked ? 'badge-error' : 'badge-success'}`}>
            {recruiter.locked ? $t('locked') : $t('unlocked')}
        </span>
    </td>

    <td class="flex flex-wrap space-x-2 items-center">
        {#if $userInfo.isOwner && $userId !== recruiter.id}
            <div class="dropdown">
                <button tabindex="0" class="btn btn-outline btn-secondary dropdown-toggle">
                    <i class="fas fa-ellipsis-h"></i> {$t('moreActions')}
                </button>
                <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-60">
                    <li class="menu-item">
                        <a on:click={toggleAdminRecruiter} class="flex items-center space-x-2">
                            <i class="fas fa-user-cog"></i>
                            <span>{recruiter.isAdmin ? $t('removeAdmin') : $t('makeAdmin')}</span>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a on:click={toggleLockRecruiter} class="flex items-center space-x-2">
                            <i class={`fas fa-${recruiter.locked ? 'unlock' : 'lock'}`}></i>
                            <span>{recruiter.locked ? $t('unlock') : $t('lock')}</span>
                        </a>
                    </li>
                    {#if !recruiter.locked}
                        <li class="menu-item separator">
                            <a on:click={initiateTransferOwnership} class="flex items-center space-x-2">
                                <i class="fas fa-exchange-alt"></i>
                                <span>{$t('transferOwnership')}</span>
                            </a>
                        </li>
                    {/if}
                </ul>
            </div>
        {/if}
    </td>
</tr>

{#if showTransferModal}
    <TransferOwnerModal
            id={recruiter.id}
            name={recruiter.name + " " + recruiter.surname}
            on:confirm={handleTransferConfirmation}
            on:cancel={() => (showTransferModal = false)}
    />
{/if}

<style>
    /* Styles as before */
</style>
