<script lang="ts">
    import { afterUpdate, onDestroy, onMount } from "svelte";
    import { WorkplaceType } from "../../model/position/WorkplaceType";
    import { t } from 'svelte-i18n';
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";
    import UpAndDownArrowKeys from "../Util/Kbd/UpAndDownArrowKeys.svelte";

    export let workplaceType: WorkplaceType;
    export let handleNext: () => void;
    export let handlePrevious: () => void;


    let buttons: HTMLButtonElement[] = [];
    let selectedButtonIndex = 0;

    function handleClick(index: number) {
        if (index === 0) {
            workplaceType = WorkplaceType.ON_SITE;
        } else if (index === 1) {
            workplaceType = WorkplaceType.HYBRID;
        } else if (index === 2) {
            workplaceType = WorkplaceType.REMOTE;
        } else {
            throw Error("Wrong index");
        }

        selectedButtonIndex = index;
    }

    function handleKeydown(event: KeyboardEvent) {
        const buttonCount = buttons.length;
        if (event.key === 'ArrowDown') {
            selectedButtonIndex = (selectedButtonIndex + 1) % buttonCount;
            handleClick(selectedButtonIndex);
        } else if (event.key === 'ArrowUp') {
            selectedButtonIndex = (selectedButtonIndex - 1 + buttonCount) % buttonCount;
            handleClick(selectedButtonIndex);
        } else if (event.key === 'Enter') {
            if (selectedButtonIndex !== -1) {
                buttons[selectedButtonIndex].click();
            }
            if (workplaceType !== null) {
                handleNext();
            }
        }

        if (selectedButtonIndex !== -1 && buttons[selectedButtonIndex]) {
            buttons[selectedButtonIndex].focus();
        }

        event.preventDefault();
    }

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });

    afterUpdate(() => {
        buttons = Array.from(document.querySelectorAll('.workplace-button'));
        if (buttons.length > 0) {
            buttons[selectedButtonIndex].focus();
        }
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-96 bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('WorkplaceTypeTitle')}</h2>
        <p class="text-center mb-6">{$t('WorkplaceTypePrompt')}</p>
        <UpAndDownArrowKeys/>
        <div class="grid grid-cols-1 gap-4">
            <button
                    class="btn w-full workplace-button {workplaceType === WorkplaceType.ON_SITE ? 'btn-accent' : 'btn-outline'}"
                    on:click={() => handleClick(0)}
            >
                {$t('WorkplaceTypeOnSite')}
            </button>
            <button
                    class="btn w-full workplace-button {workplaceType === WorkplaceType.HYBRID ? 'btn-accent' : 'btn-outline'}"
                    on:click={() => handleClick(1)}
            >
                {$t('WorkplaceTypeHybrid')}
            </button>
            <button
                    class="btn w-full workplace-button {workplaceType === WorkplaceType.REMOTE ? 'btn-accent' : 'btn-outline'}"
                    on:click={() => handleClick(2)}
            >
                {$t('WorkplaceTypeRemote')}
            </button>
        </div>

        <br />
        <EnterToContinue />
        <button
                disabled={workplaceType == null}
                on:click={handleNext}
                class="btn btn-primary w-full mt-4"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
