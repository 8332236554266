import {type Writable, writable} from 'svelte/store';
import type {UserAuthInfo} from "../service/auth/dto/AuthResponse";
import Landing from "../lib/content/Landing.svelte";
import {SelectedTheme} from "./SelectedTheme";
import type {PositionPageData} from "../model/jobs/PositionPageData";
import type {JobPostGeneratorDto} from "../model/jobs/JobPostGeneratorDto";

export const invalidLoginCredentials = writable<boolean>(false);

export const currentContent = writable<PageContent>();

export const pageStore = writable<any>();

export const metaStore = writable<any>();

export const selectedPositionId = writable<string>()

export const selectedPosition = writable<PositionPageData>()


export const token = writable(localStorage.getItem('token'));

export const userId = writable(localStorage.getItem('userId'));
export const userEmailStore = writable("");
export const userInfo = writable<UserAuthInfo>(JSON.parse(localStorage.getItem('userInfo')!!) as UserAuthInfo);

export const selectedApplicantId = writable('');

export const selectedInterviewDisplayId = writable('');
export const breadcrumbs = writable<Breadcrumbs[]>([{ name: 'Home', component: Landing }, { name: 'Home', component: Landing }, { name: 'Home', component: Landing }, { name: 'Home', component: Landing }]);

export const darkThemeStore = writable<boolean>((localStorage.getItem('theme'))=='dark');

export const selectedTheme: Writable<string> = new SelectedTheme(writable('dark'));

export const positionToEdit = writable<JobPostGeneratorDto>()

export const loadPositionDataInModern = writable<boolean>(true)

export enum AuthAction {
    EXPECTED
}

export interface PageContent{
    page: any,
    meta?: any
}

export interface Breadcrumbs {
    name:string
    component: any
    action?: () => any
}