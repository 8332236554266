<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onDestroy, onMount } from "svelte";
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import { getEmploymentTypeName } from "../../model/jobs/EmploymentType";
    import { getWorkplaceTypeName } from "../../model/position/WorkplaceType";
    import TabKeyPrompt from "../Util/Kbd/TabKeyPrompt.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";

    export let positionData: JobPostGeneratorDto;

    export let roleDescription: string;
    export let requirements: string = '';
    export let preferredQualifications: string = '';
    export let perksAndBenefits: string = '';

    export let handleNext: () => void;

    let roleDescriptionTextarea: HTMLTextAreaElement;

    onMount(() => {
        roleDescriptionTextarea.focus();
        document.addEventListener('keydown', handleKeydown);
    });

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (roleDescription && requirements && preferredQualifications && perksAndBenefits) {
                handleNext();
            }
        }
    }

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('RoleDescriptionTitle')}</h2>
        <div class="mb-6">
            <p><strong>{$t('JobTitle')}:</strong> {positionData.jobTitle}</p>
            <p><strong>{$t('WorkplaceType')}:</strong> {getWorkplaceTypeName(positionData.workplaceType)}</p>
            <p><strong>{$t('Location')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
            <p><strong>{$t('JobType')}:</strong> {getEmploymentTypeName(positionData.employmentType)}</p>
        </div>
        <p class="text-center mb-6">{$t('RoleDescriptionPrompt')}</p>
        <TabKeyPrompt/>
        <div class="mb-4">
            <label class="label">
                <span class="label-text">{$t('RoleDescription')}</span>
            </label>
            <textarea
                    bind:value={roleDescription}
                    placeholder={$t('EnterRoleDescription')}
                    class="textarea textarea-bordered w-full h-24"
                    bind:this={roleDescriptionTextarea}
            ></textarea>
        </div>

        <div class="mb-4">
            <label class="label">
                <span class="label-text">{$t('Requirements')}</span>
            </label>
            <textarea
                    bind:value={requirements}
                    placeholder={$t('EnterRequirements')}
                    class="textarea textarea-bordered w-full h-24"
            ></textarea>
        </div>

        <div class="mb-4">
            <label class="label">
                <span class="label-text">{$t('PreferredQualifications')}</span>
            </label>
            <textarea
                    bind:value={preferredQualifications}
                    placeholder={$t('EnterPreferredQualifications')}
                    class="textarea textarea-bordered w-full h-24"
            ></textarea>
        </div>

        <div class="mb-4">
            <label class="label">
                <span class="label-text">{$t('PerksAndBenefits')}</span>
            </label>
            <textarea
                    bind:value={perksAndBenefits}
                    placeholder={$t('EnterPerksAndBenefits')}
                    class="textarea textarea-bordered w-full h-24"
            ></textarea>
        </div>
        {#if requirements && preferredQualifications && perksAndBenefits && roleDescription}
            <EnterToContinue/>
        {/if}
        <button
                disabled={!requirements || !preferredQualifications || !perksAndBenefits || !roleDescription}
                on:click={handleNext}
                class="btn btn-primary w-full"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
