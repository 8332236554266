export interface Page<T> {
    content: T[],
    pageable: {
        sort: {
            empty: boolean,
            sorted: boolean,
            unsorted: boolean
        },
        offset: number,
        pageNumber: number,
        pageSize: number,
        unpaged: boolean,
        paged: boolean
    },
    last: boolean,
    totalPages: number,
    totalElements: number,
    size: number,
    number: number,
    sort: {
        empty: boolean,
        sorted: boolean,
        unsorted: boolean
    },
    first: boolean,
    numberOfElements: number,
    empty: boolean
}

export class PageUtils {
    static emptyPage<T>(): Page<T> {
        return {
            content: [],
            pageable: {
                sort: {
                    empty: true,
                    sorted: false,
                    unsorted: true
                },
                offset: 0,
                pageNumber: 0,
                pageSize: 0,
                unpaged: true,
                paged: false
            },
            last: true,
            totalPages: 0,
            totalElements: 0,
            size: 0,
            number: 0,
            sort: {
                empty: true,
                sorted: false,
                unsorted: true
            },
            first: true,
            numberOfElements: 0,
            empty: true
        };
    }

    static pageOf<T>(items: T[], pageNumber: number = 0, pageSize: number = 10): Page<T> {
        const totalElements = items.length;
        const totalPages = Math.ceil(totalElements / pageSize);
        const start = pageNumber * pageSize;
        const end = Math.min(start + pageSize, totalElements);
        const content = items.slice(start, end);

        return {
            content: content,
            pageable: {
                sort: {
                    empty: true,
                    sorted: false,
                    unsorted: true
                },
                offset: start,
                pageNumber: pageNumber,
                pageSize: pageSize,
                unpaged: false,
                paged: true
            },
            last: pageNumber === totalPages - 1,
            totalPages: totalPages,
            totalElements: totalElements,
            size: content.length,
            number: pageNumber,
            sort: {
                empty: true,
                sorted: false,
                unsorted: true
            },
            first: pageNumber === 0,
            numberOfElements: content.length,
            empty: content.length === 0
        };
    }
}