export enum WorkplaceType {
    ON_SITE = "ON_SITE",
    REMOTE = "REMOTE",
    HYBRID = "HYBRID"
}

export function getWorkplaceTypeName(type: WorkplaceType): string{
  switch (type){
      case WorkplaceType.ON_SITE:
          return "ON SITE"
      case WorkplaceType.REMOTE:
          return "REMOTE"
      case WorkplaceType.HYBRID:
          return "HYBRID"
      default:
          throw Error("Invalid workplace type "+type)
  }
}

export function getWorkplaceTypeNameUnderscore(type: WorkplaceType){
    return getWorkplaceTypeName(type).replace(" ", "_")
}