<script lang="ts">
    import {breadcrumbs, pageStore} from "../redux/stores";

</script>

<div class="breadcrumbs text-sm ">
    <ul>
        {#each $breadcrumbs as item, number}
            {#if number !== $breadcrumbs.length - 1}
                <li>
                    <a href="#" on:click={() => pageStore.set(item.component)} class="link link-hover gap-1">
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                class="h-4 w-4 stroke-current ">
                            <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                        </svg>
                        {item.name}
                    </a>
                </li>
            {:else}
                <li class="dark:text-gray-400 gap-1"> <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        class="h-4 w-4 stroke-current">
                    <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                </svg>{item.name}</li>
            {/if}
        {/each}
    </ul>
</div>

<style>
    .breadcrumbs {
        padding: 10px 0; /* Add some padding on the top and bottom */
        margin-left: 20px; /* Add margin to the left */
        font-size: 1rem; /* Adjust font size as needed */
        font-weight: bold; /* Make the text bold */
    }

    .breadcrumbs ul {
        list-style: none; /* Remove default list styling */
        padding: 0;
        display: flex; /* Use flexbox for alignment */
        gap: 8px; /* Spacing between items */
    }

    .breadcrumbs li a {
        color: #3273dc; /* Use a different color for links */
        text-decoration: none; /* Remove underline */
    }

    .breadcrumbs li a:hover {
        text-decoration: underline; /* Underline on hover */
    }

    .breadcrumbs li::after {
        color: #ccc; /* Color for the separator */
        margin: 0 5px; /* Spacing around the separator */
    }

    .breadcrumbs li:last-child::after {
        content: none; /* No separator after the last item */
    }
    .breadcrumbs li:last-child {
        color: #363636; /* Different color for the last item */
        pointer-events: none; /* Disable pointer events */
    }

    .breadcrumbs ul,li::before{
        height: 0.450rem;
        width: 0.450rem;
        opacity: 10;
        border-width: 0.125rem;
        border-left: 2px;
        border-bottom: 2px;
    }

    .breadcrumbs ul,li:first-child::before{
        height: 0.450rem;
        width: 0.450rem;
        opacity: 10;
        border-width: 0.125rem;
        border-left: 2px;
        border-bottom: 2px;
        border-top: 2px;
        border-right: 2px;
    }

</style>