<script lang="ts">
    import { t } from 'svelte-i18n';

    export let language: string;
    let selectElement: HTMLSelectElement;

    const languages = [
        { name: $t('English (US)'), id: 'en-US' },
        { name: $t('Spanish (AR)'), id: 'es-AR' },
    ];
</script>

        <div class="w-full mb-4">
            <select bind:value={language} bind:this={selectElement} class="select select-bordered w-full">
                <option value="" disabled>{$t('Select a language')}</option>
                {#each languages as language}
                    <option value={language.id}>{language.name}</option>
                {/each}
            </select>
        </div>

