<script lang="ts">
    import {writable} from 'svelte/store';
    import EmailPassComponent from "./register/EmailPassComponent.svelte";
    import CompanyDetails from "./register/CompanyDetails.svelte";
    import CreditCardRegister from "./register/CreditCardRegister.svelte";
    import type {RegisterDto} from "../../service/auth/dto/RegisterDto";
    import {CompanySize} from "../../model/company/CompanySize";
    import {CompanyType} from "../../model/company/CompanyType";
    import {Gender} from "../../model/applicant/Gender";
    import {type GoogleRegisterDto, validateGoogleRegisterDto} from "../../service/auth/dto/GoogleRegisterDto";
    import GenderRegister from "./register/personalDetails/GenderRegister.svelte";
    import BirthdayComponent from "./register/personalDetails/BirthdayComponent.svelte";
    import NameSurnameRegisterComponent from "./register/personalDetails/NameSurnameRegisterComponent.svelte";
    import CompanySizeRegisterComponent from "./register/companyDetails/CompanySizeRegisterComponent.svelte";
    import CompanyTypeRegisterComponent from "./register/companyDetails/CompanyTypeRegisterComponent.svelte";
    import {onMount} from "svelte";
    import CountrySelector from "../Common/CountrySelector.svelte";
    import {Country} from "../../model/location/Country";
    import { t } from 'svelte-i18n';
    import {getUserLocale} from "../../i18n";
    import RegisterSteps from "../Register/RegisterSteps.svelte";

    onMount(() => {
        window.addEventListener('keydown', checkForEnter);
    });

    let allowNextStep = false

    const googleRegisterDto: GoogleRegisterDto =  JSON.parse(localStorage.getItem('googleRegisterDto'))

    let hasGoogleData = googleRegisterDto ? (validateGoogleRegisterDto(googleRegisterDto)) : false

    const currentStep = writable<number>(1);
    const maxStep = 9;

    const messages = [
        $t("setupAccount"),  // "Welcome to Astrovel! Let's get you set up with a new account."
        $t("personalizeExperience"),  // "Great to have you on the path to joining Astrovel! <br> Let's personalize your experience."
        $t("knowMoreAboutYou"),  // "We'd love to know more about you!"
        $t("celebrateSpecialDay"),  // "Almost done! We'd like to celebrate you on your special day."
        $t("tailorServices"),  // "Let's tailor our services to fit your company perfectly."
        $t("supportBusinessJourney"),  // "We're excited to support your business journey. Let's define the nature of your organization."
        $t("knowCompanyBetter"),  // "Let's get to know your company a bit better."
        $t("cityAndCountry"),  // "You're almost there! Just tell us your city and country so we can personalize your experience."
        $t("creditCardInfo")  // "Enter your credit card information below. We'll use this for any pay-as-you-go services you use."
    ];


    export const registerDto = writable<RegisterDto>({
        captchaToken: "", googleToken: "",
        locale: getUserLocale(),
        email: "",
        name: "",
        surname: "",
        birthdate: "0-0-0",
        registerCompanyDto: {
            name: "",
            website: "",
            size: CompanySize.CONGLOMERATE,
            type: CompanyType.PUBLIC_COMPANY,
            country: Country.UNKNOWN
        },
        paymentMethodId: "",
        gender: Gender.OTHER
    });

    if (hasGoogleData){
        if(googleRegisterDto.email != undefined && googleRegisterDto.email != null){
            currentStep.update(cs=>cs+1);
        }
        if (googleRegisterDto.name != undefined && googleRegisterDto.surname != undefined){
            currentStep.update(cs=>cs+1);
        }
        if(googleRegisterDto.gender != ""){
            currentStep.update(cs=>cs+1);
        }
        $registerDto.email = googleRegisterDto.email
        $registerDto.name = googleRegisterDto.name
        $registerDto.surname = googleRegisterDto.surname
        $registerDto.registerCompanyDto.website = googleRegisterDto.companyWebsite
        $registerDto.googleToken = googleRegisterDto.googleToken
    }

    function navigateStep(stepChange: number) {
        currentStep.update(n => {
            let newStep = n + stepChange;
            if (newStep < 1) newStep = 1;
            if (newStep > maxStep) newStep = maxStep;
            return newStep;
        });
    }

    function checkForEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if(allowNextStep){
                navigateStep(1)
            }
        }
    }



</script>

<div class="flex flex-col h-screen" >

    <!-- Top area with back arrow and progress bar -->
    <div class="px-4 md:px-8 pt-4 md:pt-8">
        <!-- Back Arrow -->
        <div class="flex items-center">
            {#if $currentStep > 1 && !hasGoogleData || $currentStep > 3 && hasGoogleData}
            <button class="btn btn-circle btn-sm btn-outline mr-2" on:click={() => navigateStep(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            </button>
        {/if}

        </div>
    </div>

    <div class="flex justify-center items-center h-screen bg-base-100">
        <!-- Header at the top -->
        <div class="max-w-4xl w-full p-8 bg-base-200 shadow-lg rounded-lg">
            <h1 class="centered-text mb-4 mx-5 w-full" style=" font-family: 'Helvetica-Neue'">{@html messages[$currentStep-1]}</h1>
            <div>

            </div>
            <!-- Main form content -->
            <div class="flex flex-row flex-grow justify-between">
                {#if $currentStep === 1}
                    <EmailPassComponent bind:email={$registerDto.email} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 2}
                    <NameSurnameRegisterComponent bind:name={$registerDto.name} bind:surname={$registerDto.surname} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 3}
                    <GenderRegister bind:gender={$registerDto.gender} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 4}
                    <BirthdayComponent bind:birthDate={$registerDto.birthdate} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 5}
                    <CompanySizeRegisterComponent bind:companySize={$registerDto.registerCompanyDto.size} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 6}
                    <CompanyTypeRegisterComponent bind:companyType={$registerDto.registerCompanyDto.type} bind:isValid={allowNextStep}/>
                {:else if $currentStep === 7}
                    <CompanyDetails
                            bind:companyName={$registerDto.registerCompanyDto.name}
                            bind:website={$registerDto.registerCompanyDto.website}
                            bind:isValid={allowNextStep}/>
                {:else if $currentStep === 8}
                    <div class="wide px-6 py-3 w-full mt-16">
                        <CountrySelector bind:country={$registerDto.registerCompanyDto.country}/>
                    </div>
                {:else if $currentStep === 9}
                    <CreditCardRegister registerDto={$registerDto}/>
                {/if}

                <!-- Right side: Steps tracker -->
                <RegisterSteps {currentStep} />
            </div>

            <!-- Next button styled like the "Add snippet" button -->
            <div class="flex justify-end mt-6 gap-4">
                {#if $currentStep > 1 && !hasGoogleData || $currentStep > 2 && hasGoogleData}
                    <button class="btn btn-outline" on:click={() => navigateStep(-1)}>Back</button>
                {/if}
                {#if $currentStep < maxStep}
                    <button class="btn btn-primary" disabled={!allowNextStep} on:click={() => navigateStep(1)}>Next</button>
                {/if}
            </div>
        </div>
    </div>
</div>
<style>
    .progress-bar-container {
        height: 20px;
        background-color: #f5f5f5;
        border-radius: 9999px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .progress-bar {
        height: 100%;
        background-color: #3b82f6;
        transition: width 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        font-weight: 600;
    }

    #text-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .centered-text {
        font-size: 2em;
        color: #ffffff;
        text-shadow: 2px 2px 4px #000000;
    }

    @font-face {
        font-family: 'Helvetica-Neue';
        src: url("/roboto.ttf") format('opentype');
        font-weight: normal;
        font-style: normal;
    }
</style>