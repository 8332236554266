<!--ScreeningQuestionsFormInput-->
<script lang="ts">
    import type { ScreeningQuestion } from "../../model/jobs/ScreeningQuestion";

    export let screeningQuestions: ScreeningQuestion[] = [];

    let newQuestionText = '';
    let newQuestionMustHave = false;
    let newQuestionIdealAnswer = '';

    function addScreeningQuestion() {
        if (newQuestionText) {
            screeningQuestions = [
                ...screeningQuestions,
                {
                    text: newQuestionText,
                    mustHave: newQuestionMustHave,
                    idealAnswer: newQuestionIdealAnswer
                }
            ];
            resetCustomQuestionFields();
        }
    }

    function resetCustomQuestionFields() {
        newQuestionText = '';
        newQuestionMustHave = false;
        newQuestionIdealAnswer = '';
    }

    function removeScreeningQuestion(index: number) {
        screeningQuestions = screeningQuestions.filter((_, i) => i !== index);
    }
</script>

<div class="form-control">
    {#each screeningQuestions as question, index}
        <div class="flex items-center justify-between mb-2 bg-base-200 p-2 rounded">
            <div>
                <p class="text-sm font-semibold">{question.text}</p>
                <p class="text-xs">Ideal Answer: {question.idealAnswer}</p>
                <label class="flex items-center text-xs">
                    <input type="checkbox" checked={question.mustHave} class="checkbox checkbox-xs mr-1" disabled />
                    Must-have qualification
                </label>
            </div>
            <button class="btn btn-error btn-xs" on:click={() => removeScreeningQuestion(index)}>✕</button>
        </div>
    {/each}

    <input
            type="text"
            bind:value={newQuestionText}
            placeholder="Add screening question..."
            class="input input-bordered input-sm mb-2"
    />

    <input
            type="text"
            bind:value={newQuestionIdealAnswer}
            placeholder="Ideal answer..."
            class="input input-bordered input-sm mb-2"
    />

    <label class="flex items-center text-sm mb-2">
        <input type="checkbox" bind:checked={newQuestionMustHave} class="checkbox checkbox-sm mr-1" />
        Must-have qualification
    </label>

    <button on:click={addScreeningQuestion} class="btn btn-primary btn-sm w-full">Add Question</button>
</div>
