

export interface Interview {
    id: string;
    date: Date,
    name:string,
    code: string,
    candidate: string,
    position: string,
    recommendation: string
}

export enum InterviewType {
    SCREENING, CODING, FULL
}

export enum InterviewStatus {
    SCHEDULED,
    COMPLETED,
    CANCELLED,
    NO_SHOW,
    // Add other statuses as needed
}
