<script lang="ts">
    import { t } from 'svelte-i18n';
    import { PositionStatus } from "../../model/jobs/Position.js";
    import StopReceivingPositionModal from "../Modals/StopReceivingPositionmodal.svelte";
    import ClosePositionModal from "../Modals/ClosePositionModal.svelte";
    import ReOpenPositionModal from "../Modals/ReOpenPositionModal.svelte";
    import type { PositionPageData } from "../../model/jobs/PositionPageData";

    export let position: PositionPageData;

    let showActionDropdown = false;
    let showStopReceivingPositionModal = false;
    let showClosePositionModal = false;
    let showReOpenPositionModal = false;

    const toggleActionDropdown = () => {
        showActionDropdown = !showActionDropdown;
    };
</script>

<div class="dropdown dropdown-end">
    <label tabindex="0" class="btn btn-secondary" on:click={toggleActionDropdown}>{$t('ManageJob')} ▼</label>
    <ul class={`dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 ${showActionDropdown ? '' : 'hidden'}`}>
        {#if position.status === PositionStatus.CLOSED}
            <li><a href="#" on:click|preventDefault={() => { showReOpenPositionModal = true }}>{$t('ReopenPosition')}</a></li>
        {/if}
        {#if position.status === PositionStatus.NO_LONGER_ACCEPTING}
            <li><a href="#" on:click|preventDefault={() => { showReOpenPositionModal = true }}>{$t('ReopenPosition')}</a></li>
        {/if}
        {#if position.status === PositionStatus.OPEN}
            <li><a href="#" on:click|preventDefault={() => { showClosePositionModal = true }}>{$t('ClosePosition')}</a></li>
            <li><a href="#" on:click|preventDefault={() => { showStopReceivingPositionModal = true }}>{$t('StopReceivingApplicants')}</a></li>
        {/if}
    </ul>
</div>

<ClosePositionModal bind:toogle={showClosePositionModal} bind:position={position}/>
<ReOpenPositionModal bind:toogle={showReOpenPositionModal} bind:position={position}/>
<StopReceivingPositionModal bind:toogle={showStopReceivingPositionModal} bind:position={position}/>
