<script lang="ts">
    import {onMount} from "svelte";
    import {isWebsiteValid} from "../../../validators/common/WebsiteValidation";
    import {isCompanyNameValid} from "../../../validators/common/CompanyNameValidation";

    export let isValid: boolean = false
    export let companyName = "";
    export let website = "";

    let validationResult: ValidationResult = {isValid: false, message: ""}

    function validate() {
        validationResult = isWebsiteValid(website)
        isValid = validationResult.isValid
        if (!isValid) return
        validationResult = isCompanyNameValid(companyName)
        isValid = validationResult.isValid
    }

    let companyNameInput: HTMLInputElement

    onMount(() => {
        isValid = false
        companyNameInput.focus()
    })

</script>

<div class="px-6 md:px-12 flex flex-col w-full mt-5">
    <!-- Company Name Input -->
    <div class="flex gap-4">
        <div class="w-full mb-8">
            <label class="label" for="companyName">
                <span class="label-text text-xl">What's your company name?</span>
            </label>
            <input type="text"
                   id="companyName"
                   class="input input-bordered w-full text-xl h-16"
                   bind:this={companyNameInput}
                   bind:value={companyName}
                   on:input={()=> validate()} />
        </div>

        <!-- Website Input -->
        <div class="w-full mb-8">
            <label class="label" for="website">
                <span class="label-text text-xl">What's your website?</span>
            </label>
            <input type="text"
                   id="website"
                   class="input input-bordered w-full text-xl h-16"
                   bind:value={website}
                   on:input={()=> validate()} />
        </div>
    </div>


    <!-- Validation Message (if any) -->
    {#if validationResult.message}
        <div class={`alert ${isValid ? 'alert-success' : 'alert-error'} shadow-lg`}>
            <div>
                <span>{validationResult.message}</span>
            </div>
        </div>
    {/if}
</div>


