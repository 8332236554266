<script lang="ts">
    import ProfilePositionTable from "./ProfilePage/ProfilePositionTable.svelte";
    import { userInfo } from "../../redux/stores";
    import { t } from 'svelte-i18n';
</script>

<div class="user-profile-page p-8">
    <div class="user-info card bg-base-100 shadow-xl mb-6 p-6">
        <div class="flex items-center">
            <div class="avatar placeholder">
                <div class="bg-base-100 rounded-full w-20 h-20">
                    {#if $userInfo.profilePic}
                        <img src="{$userInfo.profilePic}" alt="{$t('ProfilePicture')}" class="rounded-full w-20 h-20">
                    {:else}
                        <span class="text-3xl">{$userInfo.name.charAt(0)}</span>
                    {/if}
                </div>
            </div>
            <div class="ml-4">
                <h1 class="text-3xl font-bold">{$userInfo.name} {$userInfo.surname}</h1>
                <p class="text-xl">{$userInfo.email}</p>
                <p class="text-lg">{$t('Birthdate')}: {$userInfo.birthDate}</p>
                <p class="text-lg">{$userInfo.companyName}</p>
                <p class="text-lg">{$userInfo.web}</p>
                <!--<button class="btn btn-primary mt-4">
                    {$t('EditProfile')}
                </button>-->
            </div>
        </div>
    </div>

    <div class="positions-created mb-6">
        <h2 class="text-2xl font-bold mb-4">{$t('PositionsCreated')}</h2>
        <ProfilePositionTable/>
    </div>

    <!--<div class="interviews-created mb-6">
        <h2 class="text-2xl font-bold mb-4">{$t('InterviewsCreated')}</h2>
        <ProfileInterviewTable />
    </div>-->
</div>
