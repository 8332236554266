import {makeDeleteRequest, makeGetRequest, makePostRequest} from "./Requests";
import type {InterviewCreator} from "../model/interview/InterviewCreator";
import {userId} from "../redux/stores";
import {type Interview, InterviewType} from "../model/interview/Interviews";
import type {InterviewDisplayDto} from "../model/interview/InterviewDisplayDto";
import type {TranscriptDto} from "../model/interview/TranscriptDto";


export const getInterviews = async (): Promise<Interview[]> => {
    return await makeGetRequest<Interview[]>(import.meta.env.VITE_GET_INTERVIEWS)
}

export const createScreeningInterview = async (applicantId: string, positionId: string) => createInterview(InterviewType.SCREENING, applicantId, positionId)

export const createInterview = async (interviewType: InterviewType, applicantId: string, positionId: string): Promise<any> => {
    const interviewCreator: InterviewCreator = {
        applicantId: applicantId,
        interviewType: interviewType,
        positionId: positionId,
        recruiterId: localStorage.getItem('userId')!!
    }
    return await makePostRequest(import.meta.env.VITE_POST_CREATE_INTERVIEW, interviewCreator, false, "application/json")
};

export const getInterviewDisplay = async (interviewId: string): Promise<InterviewDisplayDto> => {
    return await makeGetRequest<InterviewDisplayDto>(import.meta.env.VITE_GET_INTERVIEW_DISPLAY + interviewId)
}

export const deleteInterview = async (interviewId: string): Promise<any> => {
    return await makeDeleteRequest(import.meta.env.VITE_DELETE_INTERVIEW + interviewId)
}

export const downloadTranscript = (transcript: TranscriptDto, applicantName: string, interviewCode: string) => {
    const text = transcript.messages.map((t) => t.sender + ': ' + t.message).join("\n")

    // Create a blob
    const blob = new Blob([text], {type: 'text/plain'});

    // Create an anchor element and set the URL to the blob
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = applicantName + '-' + interviewCode + '.txt'; // Name of the file to be downloaded
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
}