<script lang="ts">
    import {logout} from "../../../service/auth/AuthService";
    import {t} from "svelte-i18n";
</script>

<dialog id="logout_modal" class="modal">
    <div class="modal-box">
        <h3 class="font-bold text-lg">{$t('hello')}</h3>
        <p class="py-4">{$t('logout_confirmation')}</p>
        <button class="btn" on:click={()=> logout()}>{$t('logout')}</button>
    </div>
    <form method="dialog" class="modal-backdrop">
        <button>{$t('close')}</button>
    </form>
</dialog>
