<script lang="ts">
import {openResume} from "../../../service/ApplicantService";
import type {ApplicantPageData} from "../../../model/jobs/PositionPageData";
import {userInfo} from "../../../redux/stores";

export let selectedApplicant: ApplicantPageData;

let isOpen: boolean;
let pathUrl = "";
let previousApplicantId = selectedApplicant.id;

$: if (selectedApplicant.id !== previousApplicantId) {
    isOpen = false;
    previousApplicantId = selectedApplicant.id;
}

const handleResume = async () => {
    const cachedUrl = sessionStorage.getItem(`resume-${selectedApplicant.id}`);
    if(!isOpen){
        if (cachedUrl) {
            pathUrl = cachedUrl;
        }else{
            const url = await openResume(selectedApplicant.id);
            if (url) {
                pathUrl = url;
                sessionStorage.setItem(`resume-${selectedApplicant.id}`, url);
            } else {
                console.error("Failed to load PDF");
            }
        }
    }
        isOpen = !isOpen;
};
</script>

<div class="card bg-base-200 p-4">
    <h3 class="text-lg font-semibold">Resume</h3>
    <br>
    {#if !isOpen}
        <button on:click={handleResume} class="btn btn-primary">
            Open Resume
        </button>
    {:else}
        <iframe id="pdfView" src={pathUrl} ></iframe>
        <br>
        <a on:click={handleResume} class="btn btn-primary">
            Close Resume</a>
    {/if}
</div>

<style>
    #pdfView {
        width: 100%;
        height: 500px;
    }
</style>