<script lang="ts">

    import {Country} from "../../model/location/Country";
    import {onMount} from "svelte";
    import {getIpLocation} from "../../utils/Utils";

    export let country: Country;
    let selectElement: HTMLSelectElement;
    let countries: Country[] = Object.values(Country) as Country[];
    let countryUser: string;

    onMount(() => {
        if (!country) {
            country = countries[0] as Country;
        }
        document.addEventListener('keydown', handleKeydown);

        getLocation();
    });

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            selectElement.focus();
            selectElement.click();
        }
    }

    function formatCountryName(country: string): string {
        return country.replace(/_/g, ' ');
    }

    async function getLocation(){
        try{
            const response = await getIpLocation();
            const data = await response.json();
            countryUser = data.country;

            const matchedCountry = countries.find(c => c.toString() === countryUser.toUpperCase());
            if (matchedCountry) {
                country = matchedCountry;
            }
        } catch (error){
            console.error(error);
        }
    }

</script>

<select bind:value={country} id="country" class="select select-bordered w-full text-lg font-semibold" bind:this={selectElement}>
    {#each countries as countryOption}
        <option value={countryOption}>{formatCountryName(countryOption)} </option>
    {/each}
</select>
