<script lang="ts">
    import { t } from 'svelte-i18n';
    import {loadPositionDataInModern, selectedPosition, userInfo} from "../../redux/stores";
    import type { ApplicantPageData, PositionPageData } from "../../model/jobs/PositionPageData";
    import {
        cachedApplicantsForPosition
    } from "../../service/ApplicantService";
    import { createScreeningInterview } from "../../service/InterviewService";
    import { formatDateToReadable, getTimeAgo } from "../../utils/DateUtils";
    import { PositionStatus } from "../../model/jobs/Position";
    import { locationToString } from "../../model/jobs/Location";
    import UploadResumeModal from "../Modals/UploadResumeModal.svelte";
    import NextStepBadge from "./NextStepBadge.svelte";
    import RatingBadge from "./RatingBadge.svelte";
    import ApplpicantContactInfoDropdown from "./ApplpicantContactInfoDropdown.svelte";
    import RejectBtn from "./RejectBtn.svelte";
    import RejectedBadge from "./RejectedBadge.svelte";
    import SearchApplicantsByKeywordInput from "../SearchApplicantsByKeywordInput.svelte";
    import JoinPagination from "./JoinPagination.svelte";
    import LoadingAnimation from "../LoadingAnimation.svelte";
    import UpdatePositionStatusDropdown from "./UpdatePositionStatusDropdown.svelte";
    import { NextStep } from "../../model/applicant/NextStep";
    import RateAsDropdown from "./PositionApplicants/RateAsDropdown.svelte";
    import ResumeScorecard from "../ResumeScoreCard/Scorecard.svelte";
    import YOEFilterDropdown from "./PositionApplicants/YOEFilterDropdown.svelte";
    import SortingDropdown from "./PositionApplicants/SortingDropdown.svelte";
    import HandlePdfViewer from "./PositionApplicants/HandlePdfViewer.svelte";
    import {pushErrorToast, pushSuccessToast} from "../../service/ToastService";

    export let position: PositionPageData = $selectedPosition;
    let resultCount: number = $cachedApplicantsForPosition.content.length;
    let showUploadPdfModal = false;
    let loadingApplicants = false;
    let originalApplicants: ApplicantPageData[] = JSON.parse(JSON.stringify($cachedApplicantsForPosition.content));
    let totalPages = $cachedApplicantsForPosition.totalPages;

    let selectedApplicant: ApplicantPageData | null = null;
    let showRateDropdown = false;
    let showActionDropdown = false;
    let showLoadDropdown = false;
    let showMoreSummary = false;
    let showMoreExperience = false;
    let showMoreEducation = false;
    let isSearch = false;
    let searchKeyword: string;
    let currentPage = 0

    const toggleDropdown = () => {
        showRateDropdown = !showRateDropdown;
    };

    const toggleActionDropdown = () => {
        showActionDropdown = !showActionDropdown;
    };

    const toggleLoadDropdown = () => {
        showLoadDropdown = !showLoadDropdown;
    };

    const toggleSummary = () => {
        showMoreSummary = !showMoreSummary;
    };

    const toggleExperience = () => {
        showMoreExperience = !showMoreExperience;
    };

    const toggleEducation = () => {
        showMoreEducation = !showMoreEducation;
    };

    function triggerFileInput() {
        showUploadPdfModal = true;
    }

    function sendToScreeningInterview() {
        if (selectedApplicant && position.id) {
            createScreeningInterview(selectedApplicant.id, position.id).then(() => {
                selectedApplicant.nextStep = NextStep.WAITING_TO_SCHEDULE_SCREENING
                pushSuccessToast($t('SuccessfullySentToScreening').replace("{name}", selectedApplicant.fullName));
            }).catch(error => {
                pushErrorToast($t('FailedToSendToScreening').replace("{name}", selectedApplicant.fullName));
                throw error
            });
        }
    }

    if (!$loadPositionDataInModern){
        selectedApplicant = $cachedApplicantsForPosition.content[0]
    }

</script>

<div class="w-full h-full">
    {#if $loadPositionDataInModern}
        <header class="bg-base-300 text-base-content py-4 px-6">
            <h1 class="text-xl font-semibold">{position.title}</h1>
            <p class="text-sm">{$userInfo.companyName} ·
                {locationToString(position.location)} ({position.workplaceType.toString().replace("_", " ")})
                - {#if position.status===PositionStatus.OPEN}{$t('Opened')}  {getTimeAgo(position.createdAt)}{/if}
                {#if position.status===PositionStatus.CLOSED}{$t('Closed')}  {getTimeAgo(position.closedAt)}{/if}
                · {position.applicantCount} {$t('Applicants')}</p>
        </header>

        <div class="flex justify-between items-center bg-base-200 px-6 py-2">
            <div class="flex gap-2">
                <SortingDropdown  positionId={position.id}/>
                <YOEFilterDropdown positionId={position.id} page={currentPage}/>
                <!--<GenderFilterDropdown positionId={position.id} page={currentPage}/>
                <AgeFilterDropdown positionId={position.id} page={currentPage}/>-->
                <SearchApplicantsByKeywordInput
                        positionId={position.id}
                        bind:resultCount={resultCount}
                        bind:applicants={$cachedApplicantsForPosition.content}
                        bind:isSearch={isSearch}
                        bind:totalPages={totalPages}
                        bind:searchKeyword={searchKeyword}
                        bind:loadingApplicants={loadingApplicants}
                        bind:selectedApplicant={selectedApplicant}
                        originalApplicants={originalApplicants}/>
            </div>
            <div class="flex gap-2">
                <UpdatePositionStatusDropdown bind:position={position}/>
                <div class="dropdown dropdown-end">
                    <label tabindex="0" class="btn btn-primary" on:click={toggleLoadDropdown}>
                        {$t('LoadApplicant')} ▼
                    </label>
                    <ul class={`z-10 dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 ${showLoadDropdown ? '' : 'hidden'}`}>
                        <li>
                            <a href="#" on:click|preventDefault={triggerFileInput}>
                                {$t('UploadResumePDF')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    {/if}

    <div class="flex w-full h-screen">
        <div class="w-1/3 bg-base-200 overflow-auto h-full">
            <div class="p-4 bg-base-300">
                {#if $loadPositionDataInModern}
                    <p class="text-lg font-semibold">{$t('TotalApplicants')}: {position.applicantCount} ({resultCount} {$t('Results')})</p>
                {/if}
            </div>
            <ul>
                {#if loadingApplicants}
                    <LoadingAnimation/>
                {:else}
                    {#each $cachedApplicantsForPosition.content as applicant}
                        <li class={`p-4 hover:bg-primary hover:text-white cursor-pointer ${selectedApplicant?.id === applicant.id ? 'bg-primary text-white' : 'text-base-content'}`} on:click={() => { selectedApplicant = applicant; showRateDropdown = false; }}>
                            {applicant.fullName}
                            {#if applicant.rejected}
                                <RejectedBadge/>
                            {:else}
                                <RatingBadge bind:currentRating={applicant.currentRating}/>
                                <NextStepBadge bind:nextStep={applicant.nextStep}/>
                            {/if}
                        </li>
                    {/each}
                {/if}
            </ul>
            {#if $loadPositionDataInModern}
                <JoinPagination positionId={position.id}
                                bind:searchKeyword={searchKeyword}
                                bind:isSearch={isSearch}
                                bind:applicants={$cachedApplicantsForPosition.content}
                                bind:totalPages={totalPages}
                                bind:toggle={showUploadPdfModal}
                />
            {/if}
        </div>

        <div class="w-2/3 p-6 h-full overflow-auto">
            {#if selectedApplicant}
                <div class="p-6 rounded-lg shadow-md">
                    <h2 class="text-2xl font-bold mb-2">{selectedApplicant.fullName}</h2>
                    <p class="text-lg mb-1">{selectedApplicant.experience?.[0]?.position ?? "N/A"}</p>
                    {#if $loadPositionDataInModern}
                        <p class="text-sm text-gray-600 mb-1">{locationToString(position.location)}</p>
                    {/if}

                    <p class="text-sm text-gray-500 mb-4">{$t('AppliedOn')} {formatDateToReadable(selectedApplicant.applyDate)}</p>
                    <!-- Summary -->
                    <p class="text-sm mb-4">
                        {#if showMoreSummary}
                            {selectedApplicant.summary}
                            <a href="#" class="link link-primary ml-2" on:click|preventDefault={toggleSummary}>{$t('ShowLess')}</a>
                        {:else}
                            {selectedApplicant.summary.slice(0, 280)}{selectedApplicant.summary.length > 280 ? '...' : ''}
                            {#if selectedApplicant.summary.length > 280}
                                <a href="#" class="link link-primary ml-2" on:click|preventDefault={toggleSummary}>{$t('ShowMore')}</a>
                            {/if}
                        {/if}
                    </p>
                    <!-- Buttons and actions -->
                    <div class="flex gap-2 mb-4">
                        <RateAsDropdown bind:selectedApplicant={selectedApplicant} bind:originalApplicants={originalApplicants}/>
                        <a class="btn btn-outline" href="mailto:{selectedApplicant.email}">{$t('Message')}</a>
                        <ApplpicantContactInfoDropdown email={selectedApplicant.email} phone={selectedApplicant.phone}/>
                   {#if $loadPositionDataInModern}
                       <RejectBtn bind:applicant={selectedApplicant} positionId={position.id} rejected={selectedApplicant.rejected}/>
                   {/if}
                    </div>

                    <button disabled={ selectedApplicant.nextStep !== NextStep.PENDING || selectedApplicant.rejected}
                            class="btn btn-secondary mb-4"
                            on:click={sendToScreeningInterview}>
                        {$t('SendToScreeningInterview')}</button>

                    <!-- Display the Resume Scorecard -->
                    {#if selectedApplicant.resumeScorecard}
                        <ResumeScorecard resumeScorecard={selectedApplicant.resumeScorecard} />
                    {/if}
                    {#if selectedApplicant.screeningScorecard}
                        <ResumeScorecard resumeScorecard={selectedApplicant.screeningScorecard} />
                    {/if}

                    <!-- Hard Skills & Soft Skills -->
                    <div class="card bg-base-200 p-4 mb-4 flex flex-row flex-wrap w-full">
                        {#each selectedApplicant.hardSkills as hardSkill}
                            <div class="badge badge-primary mr-2 mb-2 p-3">
                                {hardSkill}
                            </div>
                        {/each}
                        {#each selectedApplicant.softSkills as softSkill}
                            <div class="badge badge-secondary mr-2 mb-2 p-3">
                                {softSkill}
                            </div>
                        {/each}
                    </div>

                    <!-- Experience Section -->
                    <div class="card bg-base-200 p-4 mb-4">
                        <h3 class="text-lg font-semibold">{$t('Experience')}</h3>
                        {#if showMoreExperience}
                            {#each selectedApplicant.experience as item}
                                <div class="mt-2">
                                    <p class="font-semibold">{item.position}</p>
                                    <p class="text-sm">{item.company}</p>
                                    <p class="text-xs opacity-75">{item.startDate} - {item.endDate}</p>
                                </div>
                            {/each}
                            <a href="#" class="link link-primary mt-2" on:click|preventDefault={toggleExperience}>{$t('ShowLess')}</a>
                        {:else}
                            {#each selectedApplicant.experience.slice(0, 2) as item}
                                <div class="mt-2">
                                    <p class="font-semibold">{item.position}</p>
                                    <p class="text-sm">{item.company}</p>
                                    <p class="text-xs opacity-75">{item.startDate} - {item.endDate}</p>
                                </div>
                            {/each}
                            {#if selectedApplicant.experience.length > 2}
                                <a href="#" class="link link-primary mt-2" on:click|preventDefault={toggleExperience}>{$t('ShowMore')} {selectedApplicant.experience.length - 2} {$t('MoreExperience')}</a>
                            {/if}
                        {/if}
                    </div>

                    <!-- Education Section -->
                    <div class="card bg-base-200 p-4 mb-4">
                        <h3 class="text-lg font-semibold">{$t('Education')}</h3>
                        {#if showMoreEducation}
                            {#each selectedApplicant.education as item}
                                <div class="mt-2">
                                    <p class="font-semibold">{item.degree}</p>
                                    <p class="text-sm">{item.institution}</p>
                                    <p class="text-xs opacity-75">{item.startDate} - {item.endDate}</p>
                                </div>
                            {/each}
                            <a href="#" class="link link-primary mt-2" on:click|preventDefault={toggleEducation}>{$t('ShowLess')}</a>
                        {:else}
                            {#each selectedApplicant.education.slice(0, 2) as item}
                                <div class="mt-2">
                                    <p class="font-semibold">{item.degree}</p>
                                    <p class="text-sm">{item.institution}</p>
                                    <p class="text-xs opacity-75">{item.startDate} - {item.endDate}</p>
                                </div>
                            {/each}
                            {#if selectedApplicant.education.length > 2}
                                <a href="#" class="link link-primary mt-2" on:click|preventDefault={toggleEducation}>{$t('ShowMore')} {selectedApplicant.education.length - 2} {$t('MoreEducation')}</a>
                            {/if}
                        {/if}
                    </div>

                    <!-- Interviews Section -->
                    <div class="card bg-base-200 p-4 mb-4">
                        <h3 class="text-lg font-semibold">{$t('Interviews')}</h3>
                        {#if selectedApplicant.interviews && selectedApplicant.interviews.length > 0}
                            {#each selectedApplicant.interviews as interview}
                                <div class="mt-2">
                                    <p class="font-semibold">{interview.title}</p>
                                    <p class="text-sm">Scheduled for {formatDateToReadable(interview.scheduledDate)}</p>
                                    <p class="text-sm">Status: {interview.status}</p>
                                </div>
                            {/each}
                        {:else}
                            <p>{$t('NoInterviewsScheduled')}</p>
                        {/if}
                    </div>

                    <HandlePdfViewer bind:selectedApplicant={selectedApplicant}/>

                </div>
            {:else}
                <div class="flex items-center justify-center h-full">
                    <p>{$t('SelectApplicant')}</p>
                </div>
            {/if}
        </div>
    </div>
</div>

<UploadResumeModal bind:toogle={showUploadPdfModal}
                   bind:position={position}
                   bind:applicants={$cachedApplicantsForPosition.content}
                   bind:selectedApplicant={selectedApplicant}
                   bind:resultCount={resultCount}
                   bind:originalApplicants={originalApplicants}/>
