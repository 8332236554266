import type {AuthRequest} from "./dto/AuthRequest";
import type {RegisterDto} from "./dto/RegisterDto";
import { invalidLoginCredentials, pageStore, token, userId, userInfo} from "../../redux/stores";
import EmptyContentPage from "../../lib/EmptyContentPage.svelte";
import type {AuthResponse} from "./dto/AuthResponse";
import {navigate} from 'svelte-routing';


export const login = async (authRequest: AuthRequest): Promise<AuthResponse> => {
    const response = await fetch(import.meta.env.VITE_POST_LOGIN, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(authRequest),
    });

    if (!response.ok) {
        if (response.status == 401) invalidLoginCredentials.set(true)
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const authResponse = await response.json()

    await saveLoginData(authResponse)
    return authResponse;
}

export const saveLoginData= async (authResponse: any) =>{
    localStorage.setItem('token', authResponse.token);
    token.set(localStorage.getItem('token'));


    localStorage.setItem('userId', authResponse.userId);
    userId.set(localStorage.getItem('userId'));

    localStorage.setItem('userInfo', JSON.stringify(authResponse.userInfo));
    userInfo.set(authResponse.userInfo);

    navigate('/', { replace: true });
    sessionStorage.setItem('isAuthenticated', 'true');
    pageStore.set(EmptyContentPage);
}

export const registerNewUser = async (registerDto: RegisterDto): Promise<any> => {
    const response = await fetch(import.meta.env.VITE_POST_REGISTER, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerDto),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (registerDto.googleToken!=undefined){
        await saveLoginData(await response.json())
        navigate("/")
    }

    sessionStorage.setItem('isAuthenticated', 'true');
    return response;
}

export const resendVerificationEmail = async (email: string) => {
   return await fetch(import.meta.env.VITE_POST_RESEND_EMAIL_VALIDATION, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: email}),
    });
}

export const validateUserEmail = async (token: string) => {
    const res = await fetch(import.meta.env.VITE_POST_VALIDATE_EMAIL, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: token}),
    });
    return res.json()
}


export const logout = () => {
    localStorage.clear()
    navigate('/login', { replace: true });
    // @ts-ignore
    document.getElementById('logout_modal').close();
};
export const openGoogleConsent = () => {
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_GOOGLE_CALLBACK;
    const responseType = 'code';
    const scope = 'email profile';
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=offline&prompt=consent`

};

export const handleGoogleAuth = async (code: string) => {
    const response = await fetch(import.meta.env.VITE_POST_GOOGLE, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({code: code}),
    });

    const authResponse = await response.json()

    if (authResponse.token) {
        await saveLoginData(authResponse);
        navigate('/', { replace: true });
        sessionStorage.setItem('isAuthenticated', 'true');
        pageStore.set(EmptyContentPage);
    } else {
        localStorage.setItem('googleRegisterDto', JSON.stringify(authResponse));
        window.location.replace(import.meta.env.VITE_REGISTER_URI);
    }

};