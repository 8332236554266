<script lang="ts">
    import {handleGoogleAuth} from "../../service/auth/AuthService";
    import {getUrlParam} from "../../utils/Utils";

    // Get the 'code' parameter
    const code = getUrlParam('code')

    if (code===null) alert("error")

    handleGoogleAuth(code!!).then()
        .catch(e => {
            const errorMessage = encodeURIComponent("unexpectedError");
            window.location.href = `/login?error=${errorMessage}`;
        });

</script>

<div class="fullscreen-container">
    <span class="loading loading-lg"></span>
</div>

<style>
    .fullscreen-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7); /* Optional: background color with transparency */
    }
</style>