<script lang="ts">
    import { onMount } from 'svelte';
    import Chart from 'chart.js/auto';
    import type { Pair } from "../../../model/billing/DashboardStatsDto";
    import {darkThemeStore, selectedTheme} from "../../../redux/stores";

    export let monthlyUsageChartAstro: Pair<number, bigint>[];
    export let monthlyUsageChartUSD: Pair<number, bigint>[];
    export let currency: string;

    let monthlyUsageChart = [];
    let chartCanvas;
    let usageChart;

    function transformChartData(chartData) {
        const labels = chartData.map(p => `Day: ${p.first}`);
        const data = chartData.map(p => p.second);
        return { labels, data };
    }

    function updateChartStyle() {
        let isDarkMode = $selectedTheme;
        const chartTextColor = isDarkMode==='dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgb(0, 0, 0)';
        const chartGridColor = isDarkMode==='dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';

        if (usageChart) {
            usageChart.options.scales.y.ticks.color = chartTextColor;
            usageChart.options.scales.y.grid.color = chartGridColor;
            usageChart.options.scales.x.ticks.color = chartTextColor;
            usageChart.options.scales.x.grid.color = chartGridColor;
            usageChart.options.plugins.legend.labels.color = chartTextColor;
            usageChart.update();
        }
    }

    function updateChart() {
        const { labels, data } = transformChartData(monthlyUsageChart);
        if (usageChart) {
            usageChart.data.labels = labels;
            usageChart.data.datasets[0].data = data;
            usageChart.update();
        }
    }

    onMount(() => {
        const context = chartCanvas.getContext('2d');
        usageChart = new Chart(context, {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{ label: 'Consumption', data: [], color: 'rgb(75, 192, 192)', tension: 0.1 }]
            },
            options: { scales: { y: { beginAtZero: true } } }
        });
        monthlyUsageChart = currency === 'ASTRO' ? monthlyUsageChartAstro : monthlyUsageChartUSD;
        updateChart();
        updateChartStyle();
    });

    $: if (currency === 'ASTRO') {
        monthlyUsageChart = monthlyUsageChartAstro;
        updateChart()
    } else if (currency === 'USD') {
        monthlyUsageChart = monthlyUsageChartUSD;
        updateChart()
    }
    $: updateChartStyle(), $selectedTheme;
</script>

<div class="chart-container bg-base-100 p-4 rounded-lg shadow-md">
    <canvas bind:this={chartCanvas} aria-label="Monthly Usage Graph" role="img"></canvas>
</div>

<style>
    .chart-container {
        background: var(--bg-base-100);
        border-radius: 0.5rem;
        box-shadow: var(--box-shadow-xl);
    }
</style>
