<script lang="ts">
    import type { Usage } from "../../../model/billing/Usage";
    import { UsageType } from "../../../model/billing/Usage";
    import { formatDate, formatToThousandK } from "../../../utils/Utils";
    import { t } from 'svelte-i18n';

    export let recentTransactionsListAstro: Usage[];
    export let recentTransactionsListUSD: Usage[];
    export let currency;
    let limit: number = 10;

    $: recentTransactionsList = currency === 'ASTRO' ? recentTransactionsListAstro : recentTransactionsListUSD;
    $: recentTransactionsList = recentTransactionsList.slice(0, limit);
</script>

<div class="recent-transactions bg-base-100 p-4 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-2 text-base-content">{$t('RecentUsages')}</h2>
    <div class="text-base-content">
        {#if recentTransactionsList.length > 0}
            <ul>
                {#each recentTransactionsList as transaction, i}
                    <li class="mb-2">
                        <div class="grid grid-cols-4 items-center">
                            <span class="transaction-index text-sm col-span-1">{i + 1}.</span>
                            <span class="transaction-type text-sm">{UsageType[transaction.usageType].replace("_", " ")}</span>
                            <span class="transaction-amount col-span-1 text-center">{formatToThousandK(transaction.quantity)} {currency}</span>
                            <span class="transaction-date text-sm col-span-1 text-right">{formatDate(transaction.createdAt)}</span>
                        </div>
                    </li>
                {/each}
            </ul>
        {:else}
            <p>{$t('NoRecentTransactions')}</p>
        {/if}
    </div>
</div>

<style>
    .recent-transactions {
        background: var(--bg-base-100);
        color: var(--text-base-content);
        border-radius: 0.5rem;
        box-shadow: var(--box-shadow);
    }
    .transaction-amount {
        color: var(--text-info);
        font-weight: bold;
    }
    .transaction-date {
        color: var(--text-secondary);
    }
</style>
