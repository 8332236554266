<script lang="ts">
    import {t} from "svelte-i18n";
    import StatusBadge from "../Common/StatusBadge.svelte";
    import {formatDateToReadable} from "../../../utils/DateUtils";
    import {PositionStatus} from "../../../model/jobs/Position";
    export let positions;
    export let currentStatus: PositionStatus;
    export let viewPosition: (id: string) => void;
</script>

<div class="overflow-x-auto">
    <table class="table w-full">
        <thead>
        <tr>
            <th>{$t('Position')}</th>
            <th>{$t('Description')}</th>
            <th>{$t('Applicants')}</th>
            <th>{$t('Status')}</th>
            <th>{$t('CreatedAt')}</th>
            <th>{$t('Actions')}</th>
        </tr>
        </thead>
        <tbody>
        {#each positions as position}
            <tr>
                <td>{position.title}</td>
                <td>{position.description}</td>
                <td>{position.applicantCount}</td>
                <td><StatusBadge status={position.status} /></td>
                <td>{formatDateToReadable(position.createdAt)}</td>
                <td>
                    <button class="btn btn-outline btn-accent" on:click={() => viewPosition(position.id)}>
                        {$t('ViewPosition')}
                    </button>
                </td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>
