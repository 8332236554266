<script lang="ts">
    import { getPaymentMethodDetails, updatePaymentMethod } from "../../../service/PaymentsService";
    import { loadStripe } from "@stripe/stripe-js";
    import { CardCvc, CardExpiry, CardNumber, Elements } from "svelte-stripe";
    import { onMount } from "svelte";
    import type { PaymentMethodsDetails } from "../../../model/billing/PaymentMethodsDetails";
    import { darkThemeStore } from "../../../redux/stores";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";
    import { t } from "svelte-i18n";

    let stripe = null;
    let paymentMethod: PaymentMethodsDetails = { brand: "", lastFour: "" };

    onMount(async () => {
        stripe = await loadStripe("pk_test_51OK2ZZDPYpaKRSjDFLmF6UHwoJythEKWlIcjZkPAF64YwgEB0WHnkjwIaQRl5u9AJnMrqBulRvTtWPF4W2FRe89S001E635gme");
        paymentMethod = await getPaymentMethodDetails();
    });

    let cardElement;
    let darkMode = $darkThemeStore;
    let processing = false;

    async function onSubmit() {
        if (processing) return;
        processing = true;

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: { name },
        });

        if (error) {
            pushErrorToast($t("PaymentMethodUpdateError"));
            console.error("Error creating payment method:", error);
            processing = false;
            return;
        }

        // SHOULD TRIGGER AN ANIMATION
        await updatePaymentMethod(paymentMethod.id)
            .then((r) => {
                paymentMethod.brand = r.brand;
                paymentMethod.lastFour = r.lastFour;
                pushSuccessToast($t("PaymentMethodUpdated"));
            })
            .catch((e) => {
                pushErrorToast($t("PaymentMethodUpdateError"));
                throw e
            });
    }

    let name = "";

    // Reactive class variable for dark mode
    $: inputClasses = darkMode ? "input input-bordered w-full dark-input" : "input input-bordered w-full";
</script>

<div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold text-center mb-8">{$t("BillingInformation")}</h1>

    <div class={darkMode ? "bg-base-200 p-6 rounded-lg shadow-lg mb-8 dark-bg" : "bg-base-200 p-6 rounded-lg shadow-lg mb-8"}>
        <div class="mb-4">
            <h2 class="text-lg font-semibold">{$t("CurrentPaymentMethod")}</h2>
            <p class={darkMode ? "text-white" : "text-gray-700"}>
                <b>{paymentMethod.brand}</b> {$t("EndingIn")} <b>{paymentMethod.lastFour}</b> <i class="fas fa-credit-card mr-2"></i>
            </p>
        </div>
    </div>

    <form on:submit|preventDefault={onSubmit}>
        <Elements stripe={stripe}>
            <div class="form-control w-full mb-4">
                <label class={darkMode ? "label dark-label" : "label"}>
                    <span class="label-text">{$t("YourName")}</span>
                </label>
                <input type="text" bind:value={name} placeholder={$t("EnterYourName")} class={inputClasses} />
            </div>

            <div class="form-control w-full mb-4">
                <label class={darkMode ? "label dark-label" : "label"}>
                    <span class="label-text">{$t("CardNumber")}</span>
                </label>
                <CardNumber bind:element={cardElement} class={inputClasses} />
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div class="form-control">
                    <label class={darkMode ? "label dark-label" : "label"}>
                        <span class="label-text">{$t("ExpiryDate")}</span>
                    </label>
                    <CardExpiry class={inputClasses} />
                </div>
                <div class="form-control">
                    <label class={darkMode ? "label dark-label" : "label"}>
                        <span class="label-text">{$t("CVC")}</span>
                    </label>
                    <CardCvc class={inputClasses} />
                </div>
            </div>
            <br>
            <button class={darkMode ? "btn btn-primary w-full dark-btn" : "btn btn-primary w-full"}>
                {$t("SavePaymentMethod")}
            </button>
        </Elements>
    </form>
</div>

<style>
    /* Dark mode styles */
    .dark-input {
        background-color: #333;
        color: #fff;
        border-color: #444;
    }

    .dark-bg {
        background-color: #1a1a1a;
    }

    .dark-label {
        color: #aaa;
    }

    .dark-btn {
        background-color: #4b5563;
        color: #fff;
    }

    :global(.StripeElement--darkMode) {
        background-color: #333 !important;
        color: #fff !important;
    }
</style>
