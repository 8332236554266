<script lang="ts">
    import {t} from "svelte-i18n";
    import type {ApplicantDBTableData} from "../../../model/applicant/ApplicantDBTableData";
    import type {Page} from "../../../model/Page";
    import {getApplicantsForDBPage, searchApplicantsCompanyByKeyword} from "../../../service/ApplicantService";
    import {userInfo} from "../../../redux/stores";

    export let applicants: Page<ApplicantDBTableData>;
    let isSearch = false;
    let keyword = "";
    let companyId = $userInfo.companyId;

    async function searchApplicants(){
        if(isSearch){
            applicants = await searchApplicantsCompanyByKeyword(0,keyword,companyId);
        }else{
            applicants = await getApplicantsForDBPage(0);
        }
    }


    function search(){
        isSearch = true;
        searchApplicants();
    }

    function clear(){
        isSearch = false;
        keyword = "";
        searchApplicants();
    }

</script>


<input type="text" placeholder={$t('SearchByKeyword')} class="input input-bordered" bind:value={keyword}/>

{#if keyword.length >= 3}
    <button class="btn btn-primary px-4 py-2 ml-1" on:click={() => search()}>{$t('Search')} </button>
{:else}
    <button class="btn btn-secondary px-4 py-2 ml-1" on:click={() => clear()}>{$t('Clear')}</button>
{/if}
