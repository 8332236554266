<script lang="ts">


    import {deleteInterview, getInterviewDisplay} from "../../../service/InterviewService";
    import {breadcrumbs, pageStore, selectedInterviewDisplayId} from "../../../redux/stores";
    import InterviewDatabasePage from "../InterviewDatabasePage/InterviewDatabasePage.svelte";
    import {toast} from "@zerodevx/svelte-toast";
    import InterviewTranscriptComponent from "./InterviewTranscriptComponent.svelte";
    import Landing from "../../content/Landing.svelte";
    import {pushErrorToast, pushSuccessToast} from "../../../service/ToastService";


    breadcrumbs.set([{ name: 'Home', component: Landing }, { name: 'Interviews', component: InterviewDatabasePage }, { name: 'Interview', component: this }])

    function handleDelete(interviewId: string) {
        deleteInterview(interviewId).then(
            ()=>{
                pushSuccessToast("Interview Deleted!")
                pageStore.set(InterviewDatabasePage);
            }

        ).catch(
           ()=>

        pushErrorToast("Error while trying to delete the Interview!")
        )
    }
</script>
<div class="container mx-auto p-4">
    <h1 class="text-3xl font-bold text-center mb-8">Interview Review</h1>

    {#await getInterviewDisplay($selectedInterviewDisplayId)}
        hola
    {:then i}
        <div class="bg-base-200 p-6 rounded-lg shadow-lg">
            <div class="flex items-center justify-between mb-4">
                <h2 class="text-xl font-semibold">{i.position} {i.name}</h2>
                <button class="btn btn-error" onclick="delete_interview_modal.showModal()">Delete Interview</button>
            </div>

            <div class="flex gap-4 items-center mb-4">
                <div class="avatar">
                    <img src={"interview.applicant.image"} alt="Applicant" class="rounded-full" />
                </div>
                <div>
                    <p class="text-lg font-semibold">{i.candidate}</p>
                    <p class="text-sm text-gray-500">{i.position}</p>
                </div>
            </div>

            <video controls class="w-full rounded-lg" oncontextmenu="return false;">
                <source src={i.video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div class="mt-4">
                <p><b>Code</b>: {i.code}</p>
                <p><b>Date</b>: {i.date}</p>
                <!-- Other details if necessary -->
            </div>

            <InterviewTranscriptComponent transcript={i.transcriptDto} applicantName={i.candidate} interviewCode={i.code}/>
        </div>

        <dialog id="delete_interview_modal" class="modal">
            <div class="modal-box">
                <h3 class="font-bold text-lg">Are you sure you want to delete the interview?</h3>
                <p class="py-4">
                    Are you sure you want to delete the interview?
                    <br><br>
                    <strong>Details:</strong>
                    <li>Interview Code: <span id="interviewCode">{i.code}</span></li>
                    <li>Position: <span id="positionName">{i.position}</span></li>
                    <li>Applicant: <span id="applicantName">{i.candidate}</span></li>
                    <br>
                    Please note that this action is irreversible. <b>Once deleted, you will not be able to recover any data related to this interview.</b>
                    <br><br>
                    If you are certain and understand the implications, please confirm your action below.
                </p>
                <div class="modal-action">
                    <button class="btn btn-error" id="confirmDeleteButton">Delete</button>
                </div>
            </div>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    {:catch e}
        e
    {/await}

</div>
