<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onMount, onDestroy } from "svelte";
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import type { ScreeningQuestion } from "../../model/jobs/ScreeningQuestion";
    import TabKeyPrompt from "../Util/Kbd/TabKeyPrompt.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";

    export let positionData: JobPostGeneratorDto;
    export let handleNext: () => void;
    export let screeningQuestions: ScreeningQuestion[] = [];

    let predefinedQuestions: ScreeningQuestion[] = [
        { text: $t('ScreeningQuestionBackgroundCheck'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionDriversLicense'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionDrugTest'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionEducation'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionExpertise'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionHybridWork'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionIndustryExperience'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionLanguage'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionLocation'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionOnsiteWork'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionRemoteWork'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionVisaStatus'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionWorkAuthorization'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionWorkExperience'), mustHave: false, idealAnswer: '' },
        { text: $t('ScreeningQuestionCustom'), mustHave: false, idealAnswer: '' }
    ];

    let newQuestionText = '';
    let newQuestionMustHave = false;
    let newQuestionIdealAnswer = '';
    let nextButton: HTMLButtonElement;
    let customQuestionInput: HTMLInputElement;
    let idealAnswerInput: HTMLInputElement;
    let mustHaveCheckbox: HTMLInputElement;
    let addQuestionButton: HTMLButtonElement;

    function addScreeningQuestion(question: ScreeningQuestion) {
        if (!screeningQuestions.includes(question)) {
            screeningQuestions = [...screeningQuestions, question];
            predefinedQuestions = predefinedQuestions.filter(q => q.text !== question.text);
        }
    }

    function removeScreeningQuestion(question: ScreeningQuestion) {
        screeningQuestions = screeningQuestions.filter(q => q !== question);
        predefinedQuestions = [...predefinedQuestions, { ...question, mustHave: false, idealAnswer: '' }];
    }

    function addCustomQuestion() {
        if (newQuestionText) {
            addScreeningQuestion({
                text: newQuestionText,
                mustHave: newQuestionMustHave,
                idealAnswer: newQuestionIdealAnswer
            });
            resetCustomQuestionFields();
            customQuestionInput.focus();
        }
    }

    function resetCustomQuestionFields() {
        newQuestionText = '';
        newQuestionMustHave = false;
        newQuestionIdealAnswer = '';
    }

    function setCustomQuestionFields(question: ScreeningQuestion) {
        newQuestionText = question.text;
        newQuestionMustHave = question.mustHave;
        newQuestionIdealAnswer = question.idealAnswer;
    }

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (document.activeElement === customQuestionInput && newQuestionText) {
                event.preventDefault();
                addCustomQuestion();
            } else if (document.activeElement === idealAnswerInput && newQuestionIdealAnswer) {
                event.preventDefault();
                addCustomQuestion();
            } else if (screeningQuestions.length > 0) {
                event.preventDefault();
                handleNext();
            } else if (document.activeElement === mustHaveCheckbox) {
                event.preventDefault();
                newQuestionMustHave = !newQuestionMustHave;
            }
        } else if (event.key === 'Tab') {
            event.preventDefault();
            if (document.activeElement === customQuestionInput) {
                idealAnswerInput.focus();
            } else if (document.activeElement === idealAnswerInput) {
                if (newQuestionIdealAnswer) {
                    mustHaveCheckbox.focus();
                } else {
                    nextButton.focus();
                }
            } else if (document.activeElement === mustHaveCheckbox) {
                if (newQuestionText) {
                    addQuestionButton.focus();
                } else {
                    nextButton.focus();
                }
            }
        }
    }

    function handleNextKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
        }
    }

    onMount(() => {
        customQuestionInput.focus();
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('ScreeningQuestionsTitle')}</h2>
        <div class="mb-6">
            <p><strong>{$t('JobTitle')}:</strong> {positionData.jobTitle}</p>
            <p><strong>{$t('WorkplaceType')}:</strong> {positionData.workplaceType}</p>
            <p><strong>{$t('Country')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
            <p><strong>{$t('JobType')}:</strong> {positionData.employmentType}</p>
            <p><strong>{$t('RoleDescription')}:</strong> {positionData.jobDescription}</p>
            <p><strong>{$t('Skills')}:</strong> {positionData.skills.join(', ')}</p>
        </div>
        <p class="text-center mb-6">{$t('ScreeningQuestionPrompt')}</p>
        <TabKeyPrompt/>
        <div class="mb-4">
            {#each screeningQuestions as question}
                <div class="card card-bordered mb-2">
                    <div class="card-body">
                        <div class="flex justify-between">
                            <div>{question.text}</div>
                            <button class="text-error" on:click={() => removeScreeningQuestion(question)}>✕</button>
                        </div>
                        <div class="mt-2">
                            <p><strong>{$t('IdealAnswer')}:</strong> {question.idealAnswer}</p>
                            <label class="flex items-center mt-2">
                                <input
                                        type="checkbox"
                                        bind:checked={question.mustHave}
                                        class="checkbox checkbox-primary"
                                        disabled
                                />
                                <span class="ml-2">{$t('MustHaveQualification')}</span>
                            </label>
                        </div>
                    </div>
                </div>
            {/each}
        </div>
        <div class="mb-4">
            <input
                    type="text"
                    bind:value={newQuestionText}
                    placeholder={$t('EnterCustomQuestion')}
                    class="input input-bordered w-full mb-2"
                    bind:this={customQuestionInput}
            />
            <input
                    type="text"
                    bind:value={newQuestionIdealAnswer}
                    placeholder={$t('IdealAnswerPlaceholder')}
                    class="input input-bordered w-full mb-2"
                    bind:this={idealAnswerInput}
            />
            <label class="flex items-center">
                <input
                        type="checkbox"
                        bind:checked={newQuestionMustHave}
                        class="checkbox checkbox-primary"
                        bind:this={mustHaveCheckbox}
                />
                <span class="ml-2">{$t('MustHaveQualification')}</span>
            </label>
            <EnterToContinue action={$t('to add a question')}/>
            <button on:click={addCustomQuestion} class="btn btn-primary w-full mt-2" bind:this={addQuestionButton}>
                {$t('AddCustomQuestion')}
            </button>
            <TabKeyPrompt action={$t("to continue")}/>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
            {#each predefinedQuestions as question}
                <button
                        on:click={() => setCustomQuestionFields(question)}
                        class="btn btn-outline w-full"
                >
                    + {question.text}
                </button>
            {/each}
        </div>
        <EnterToContinue/>
        <button
                bind:this={nextButton}
                disabled={screeningQuestions.length < 1}
                on:click={handleNext}
                on:keydown={handleNextKeydown}
                class="btn btn-primary w-full focus-green"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
    .btn-primary:focus {
        background-color: green;
        color: white;
    }
</style>
