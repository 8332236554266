<script lang="ts">
    import {Country} from "../../model/location/Country";
    import type {Location} from "../../model/jobs/Location";
    import {onMount} from "svelte";

    export let location: Location;
    export let selectElement: HTMLSelectElement;
    let countries: Country[] = Object.values(Country) as Country[];

    function formatCountryName(country: string): string {
        return country.replace(/_/g, ' ');
    }

    function findCountryByCode(code: string): Country | null {
        // Convert the country code to uppercase and match it with the enum values
        const matchingCountry = countries.find(country => country.toUpperCase().includes(code.toUpperCase()));
        return matchingCountry || null;
    }

    onMount(() => {
        const language = navigator.language || navigator.userLanguage;
        const countryCode = language.split('-')[1]; // This will give you the country code (if available)

        if (countryCode) {
            const matchedCountry = findCountryByCode(countryCode);
            if (matchedCountry) {
                location.country = matchedCountry;
            }
        }
        try {
            selectElement.focus();
        } catch (error) {
            console.error("Error focusing select element:", error);
        }
    });
</script>

<select bind:value={location.country} class="select select-bordered w-full" bind:this={selectElement}>
    {#each countries as countryOption}
        <option value={countryOption}>{formatCountryName(countryOption)}</option>
    {/each}
</select>
