<script lang="ts">
    import { onMount } from 'svelte';
    import { t } from 'svelte-i18n';
    import {PositionStatus} from "../../model/jobs/Position";
    import {
        closedPositions,
        fetchClosedPositionsShort,
        fetchNoLongerAcceptingPositionsShort,
        fetchOpenPositionsShort, noLongerAcceptingPositions, openPositions
    } from "../../service/JobPostService";
    import {breadcrumbs, pageStore, selectedPositionId} from "../../redux/stores";
    import PositionDetails from "../../views/PositionDetails.svelte";
    import CreatePositionForm from "../CreatePosition/CreatePositionForm.svelte";
    import StatusToggleButtons from "./PositionsPage/StatusToggleButtons.svelte";
    import PositionsPageTable from "./PositionsPage/PositionsPageTable.svelte";
    import PositionsPageCard from "./PositionsPage/PositionsPageCard.svelte";
    import type {OpenPositionShortDto} from "../../model/jobs/OpenPositionShortDto";
    import Landing from "./Landing.svelte";

    breadcrumbs.set([{ name: $t('Home'), component: Landing }, { name: $t('Positions'), component: undefined }]);

    let tableView = false;
    let currentStatus: PositionStatus = PositionStatus.OPEN;
    let positions: OpenPositionShortDto[] = []


    async function fetchPositionsByStatus(status: PositionStatus) {
        if (status === PositionStatus.OPEN) {
            await fetchOpenPositionsShort()
            positions = ($openPositions)
        } else if (status === PositionStatus.NO_LONGER_ACCEPTING) {
            await fetchNoLongerAcceptingPositionsShort();
            positions = ($noLongerAcceptingPositions)
        } else if (status === PositionStatus.CLOSED) {
            await fetchClosedPositionsShort();
            positions =  ($closedPositions)
        }
    }

    function toggleView() {
        tableView = !tableView;
    }

    function setStatus(status: PositionStatus) {
        currentStatus = status;
        fetchPositionsByStatus(status);
    }

    function viewPosition(id: string) {
        selectedPositionId.set(id);
        pageStore.set(PositionDetails);
    }

    onMount(() => {
        fetchPositionsByStatus(currentStatus);
    });
</script>

<div class="min-h-screen p-4">
    <div class="flex justify-between items-center mb-6">
        <h1 class="text-4xl font-bold">{$t('Positions')}</h1>
        <div>
            <button class="btn btn-primary mr-2" on:click={() => pageStore.set(CreatePositionForm)}>
                {$t('AddANewPosition')}
            </button>
            <button class="btn" on:click={toggleView}>
                {tableView ? $t('CardView') : $t('TableView')}
            </button>
        </div>
    </div>

    <StatusToggleButtons {currentStatus} setStatus={setStatus} />

    {#if tableView}
        <PositionsPageTable positions={positions} {currentStatus} viewPosition={viewPosition} />
    {:else}
        <PositionsPageCard positions={positions} viewPosition={viewPosition} />
    {/if}
</div>
