<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onMount, onDestroy } from "svelte";
    import UpAndDownArrowKeys from "../Util/Kbd/UpAndDownArrowKeys.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";

    export let language: string;
    export let handleNext: () => void;
    let selectElement: HTMLSelectElement;

    const languages = [
        { name: $t('English (US)'), id: 'en-US' },
        { name: $t('Spanish (AR)'), id: 'es-AR' },
    ];

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
        selectElement.focus();
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (language) {
                handleNext();
            }
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            selectElement.focus();
        }
    }
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-96 bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('Interview Language')}</h2>
        <p class="text-center mb-6">{$t('Please select the language in which the interview will be conducted')}</p>
        <UpAndDownArrowKeys/>
        <div class="w-full mb-4">
            <select bind:value={language} bind:this={selectElement} class="select select-bordered w-full">
                <option value="" disabled>{$t('Select a language')}</option>
                {#each languages as language}
                    <option value={language.id}>{language.name}</option>
                {/each}
            </select>
        </div>
        <EnterToContinue/>
        <button
                disabled={!language}
                on:click={handleNext}
                class="btn btn-primary w-full"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
