<script lang="ts">
import Content from "./Content.svelte";
import Drawer from "./Drawer.svelte";
import Navbar from "./Navbar.svelte";
import Footer from "./Footer.svelte";
import {pageStore} from "../redux/stores.js";
import Landing from "./content/Landing.svelte";

pageStore.set(Landing)
</script>
<Navbar/>
<Content pageComponent={$pageStore}/>
<Footer/>
<Drawer/>