import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = (key: string, ...replacements: string[]): string => {
    const regex = /{([^}]*)}/g;
    let message = get(t)(key);

    let match;
    let index = 0;
    while ((match = regex.exec(message)) !== null && index < replacements.length) {
        message = message.replace(match[0], replacements[index]);
        index++;
    }

    return message;
};

export function validateJobPositionName(name: string): ValidationResult {
    const minLength = 3;
    const maxLength = 50;
    const namePattern = /^[a-zA-Z\s]+$/;

    if (name.length < minLength) {
        return {
            isValid: false,
            message: translate('jobPositionNameMinLength', minLength.toString()), // Replace placeholder with value
        };
    }

    if (name.length > maxLength) {
        return {
            isValid: false,
            message: translate('jobPositionNameMaxLength', maxLength.toString()), // Replace placeholder with value
        };
    }

    if (!namePattern.test(name)) {
        return {
            isValid: false,
            message: translate('jobPositionNamePattern'), // No replacements needed
        };
    }

    return {
        isValid: true,
        message: translate('jobPositionNameValid'), // No replacements needed
    };
}
