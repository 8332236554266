<script lang="ts">
    import { t } from 'svelte-i18n';
    import { refreshOpenPositionsShort, stopAcceptingApplicants } from "../../service/JobPostService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import type { PositionPageData } from "../../model/jobs/PositionPageData";
    import { PositionStatus } from "../../model/jobs/Position";

    export let position: PositionPageData;
    export let toogle: boolean;
    let input: HTMLInputElement;

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('stop_applicants_modal').close();
        toogle = false;
    }

    function stop() {
        stopAcceptingApplicants(position.id)
            .then(() => {
                position.status = PositionStatus.NO_LONGER_ACCEPTING;
                refreshOpenPositionsShort();
                pushSuccessToast($t('StopApplicantsSuccess').replace("{title}", position.title));
            })
            .catch(() => pushErrorToast($t('StopApplicantsError')))
    .finally(() => closeModal());
    }
</script>

<input type="hidden" class="btn" onclick="stop_applicants_modal.showModal()" bind:this={input} />

<dialog id="stop_applicants_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{$t('StopApplicantsTitle').replace("{title}", position.title)}</h3>
        <p class="py-4">{$t('StopApplicantsConfirmation').replace("{title}", position.title)}</p>
        <div class="modal-action">
            <form method="dialog">
                <button class="btn btn-primary" on:click={() => stop()}>{$t('YesStopApplicants')}</button>
            </form>
            <button class="btn btn-secondary" on:click={() => closeModal()}>{$t('Cancel')}</button>
        </div>
    </div>
</dialog>
