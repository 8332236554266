<script lang="ts">

    import {CurrentRating} from "../../model/applicant/CurrentRating";

    export let currentRating: CurrentRating;

    $: badgeColour = getBadgeColour(currentRating);

    function getBadgeColour(currentRating: CurrentRating) {
        switch (currentRating.toString()) {
            case "REJECTED":
            case CurrentRating.BAD_FIT.toString():
                return "error";
            case "GOOD_FIT":
                return "primary";
            case "HIRED":
                return "accent";
            case CurrentRating.REQUIRES_MANUAL_REVIEW.toString():
                return "secondary";
            default:
                throw Error("Invalid Current Status");
        }
    }
</script>

<div class="badge badge-{badgeColour}">{currentRating.toString().replaceAll("_", " ")}</div>
