export enum CompanySize {
    SOLO_VENTURE = '0-1',
    MICRO_ENTERPRISE = '2-10',
    SMALL_BUSINESS = '11-50',
    MEDIUM_ENTERPRISE = '51-200',
    LARGE_BUSINESS = '201-500',
    MAJOR_CORPORATION = '501-1000',
    MULTI_NATIONAL_CORPORATION = '1001-5000',
    GLOBAL_ENTERPRISE = '5001-10000',
    CONGLOMERATE = '10000+'
}

export const companySizeMap = new Map<string, string>(
    Object.keys(CompanySize).map(key => [key, CompanySize[key as keyof typeof CompanySize]])
);

export function getCompanySizeByOrdinal(ordinal: number): string | null {
    const companySizes = Object.keys(CompanySize) as Array<keyof typeof CompanySize>;

    if (ordinal >= 0 && ordinal < companySizes.length) {
        const key = companySizes[ordinal];
        return CompanySize[key];
    }

    return null; // Return null if the ordinal is out of range
}