<script lang="ts">
    import type {PositionPageData} from "../../../model/jobs/PositionPageData";

    export let position: PositionPageData
</script>
<div class="shadow-lg rounded-lg p-6 bg-base-100">
    <h3 class="text-lg font-semibold">Job performance</h3>
    <div class="mt-4">
        <div class="flex items-center">
            <span class="text-2xl font-bold">{position.applicantCount}</span>
            <span class="ml-2 text-sm text-gray-500">Applicants</span>
        </div>
        <div class="mt-4">
            <span class="text-2xl font-bold">{position.salaryDto.currency} {position.paymentDataForPositionPageDto.totalSpent.toFixed(2)}</span>
            <span class="ml-2 text-sm text-gray-500">Spent</span>
        </div>
        <div class="mt-4 border-t pt-4">
            <span class="text-sm font-semibold">{position.salaryDto.currency} {position.paymentDataForPositionPageDto.dailyAverage.toFixed(2)} daily average</span>
        </div>
    </div>
</div>