<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onMount, onDestroy, afterUpdate } from "svelte";
    import { EmploymentType } from "../../model/jobs/EmploymentType";
    import { employmentTypeMap, getEmploymentTypeName } from "../../model/jobs/EmploymentType";
    import UpAndDownArrowKeys from "../Util/Kbd/UpAndDownArrowKeys.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";

    export let employmentType: EmploymentType;
    let buttons: HTMLButtonElement[] = [];
    let selectedButtonIndex = 0;
    export let handleNext: () => void;

    const jobTypes = Array.from(employmentTypeMap.keys());

    function handleClick(index: number) {
        employmentType = EmploymentType[jobTypes[index] as keyof typeof EmploymentType];
        selectedButtonIndex = index;
    }

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
            selectedButtonIndex = (selectedButtonIndex + 1) % buttons.length;
            handleClick(selectedButtonIndex);
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
            selectedButtonIndex = (selectedButtonIndex - 1 + buttons.length) % buttons.length;
            handleClick(selectedButtonIndex);
        } else if (event.key === 'Enter') {
            if (employmentType !== undefined) {
                handleNext();
            }
        }

        // Update focus on the selected button
        if (selectedButtonIndex !== -1 && buttons[selectedButtonIndex]) {
            buttons[selectedButtonIndex].focus();
        }

        event.preventDefault();
    }

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });

    afterUpdate(() => {
        buttons = Array.from(document.querySelectorAll('.job-type-button'));
        if (buttons.length > 0 && selectedButtonIndex !== -1) {
            buttons[selectedButtonIndex].focus();
        }
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-96 bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('JobType')}</h2>
        <p class="text-center mb-6">{$t('JobTypePrompt')}</p>
        <UpAndDownArrowKeys/>
        <div class="grid grid-cols-1 gap-4">
            {#each jobTypes as job, index}
                <button
                        class="btn w-full job-type-button {employmentType === EmploymentType[job] ? 'btn-accent' : 'btn-outline'}"
                        on:click={() => handleClick(index)}
                >
                    {getEmploymentTypeName(EmploymentType[job])}
                </button>
            {/each}
        </div>
        <br>
        {#if employmentType !== undefined}
            <EnterToContinue/>
        {/if}
        <button
                disabled={employmentType === undefined}
                on:click={handleNext}
                class="btn btn-primary w-full"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
