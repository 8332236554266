<script lang="ts">
    import {getInterviews} from "../../../service/InterviewService";
    import {redirectToInterview} from "../../../service/Redirects";
    import {t} from "svelte-i18n";

    let searchTerm = '';
    let searchDate = '';

    // Mock search functionality


</script>

<div class="container mx-auto p-4">
    <h1 class="text-3xl font-bold text-center mb-8">{$t("InterviewDatabasePage")}</h1>

    <div class="form-control">
        <div class="input-group">
            <input
                    type="text"
                    placeholder="Search by name..."
                    class="input input-bordered w-full rounded-full"
                    bind:value={searchTerm}
            />
            <input
                    type="date"
                    class="input input-bordered rounded-full"
                    bind:value={searchDate}
            />
            <button class="btn btn-square rounded-full">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>


    <div class="overflow-x-auto mt-4">

        {#await getInterviews()}
            loading...
        {:then interviews}
            <table class="table w-full table-zebra">
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Interview Name</th>
                    <th>Date</th>
                    <th>Applicant</th>
                    <th>Position</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {#each interviews as interview, n}
                    <tr>
                        <td>{interview.code}</td>
                        <td>{interview.name}</td>
                        <td>{interview.date}</td>
                        <td>{interview.candidate}</td>
                        <td>{interview.position}</td>
                        <td>
                            <button class="btn btn-primary btn-sm" on:click={() => redirectToInterview(interview.id)}>
                                Go to Interview
                            </button>
                        </td>
                    </tr>
                {/each}
                </tbody>
            </table>
        {:catch e}
            {e}
        {/await}
    </div>
</div>


<style>
    /* Ensures the table is full width */
    .table {
        min-width: 100%;
    }

    /* Adjusts the input group spacing to fit the full width */
    .input-group {
        width: 100%;
    }

    /* DaisyUI rounded-full utility will work as expected, no additional styles needed for themes */
</style>
