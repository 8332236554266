<script lang="ts">

    import {t} from "svelte-i18n";
    import type {ApplicantDBTableData} from "../../../model/applicant/ApplicantDBTableData";
    import type {Page} from "../../../model/Page";

    import {userInfo} from "../../../redux/stores";
    import {
        filterApplicantsCompanyByAge,
        filterApplicantsCompanyByCreatedAt,
        filterApplicantsCompanyByLastCompany,
        filterApplicantsCompanyByName
    } from "../../../service/ApplicantService";

    export let applicants: Page<ApplicantDBTableData>;

    let currentSort = "SortBy";
    let orderSort: string;
    let nameSort = "A-Z";
    let companySort = "A-Z";
    let minYears = 0;
    let maxYears = 20;
    let showOrderBy: boolean = false;
    let companyId = $userInfo.companyId
    let startDate: string = "all";


    async function sortByName() {
        showOrderBy = true;
        currentSort = "Name";
        orderSort = nameSort;

        if (nameSort == "A-Z") {
            applicants = await filterApplicantsCompanyByName(0, true, companyId)
        }
        if (nameSort == "Z-A") {
            applicants = await filterApplicantsCompanyByName(0, false, companyId)
        }
    }

    function sortByExp() {
        showOrderBy = true;
        currentSort = "years_of_experience";
        orderSort = "years_of_experience";
    }

    async function sortByCompany() {
        showOrderBy = true;
        currentSort = "LastCompany";
        orderSort = companySort;
        if (companySort == "A-Z") {
            applicants = await filterApplicantsCompanyByLastCompany(0, true, companyId)
        }
        if (companySort == "Z-A") {
            applicants = await filterApplicantsCompanyByLastCompany(0, false, companyId)
        }
    }

    async function sortByDate() {
        currentSort = "DateApplied";
        showOrderBy = true;

        applicants = await filterApplicantsCompanyByCreatedAt(0, startDate, companyId)
    }

    function changeOrder(orderType: 'name' | 'company') {
        if (orderType == 'name') {
            nameSort = nameSort === "A-Z" ? "Z-A" : "A-Z";
            sortByName()
        } else {
            companySort = companySort === "A-Z" ? "Z-A" : "A-Z";
            sortByCompany()
        }
    }

    async function filterByExperience() {
        applicants = await filterApplicantsCompanyByAge(0, minYears, maxYears, companyId)
    }

    function getDateRange(range: 'last_week' | 'last_month' | 'last_3_months' | 'last_6_months' | 'last_year' | 'all'): any {

        switch (range) {
            case 'last_week':
                startDate = "last_week";
                sortByDate()
                break;
            case 'last_month':
                startDate = "last_month"
                sortByDate()
                break;
            case 'last_3_months':
                startDate = "last_3_months"
                sortByDate()
                break;
            case 'last_6_months':
                startDate = "last_6_months"
                sortByDate()
                break;
            case 'last_year':
                startDate = "last_year"
                sortByDate()
                break;
            case 'all':
            default:
                startDate = "all"
                sortByDate()
                break;
        }
    }


</script>
<div class="filtering dropdown">
    <button class="btn btn-primary dropdown-toggle" tabindex="0">{$t(currentSort)}
        <svg fill="none" stroke="currentColor" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
             stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
        </svg>
    </button>
    <ul class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-10">
        <li><a href="#" on:click={() =>sortByName()}>{$t("Name")}</a></li>
        <li><a href="#" on:click={() =>sortByExp()}>{$t("years_of_experience")}</a></li>
        <li><a href="#" on:click={() =>sortByCompany()}>{$t("LastCompany")}</a></li>
        <li><a href="#" on:click={() =>sortByDate()}>{$t("DateApplied")}</a></li>
    </ul>
</div>

{#if showOrderBy}
    <div class="order dropdown">
        {#if currentSort === "Name"}
            <button class="btn btn-secondary" type="button" on:click={() => changeOrder('name')}>{orderSort}</button>
        {:else if currentSort === "years_of_experience"}

            <button class="btn btn-secondary dropdown-toggle" tabindex="0">{$t(orderSort)}</button>
            <ul tabindex="0" class="dropdown-content menu p-10 shadow-lg bg-base-100 rounded-lg w-64 z-10">
                <li>
                    <div class="flex flex-col space-y-2 -ml-9">
                        <label class="flex flex-col">
                            {$t('min')}
                            <input class="input input-bordered" type="number" min="0" bind:value={minYears}/>
                        </label>
                        <label class="flex flex-col">
                            {$t('max')}
                            <input class="input input-bordered" type="number" bind:value={maxYears}/>
                        </label>
                    </div>
                </li>
                <li>
                    <button class="btn btn-secondary w-full mt-2 -ml-4" type="button"
                            on:click={() => filterByExperience()}>{$t('show_results')}</button>
                </li>
            </ul>

        {:else if currentSort === "LastCompany"}
            <button class="btn btn-secondary " type="button" on:click={() => changeOrder('company')}>{orderSort}</button>
        {:else if currentSort === "DateApplied"}

            <button class="btn btn-secondary menu-dropdown-toggle">{$t("timeRanges")}</button>
            <ul class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-10">
                <li><a href="#" on:click={() =>getDateRange('last_week')}>{$t("last_week")}</a></li>
                <li><a href="#" on:click={() =>getDateRange('last_month')}>{$t("last_month")}</a></li>
                <li><a href="#" on:click={() =>getDateRange('last_3_months')}>{$t("last_3_months")}</a></li>
                <li><a href="#" on:click={() =>getDateRange('last_6_months')}>{$t("last_6_months")}</a></li>
                <li><a href="#" on:click={() =>getDateRange('last_year')}>{$t("last_year")}</a></li>
                <li><a href="#" on:click={() =>getDateRange('all')}>{$t("All")}</a></li>
            </ul>

        {/if}
    </div>
{/if}