<script lang="ts">
    import { t } from 'svelte-i18n';
</script>
<div class="flex flex-col items-center mb-6">
    <div class="flex items-center justify-center gap-2">
        <kbd class="kbd kbd-md">▲</kbd>
        <kbd class="kbd kbd-md">▼</kbd>
    </div>
    <span class="text-sm mt-2">{$t("Use arrow keys to navigate")}</span>
</div>

<style>
    .text-sm {
        font-size: 0.875rem;
    }

    .gap-2 {
        gap: 0.5rem; /* Adjusts spacing between arrow keys */
    }

    .mt-2 {
        margin-top: 0.5rem; /* Adds space between arrow keys and text */
    }
</style>