<script lang="ts">
    import { formatToThousandK } from "../../../utils/Utils";
    import { t } from 'svelte-i18n';

    export let astroBalance;
    export let usdBalance;
    export let currency;

    $: balance = currency === 'ASTRO' ? astroBalance : usdBalance;
</script>

<div class="current-balance bg-base-100 p-4 rounded-lg shadow-md text-center">
    <h2 class="text-2xl font-bold mb-2 text-base-content">{$t('CurrentUsage')}</h2>
    <div class="text-5xl text-info">
        {formatToThousandK(balance)} {currency}
    </div>
</div>


<style>
    .current-balance {
        /* Additional styling here */
        background: var(--bg-base-100); /* Use DaisyUI variable for background */
        border-radius: 0.5rem;
        box-shadow: var(--box-shadow);
    }

    /* You can remove the color styles from here as they are now controlled by DaisyUI classes */
</style>
