<script lang="ts">
    import { onMount } from 'svelte';
    import { t } from 'svelte-i18n'; // Importing translation function
    import { CompanyType, getCompanyTypeByOrdinal } from "../../../../model/company/CompanyType";

    export let isValid: boolean;
    export let companyType: CompanyType = CompanyType.PUBLIC_COMPANY;

    let buttons = [];
    let index = 0;

    function handleClick(aCompanyType, i) {
        companyType = aCompanyType;
        index = i;
        isValid = true;
    }

    onMount(() => {
        isValid = false;
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    });

    function handleKeydown(event) {
        isValid = true;
        const companyTypeKeys = Object.keys(CompanyType).filter((item) => isNaN(Number(item)));
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowRight':
                index = (index + 1) % companyTypeKeys.length;
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                index = (index - 1 + companyTypeKeys.length) % companyTypeKeys.length;
                break;
            default:
                return;
        }

        companyType = companyTypeKeys[index];
        buttons[index].focus();
        event.preventDefault();
    }
</script>

<div class="flex flex-col gap-6 px-6 md:px-12 items-center mt-6 w-full">
    <!-- Company Type Selection -->
    <div class="flex flex-col gap-4 w-full">
        {#each Object.keys(CompanyType).filter((item) => {
            return isNaN(Number(item));
        }) as aCompanyType, i}
            <button
                    class={`btn btn-block wide text-xl px-6 ${companyType === aCompanyType ? 'btn-primary' : 'btn-outline'}`}
                    on:click={() => { handleClick(aCompanyType, i) }}
            >
                {#if getCompanyTypeByOrdinal(i)}
                    {$t(getCompanyTypeByOrdinal(i))} <!-- Translate the company type -->
                {:else}
                    {aCompanyType}
                {/if}
            </button>
        {/each}
    </div>

    <!-- Additional fields -->
</div>

<style>
    .name-input-container {
        max-width: 640px;
        margin: 0 auto;
    }

    .name-input, .wide {
        width: 100%;
        font-size: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 8px;
    }

    .btn.wide {
        width: 100%;
        box-sizing: border-box;
    }
</style>
