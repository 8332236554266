import type {RegisterCompanyDto} from "../../service/auth/dto/RegisterCompanyDto";

export interface Usage {
    id: string,
    usageType: UsageType,
    quantity: bigint,
    createdAt: Date,
}

export enum UsageType {
    INTERVIEW_STORAGE, RESUME_PROCESSING, SCREENING_INTERVIEW, TECHNICAL_INTERVIEW, FULL_INTERVIEW
}

