<script lang="ts">
    import { t } from 'svelte-i18n';
    import { getApplicantsByKeyword } from "../service/ApplicantService";
    import { pushErrorToast, pushSuccessToast } from "../service/ToastService";
    import type { ApplicantPageData } from "../model/jobs/PositionPageData";

    let keyword: string = "";
    export let isSearch: boolean;
    export let searchKeyword: string;
    export let positionId: string;
    export let applicants: ApplicantPageData[];
    export let resultCount: number;
    export let loadingApplicants: boolean;
    export let selectedApplicant: ApplicantPageData;
    export let originalApplicants: ApplicantPageData[];
    export let totalPages: number;
    let originalTotalPages: number = totalPages;

    function search() {
        searchKeyword = keyword;
        isSearch = true;
        loadingApplicants = true;
        getApplicantsByKeyword(keyword, positionId)
            .then((r) => {
                applicants = r.content;
                resultCount = r.totalElements;
                totalPages = r.totalPages;
                pushSuccessToast($t('ApplicantsFoundSuccessfully'));
                selectedApplicant = applicants[0];
                keyword = "";
            })
            .catch(() => {
                pushErrorToast($t('UnableToFindApplicants'));
            })
            .finally(() => {
                loadingApplicants = false;
            });
    }

    function clear() {
        isSearch = false;
        loadingApplicants = true;
        applicants = originalApplicants;
        resultCount = originalApplicants.length;
        selectedApplicant = originalApplicants[0];
        pushSuccessToast($t('SearchCleared'));
        loadingApplicants = false;
        totalPages = originalTotalPages;
    }

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            if (keyword.length > 1) {
                search();
            } else {
                clear();
            }
        }
    }
</script>

<div class="form-control">
    <input type="text" placeholder={$t('SearchByKeyword')} class="input input-bordered" bind:value={keyword} on:keydown={handleKeydown} />
</div>
<div class="form-control">
    {#if keyword.length > 1}
        <button class="btn btn-primary px-4 py-2" on:click={search}>{$t('Search')}</button>
    {:else}
        <button class="btn btn-secondary px-4 py-2" on:click={clear}>{$t('Clear')}</button>
    {/if}
</div>
