import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = (key: string): string => get(t)(key);

export function isSalaryValid(min?: number, max?: number): ValidationResult {
    if (min == null && max == null) {
        return {
            isValid: true,
            message: translate('salaryValid'),
        };
    }
    if (min == null || max == null) {
        return {
            isValid: false,
            message: translate('salaryBothRequired'),
        };
    }
    if (min < 0 || max < 0) {
        return {
            isValid: false,
            message: translate('salaryPositive'),
        };
    }
    if (min > max) {
        return {
            isValid: false,
            message: translate('salaryMinGreaterThanMax')
        };
    }
    return {
        isValid: true,
        message: translate('salaryValid'),
    };
}
