<script lang="ts">
    import { t } from 'svelte-i18n';
    import { fetchApplicantsForPositionPageDataFilterByYOE } from "../../../service/ApplicantService";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";

    export let positionId: string;
    export let page: number;
    let min = 0;
    let max = 10;

    function showResults() {
        fetchApplicantsForPositionPageDataFilterByYOE(positionId, page, min, max).then(() => {
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }
</script>

<div class="dropdown">
    <label tabindex="0" class="btn btn-primary">{$t('years_of_experience')}</label>
    <ul tabindex="0" class="dropdown-content menu p-4 shadow-lg bg-base-100 rounded-lg w-64">
        <li class="p-2">
            <div class="flex flex-col space-y-4"> <!-- Adjusted spacing here -->
                <label class="flex flex-col items-center"> <!-- Centered label -->
                    {$t('min')}
                    <input class="input input-bordered w-full mt-2" bind:value={min} type="number" min="0" /> <!-- Added full width -->
                </label>
                <label class="flex flex-col items-center"> <!-- Centered label -->
                    {$t('max')}
                    <input class="input input-bordered w-full mt-2" bind:value={max} type="number" /> <!-- Added full width -->
                </label>
            </div>
        </li>
        <li class="p-2">
            <button on:click={showResults} class="btn btn-secondary w-full mt-4 text-center">
                {$t('show_results')}
            </button>
        </li>
    </ul>
</div>
