<script lang="ts">
    import { onMount } from 'svelte';
    import { Gender } from "../../../../model/applicant/Gender";
    import { t } from 'svelte-i18n';

    export let isValid = false;
    export let gender = "";

    let buttons = [];
    let index = 0;

    onMount(() => {
        isValid = false;
        window.addEventListener('keydown', handleKeydown);

        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    });

    function handleKeydown(event) {
        const genderKeys = Object.keys(Gender).filter((item) => isNaN(Number(item)));
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowRight':
                index = (index + 1) % genderKeys.length;
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                index = (index - 1 + genderKeys.length) % genderKeys.length;
                break;
            default:
                return;
        }

        gender = genderKeys[index];
        buttons[index].focus();
        event.preventDefault();
        isValid = true;
    }

    function selectGender(aGender, idx) {
        gender = aGender;
        index = idx;
        isValid = true;
    }
</script>

<!-- Gender Selection Layout -->
<div class="flex flex-col justify-center items-center gap-4 w-full max-w-md mx-auto mt-5">
    {#each Object.keys(Gender).filter((item) => isNaN(Number(item))) as aGender, idx}
        <button
                bind:this={buttons[idx]}
                class="btn {`${gender === aGender ? 'btn-primary' : 'btn-outline'}`}"
                on:click={() => selectGender(aGender, idx)}
        >
            {$t(aGender)}
        </button>
    {/each}
</div>

<style>
    .btn {
        width: 100%;
        font-size: 1.25rem;
        border-radius: 8px;
        box-sizing: border-box;
        align-items: center;
    }

    .btn-primary {
        background-color: #6a0dad; /* Example color */
        color: white;
    }

    .btn-outline {
        background-color: transparent;
        border: 2px solid #ccc;
    }

    .btn-outline:hover {
        border-color: #6a0dad;
        color: #ccc;
    }

    .btn:focus-visible{
        outline: none;
    }

    .gender-btn {
        max-width: 320px; /* Limit the button size */
    }

    /* Center buttons */
    .flex-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Responsive adjustments */
    @media (min-width: 768px) {
        .btn {
            font-size: 1.5rem;
        }
    }

    /* Adjust minimum height to better center content */
    .min-h- {
        min-height: 300px; /* Adjust this to whatever fits your layout */
    }
</style>
