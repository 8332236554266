<script lang="ts">
    import { onMount } from "svelte";
    import {getApplicantsForDBPage} from "../../service/ApplicantService";
    import {breadcrumbs} from "../../redux/stores";
    import Landing from "./Landing.svelte";
    import ApplicantsDatabaseTable from "./ApplicantsDatabase/ApplicantsDatabaseTable.svelte";
    import LoadingAnimation from "../LoadingAnimation.svelte";
    import type {Page} from "../../model/Page";
    import type {ApplicantDBTableData} from "../../model/applicant/ApplicantDBTableData";
    import { t } from 'svelte-i18n';
    import FilterAndOrder from "./ApplicantsDatabase/FilterAndOrder.svelte";
    import SearchApplicantDB from "./ApplicantsDatabase/SearchApplicantDB.svelte";

    breadcrumbs.set([{ name: 'Home', component: Landing }, { name: $t('ApplicantsDatabase'), component: undefined }]);

    let applicantCount: number = 0;
    let loading = false;
    let originalApplicants: Page<ApplicantDBTableData> = {
        content: [],
        empty: false,
        first: false,
        last: false,
        number: 0,
        numberOfElements: 0,
        pageable: {
            offset: 0,
            pageNumber: 0,
            pageSize: 0,
            paged: false,
            sort: {empty: false, sorted: false, unsorted: false},
            unpaged: false
        },
        size: 0,
        sort: {empty: false, sorted: false, unsorted: false},
        totalElements: 0,
        totalPages: 0
    }

    async function loadApplicants(){
        loading = true
        originalApplicants = await getApplicantsForDBPage(0).finally(()=>loading=false)
    }

    onMount(()=>{
        loadApplicants()
    })

</script>

<div>

    {#if loading}
        <LoadingAnimation/>
    {:else}

        <div class="recruiters-page bg-base-100 p-8 flex-row flex-grow justify-between flex">
            <div class="flex justify-between items-center mb-6">
                <h1 class="text-4xl font-bold">
                    {$t('Applicants')}
                </h1>
            </div>
            <div class="flex flex-row justify-between items-center mb-6">
                <div class="search">
                    <SearchApplicantDB bind:applicants={originalApplicants}/>
                </div>
                <div class="filter-applicants ml-2">
                    <FilterAndOrder bind:applicants={originalApplicants}/>
                </div>
                <div class="text-2xl font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-500 px-5 py-2 rounded-lg shadow-lg ml-2">
                    {$t('Total')}: {originalApplicants.totalElements}
                </div>
            </div>
        </div>

        <ApplicantsDatabaseTable bind:applicants={originalApplicants}/>
    {/if}
</div>
