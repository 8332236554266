<script lang="ts">

    import type {TranscriptDto} from "../../../model/interview/TranscriptDto";
    import {downloadTranscript} from "../../../service/InterviewService";

    export let transcript: TranscriptDto
    export let applicantName: string
    export let interviewCode: string

</script>

<div class="container mx-auto p-4">
    <div class="card card-bordered">
        <div class="card-body">
            <div class="font-bold mb-4">Transcript (Start: {transcript.startTime} - End: {transcript.endTime})</div>
            <ul>
                {#each transcript.messages as line}
                    <li class="mb-2">
                        <span class="font-bold">{line.sender}:</span>
                        <span class="ml-2">{line.message}</span>
                    </li>
                {/each}
            </ul>
        </div>
    </div>
    <button class="btn btn-primary mt-4" on:click={()=> downloadTranscript(transcript, applicantName, interviewCode)}>
        Download Transcript
    </button>
</div>
