<script lang="ts">

    import {breadcrumbs} from "../redux/stores";
    import Landing from "./content/Landing.svelte";
    import Breadcrums from "./Breadcrums.svelte";

    export let pageComponent;

    breadcrumbs.set([{ name: 'Home', component: Landing }])

</script>

<Breadcrums/>

<svelte:component this={pageComponent}/>

