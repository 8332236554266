import {toast} from "@zerodevx/svelte-toast";

const pushToast = (msg: string, toastBackground: string, toastBarBackground: string, toastColor: string) => {
    toast.push(msg, {
        theme: {
            '--toastColor': toastColor, // Keeps the text visible against the red background
            '--toastBackground':toastBackground,
            '--toastBarBackground': toastBarBackground
        }
    })
}

export const pushErrorToast = (msg: string) => {
    pushToast(msg, 'rgba(255,0,0,0.9)', '#B83227', 'white')
}

export const pushSuccessToast = (msg: string) => {
    pushToast(msg, 'rgba(72,187,120,0.9)', '#2F855A', 'mintcream')
}