<script lang="ts">
    import { t } from 'svelte-i18n';
    import type {YearsOfExperienceDto} from "../../model/jobs/YearsOfExperienceDto";
    import {onMount} from "svelte";

    export let handleNext: () => void;
    export let yearsOfExperience: YearsOfExperienceDto | undefined = undefined;

    let min: number | null = null;
    let max: number | null = null;

    let showMin: boolean = true;
    let showMax: boolean = true;

    let isValidRange: boolean = true;

    function handleEventKey(event: KeyboardEvent){
        if (event.key === 'Enter'){
            validateYoE(min,max)
        }
    }

    function validateYoE(min: number | null, max: number | null){
        if(showMax == false){
            isValidRange = true;
        }
        if(min != null){
            isValidRange = false;
            if(max!=null){
                isValidRange = !(min < max);
            }
        }else {
            isValidRange = true;
        }
    }

    function handleConfirmation(){
        if(min == null){
            yearsOfExperience = undefined
            handleNext()
        }else{
            yearsOfExperience = {min: 0, max: undefined}
            if(min !== null){
                yearsOfExperience.min = min;

                if(max !== null){
                    yearsOfExperience.max = max
                }
                handleNext()
            }
        }
    }

    onMount(() => {
        document.addEventListener('keydown', handleEventKey);
    })
</script>

<div class="flex flex-row flex-grow justify-center items-center min-h-screen bg-base-200">
    <div class="card w-full bg-base-100 shadow-xl p-6">
        <div class="flex justify-center flex-grow flex-col items-center">
        <h2 class="text-2xl font-bold mb-2">{$t("EnterYearsOfExperience")}</h2>
            <p class="">{$t("Optional")}</p>
        </div>
        <div class="flex flex-col justify-between items-center w-full h-full m-4">
            <div class="w-full items-center mb-2">
                <div class="form-control ">
                    <label class="label">{$t("min")}</label>
                    <input type="number" class="input input-bordered w-full"
                           bind:value={min} on:change={() => validateYoE(min,max)}>
                </div>
            </div>
            <div class="w-full items-center mb-2">
                <div class="form-control">
                    <label class="label">{$t("max")} ({$t("Optional")})</label>
                    <input type="number" class="input input-bordered"
                           bind:value={max} on:change={() => validateYoE(min,max)}>
                </div>
            </div>
            <div class="flex justify-between w-full mt-6">
                <button
                        disabled={min != null || max != null}
                        class="btn btn-secondary"
                        on:click={handleConfirmation}
                >
                    {$t('Skip')}
                </button>
                <button
                        disabled={isValidRange}
                        class="btn btn-primary mr-10"
                        on:click={handleConfirmation}
                >
                    {$t('Next')}
                </button>
            </div>
        </div>
    </div>
</div>

<style>
    .input{
        width: 39rem;
    }
</style>