<script lang="ts">

    import {pushErrorToast, pushSuccessToast} from "../../service/ToastService";
    import { t } from "svelte-i18n";
    import {i18nLanguage, i18nLanguageStore, updateLocaleLang} from "../../i18n";
    export let toogle: boolean;
    let selectedLocale: i18nLanguage
    let input: HTMLInputElement;
    $: if (toogle) {
        input.click();
    }

    function closeModal(){
        document.getElementById('change_language_modal').close();
        toogle = false;
    }

    async function changeLocale(){
        try {
            await updateLocaleLang(selectedLocale)
            $i18nLanguageStore = selectedLocale
            pushSuccessToast($t('LanguageUpdatedTo')+selectedLocale);
        } catch {
            pushErrorToast($t('LanguageUpdateError'));
        } finally {
            closeModal();
        }
    }
</script>

<input type="hidden" class="btn" onclick="change_language_modal.showModal()" bind:this={input}/>

<dialog id="change_language_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{ $t('ChangeLanguage') }</h3>
        <p class="py-4">{ $t('WantToChangeLangTo') }{selectedLocale}?</p>
        <select class="select select-bordered w-full max-w-xs" bind:value={selectedLocale}>
            {#each Object.keys(i18nLanguage) as locale}
                <option value={locale}>{locale}</option>
            {/each}
        </select>
        <div class="modal-action">
            <button class="btn btn-primary" on:click={changeLocale}>{ $t('YesChangeLang') }</button>
            <button class="btn btn-secondary" on:click={closeModal}>{ $t('Cancel') }</button>
        </div>
    </div>
</dialog>
