<script lang="ts">
    import { Turnstile } from 'svelte-turnstile';
    export let handleRequestOTP: (token: string) => void;
    export let otpResendEnabled: boolean;
    export let otpSent: boolean;


    let token: string | null = null;

    const onCaptchaSuccess = (event: CustomEvent<{ token: string }>) => {
        token = event.detail.token;
    };
</script>

<button
        on:click={()=>handleRequestOTP(token)}
        class="block w-full bg-indigo-500 mt-2 py-2 rounded-2xl text-white font-semibold mb-2"
        disabled={!otpResendEnabled && otpSent}
>
    {#if otpSent && !otpResendEnabled}
        Resend OTP in 5 min
    {:else if otpSent}
        Resend OTP
    {:else}
        Request OTP
    {/if}
</button>

<Turnstile siteKey={import.meta.env.VITE_TURNSTILE_KEY} theme="dark" on:callback={onCaptchaSuccess} />