<script lang="ts">
    import { t } from 'svelte-i18n';
    import { cachedApplicantsForPosition, updateRating } from "../../../service/ApplicantService";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";
    import type { ApplicantPageData } from "../../../model/jobs/PositionPageData";
    import { CurrentRating } from "../../../model/applicant/CurrentRating";

    export let selectedApplicant: ApplicantPageData;
    export let originalApplicants: ApplicantPageData[];

    // Mapping enum values to human-readable labels with translations
    const statusLabels = {
        [CurrentRating.REJECTED]: $t('StatusRejected'),
        [CurrentRating.HIRED]: $t('StatusHired'),
        [CurrentRating.GOOD_FIT]: $t('StatusGoodFit'),
        [CurrentRating.BAD_FIT]: $t('StatusBadFit'),
        [CurrentRating.REQUIRES_MANUAL_REVIEW]: $t('StatusRequiresManualReview')
    };

    // Valid statuses for the dropdown (excluding REQUIRES_MANUAL_REVIEW)
    const validStatuses = [
        CurrentRating.GOOD_FIT,
        CurrentRating.BAD_FIT,
        CurrentRating.HIRED
    ];

    // Function to determine the color class based on the current status
    function getBadgeColour(status: CurrentRating) {
        switch (status) {
            case CurrentRating.REJECTED:
            case CurrentRating.BAD_FIT:
                return "error";
            case CurrentRating.GOOD_FIT:
                return "primary";
            case CurrentRating.HIRED:
                return "accent";
            case CurrentRating.REQUIRES_MANUAL_REVIEW:
                return "secondary";
            default:
                throw new Error("Invalid Current Status");
        }
    }

    async function updateStatus(newStatus: CurrentRating) {
        updateRating(selectedApplicant.id, newStatus).then(() => {
            pushSuccessToast($t('StatusUpdated'));
            selectedApplicant.currentRating = newStatus;
            updateCaches(newStatus);
        }).catch(() => {
            pushErrorToast($t('ErrorUpdatingStatus'));
        });
    }

    function updateCaches(newStatus: CurrentRating) {
        $cachedApplicantsForPosition.content = updateRatingByID($cachedApplicantsForPosition.content, selectedApplicant.id, newStatus);
        originalApplicants = updateRatingByID(originalApplicants, selectedApplicant.id, newStatus);
    }

    const updateRatingByID = (array: ApplicantPageData[], id: string, rating: CurrentRating) => {
        return array.map(item =>
            item.id === id ? { ...item, currentRating: rating } : item
        );
    };
</script>

<div class="dropdown">
    <label tabindex="0" class="btn btn-{getBadgeColour(selectedApplicant.currentRating)} dropdown-toggle">
        {statusLabels[selectedApplicant.currentRating]}
    </label>
    {#if !selectedApplicant.rejected}
        <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {#each validStatuses as status}
                {#if status !== selectedApplicant.currentRating}
                    <li>
                        <a class="text-{getBadgeColour(status)}" on:click={() => updateStatus(status)}>
                            {statusLabels[status]}
                        </a>
                    </li>
                {/if}
            {/each}
        </ul>
    {/if}
</div>
