<script lang="ts">
    export let otpArray: string[];
    export let handleOtpInput: (index: number, event: Event) => void;
    export let handlePaste: (event: ClipboardEvent) => void;
</script>

<div class="otp-container flex justify-between mb-2">
    {#each otpArray as otp, index}
        <input
                id={"otp-input-" + index}
                type="text"
                maxlength="1"
                class="otp-input input-bordered text-center bg-white"
        bind:value={otpArray[index]}
        on:input={(e) => handleOtpInput(index, e)}
        on:paste={handlePaste}
        />
    {/each}
</div>

<style>
    .otp-container {
        display: flex;
        gap: 0.5rem;
    }

    .otp-input {
        width: 2.5rem;
        height: 3rem;
        font-size: 1.25rem;
        text-align: center;
        border-radius: 0.5rem;
        text-transform: uppercase;
        background-color: white;
    }
</style>
