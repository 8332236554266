import {makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest} from "./Requests";
import type {Applicant} from "../model/applicant/Applicant";
import type {ApplicantSearchDto} from "./dto/applicant/ApplicantSearchDto";
import type {ApplicantPageData} from "../model/jobs/PositionPageData";
import type {Page} from "../model/Page";
import {writable} from "svelte/store";
import {CurrentRating} from "../model/applicant/CurrentRating";
import type {ApplicantDBTableData} from "../model/applicant/ApplicantDBTableData";
import type {ResumeUploadResponse} from "./dto/ResumeUploadResponse";

export const cachedApplicantsForPosition = writable<Page<ApplicantPageData>>()

let applicantsPositionCache: Page<ApplicantPageData>

export const getApplicants = async (applicantSearchDto?: ApplicantSearchDto, page: number = 0, size: number = 10): Promise<Applicant[]> => {
    return await makePostRequest(import.meta.env.VITE_POST_SEARCH_APPLICANTS + "/?page=" + page + "&size=" + size, applicantSearchDto, true)

};

export const getApplicantsForDBPage = async (page: number):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS+"?page="+page)
}

export const searchApplicantsCompanyByKeyword = (page: number, keyword: string, companyId: string):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS_FILTER_SEARCH
        .replace("{{companyId}}", companyId)
        .replace("{{page}}", page)
        .replace("{{keyword}}", keyword))
}


export const filterApplicantsCompanyByAge = (page: number, min: number, max: number, companyId: string):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS_FILTER_YOE_COMPANY.replace("{{companyId}}", companyId)
        .replace("{{page}}", page)
        .replace("{{min}}", min)
        .replace("{{max}}", max))
}

export const filterApplicantsCompanyByName = (page: number, ascendingOrder: boolean, companyId: string):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS_FILTER_NAME
        .replace("{{companyId}}", companyId)
        .replace("{{ascendingOrder}}", ascendingOrder)
        .replace("{{page}}",page))
}

export const filterApplicantsCompanyByLastCompany = (page: number, ascendingOrder: boolean, companyId: string):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS_FILTER_LAST_COMPANY.replace("{{companyId}}", companyId)
        .replace("{{page}}", page)
        .replace("{{ascendingOrder}}",ascendingOrder))
}

export const filterApplicantsCompanyByCreatedAt = (page: number, range: string, companyId: string):Promise<Page<ApplicantDBTableData>> =>{
    return makeGetRequest<Page<ApplicantDBTableData>>(import.meta.env.VITE_GET_APPLICANTS_FILTER_CREATED_AT.replace("{{companyId}}", companyId)
        .replace("{{range}}", range)
        .replace("{{page}}", page))
}

export const getApplicantsByKeyword = async (keyword: string, positionId: string, page: number = 0): Promise<Page<ApplicantPageData>> => {
    return await makePostRequest(import.meta.env.VITE_POST_SEARCH_APPLICANTS + "/"+keyword+ "/"+positionId+"/"+page, undefined)

};


export const getResume = async (id: string): Promise<string> => {
    return await makeGetRequest(import.meta.env.VITE_GET_RESUME + id)
};

export const openResume = async (id: string): Promise<string | undefined> => {
    try {
        const text = await getResume(id);
        const binaryString = window.atob(text);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Failed to open resume:', error);
    }
};

export const deleteApplicant = async (id: string): Promise<any> => {
    return await makeDeleteRequest(
        import.meta.env.VITE_DELETE_APPLICANT + id,
        undefined,
        false,
        false)
};


export const getApplicantById = async (id: string): Promise<ApplicantPageData> => {
    return await makeGetRequest(import.meta.env.VITE_GET_APPLICANT_BY_ID + id)
};

export const uploadResume = async (positionId: string, file: File[]): Promise<ResumeUploadResponse> => {

    const formData = new FormData();

    for(let i = 0; i < file.length; i++ ){
        formData.append('file', file[i]);
    }

    return await makePostRequest<ResumeUploadResponse>(import.meta.env.VITE_APPLY_TO_POSITION + positionId, formData, false, "multipart/form-data", true);
};

export const getApplicantsForPositionPageData = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_BY_POSITION + id + "/"+page
    const applicants = await makeGetRequest<Page<ApplicantPageData>>(url)
    cachedApplicantsForPosition.set(applicants)
    return applicants
};

export const fetchApplicantsForPositionPageData = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_BY_POSITION + id + "/"+(page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};

export const updateRating = async (id: string, currentRating: CurrentRating) => {
    const url = import.meta.env.VITE_PATCH_UPDATE_RATING
        .replace("{{id}}", id)
        .replace("{{rating}}", currentRating.toString().toUpperCase())
    return await makePatchRequest(url)
}

export const fetchApplicantsForPositionPageDataSortedByYOE = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_YOE.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};

export const fetchApplicantsForPositionPageDataSortedByLocation = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_LOCATION.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};



export const fetchApplicantsForPositionPageDataSortedByRating = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_RATING.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};

export const fetchApplicantsForPositionPageDataSortedByAge = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_AGE.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};


export const fetchApplicantsForPositionPageDataSortedByName = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_NAME.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};


export const fetchApplicantsForPositionPageDataSortedBySurname = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_SURNAME.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};

export const fetchApplicantsForPositionPageDataSortedByRelevance = async (id: string, page: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_SORT_RELEVANCE.replace("{{positionId}}", id).replace("{{page}}", page)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};



export const fetchApplicantsForPositionPageDataFilterByYOE = async (id: string, page: number, min: number,  max: number) => {
    const url = import.meta.env.VITE_GET_APPLICANTS_FILTER_YOE
        .replace("{{positionId}}", id)
        .replace("{{page}}", page)
        .replace("{{min}}", min)
        .replace("{{max}}", max)
    cachedApplicantsForPosition.set(await makeGetRequest<Page<ApplicantPageData>>(url))
};