import {makeGetRequest, makePatchRequest, makePostRequest} from "./Requests";
import type {DashboardStatsDto} from "../model/billing/DashboardStatsDto";
import type {Usage} from "../model/billing/Usage";
import type {TransactionRequestDto} from "./dto/payments/TransactionRequestDto";
import type {PaymentMethodsDetails} from "../model/billing/PaymentMethodsDetails";
import type {Page} from "../model/Page";


export const getBillingDashboardStats = async (companyId: string): Promise<DashboardStatsDto> => {
    return  await makeGetRequest<DashboardStatsDto>(import.meta.env.VITE_GET_BILLING_DASHBOARD_STATS+companyId)
};

export const getTransactions = async (transactionRequestDto: TransactionRequestDto, page: number): Promise<Page<Usage[]>> => {
    return  await makePostRequest(import.meta.env.VITE_POST_GET_TRANSACTIONS.replace("{{page}}", page), transactionRequestDto, false)
};

export const getPaymentMethodDetails = async (): Promise<PaymentMethodsDetails> => {
    return  await makeGetRequest<PaymentMethodsDetails>(import.meta.env.VITE_GET_PAYMENT_DETAILS)
};

export const updatePaymentMethod = async (paymentMethodId: string): Promise<PaymentMethodsDetails> => {
    return await makePatchRequest<PaymentMethodsDetails>(import.meta.env.VITE_PATCH_UPDATE_PAYMENT_METHOD + paymentMethodId)
};
