<script lang="ts">
    import { sendOTP } from "../service/otp/OTPService";
    import type { ErrorResponse } from "../service/error/ErrorResponse";
    import { login } from "../service/auth/AuthService";
    import { onDestroy, onMount } from "svelte";
    import LoadingSpinner from "../lib/login/LoadingSpinner.svelte";
    import { invalidLoginCredentials } from "../redux/stores";
    import AlertMessage from "../lib/login/AlertMessage.svelte";
    import EmailInput from "../lib/login/EmailInput.svelte";
    import OtpRequestButton from "../lib/login/OtpRequestButton.svelte";
    import OtpInputFields from "../lib/login/OtpInputFields.svelte";
    import SocialLoginButtons from "../lib/login/SocialLoginButtons.svelte";
    import RegisterButtons from "../lib/login/RegisterButtons.svelte";
    import { t } from "svelte-i18n";

    let email = "";
    let otpArray = Array(6).fill("");
    let loading = false;
    let otpSent = false;
    let otpResendEnabled = false;
    let otpTimer: NodeJS.Timeout;
    let alertMessage: string | null = null;
    let alertType: string = "info";

    const urlParams = new URLSearchParams(window.location.search);
    let errorGoogleMessage = urlParams.get("error")


    const showAlert = (message: string, type: string = "info") => {
        alertMessage = message;
        alertType = type;
        setTimeout(() => (alertMessage = null), 5000);
    };

    if(errorGoogleMessage){
        showAlert($t(errorGoogleMessage), "error");
    }

    // Check if the URL contains the newUser=true parameter
    const checkNewUserParameter = () => {
        const isNewUser = urlParams.get("newUser") === "true";

        if (isNewUser) {
            showAlert($t("welcomeRequestOtp"), "info");
        }
    };

    const handleRequestOTP = async (token: string) => {
        if (!email) {
            showAlert($t("enterEmail"), "error");
            return;
        }

        if (!token) {
            showAlert($t("enterEmail"), "error");
            return;
        }

        loading = true;
        otpSent = false;

        try {
            const otpResponse = await sendOTP({ email, token });

            // Handle error responses
            if (otpResponse) {
                handleErrorResponse(otpResponse);
                return;
            }

            otpSent = true;
            showAlert($t("otpSent"), "success");
            startOtpResendTimer();
        } catch (error) {
            console.error("Error sending OTP:", error);
            showAlert($t("otpFailed"), "error");
        } finally {
            loading = false;
        }
    };

    const handleErrorResponse = (otpResponse: ErrorResponse) => {
        switch (otpResponse.code) {
            case 9009:
                showAlert($t("invalidEmail"), "error");
                break;
            case 17000:
                showAlert($t("otpAlreadySent"), "error");
                otpSent = true;
                break;
            case 9002:
                showAlert($t("accountLocked"), "error");
                break;
            default:
                showAlert($t("unexpectedError"), "error");
        }
    };

    const startOtpResendTimer = () => {
        otpResendEnabled = false;
        otpTimer = setTimeout(() => {
            otpResendEnabled = true;
        }, 300000);
    };

    const handleSubmit = async () => {
        if (!otpSent) {
            showAlert($t("requestOtpFirst"), "error");
            return;
        }

        loading = true;
        try {
            const pass = otpArray.join("");
            const authRequest = { email, pass };
            await login(authRequest);
        } catch (error) {
            loading = false;
            showAlert($t("invalidOtp"), "error");
            throw error
        } finally {
            loading = false;
        }
    };

    const handleOtpInput = (index: number, event: Event) => {
        const target = event.target as HTMLInputElement;
        const value = target.value.toUpperCase();
        if (/^[0-9A-Z]*$/.test(value)) {
            otpArray[index] = value;
        }
        if (value && index < otpArray.length - 1) {
            (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
        }
        if (otpArray.join("").length === 6) {
            handleSubmit();
        }
    };

    const handlePaste = (event: ClipboardEvent) => {
        event.preventDefault();
        const clipboardData = event.clipboardData?.getData("Text");
        if (clipboardData && clipboardData.length === 6) {
            otpArray = clipboardData
                .toUpperCase()
                .split("")
                .map((char) => (/^[0-9A-Z]$/.test(char) ? char : ""));
            otpArray.forEach((char, index) => {
                const inputElement = document.getElementById(`otp-input-${index}`) as HTMLInputElement;
                inputElement.value = char;
            });
            handleSubmit();
        }
    };

    onMount(() => {
        checkNewUserParameter(); // Check if the user is new when the component is mounted

        const loginButton = document.querySelector("#loginButton");
        if (loginButton) {
            loginButton.addEventListener("click", async (event) => {
                event.preventDefault();
                await handleSubmit();
            });
        }
    });

    onDestroy(() => {
        if (otpTimer) {
            clearTimeout(otpTimer);
        }
    });
</script>

<LoadingSpinner {loading} />

<div class="h-screen md:flex">
    <div class="relative overflow-hidden md:flex w-1/2 bg-gradient-to-tr from-blue-800 to-purple-700 justify-around items-center hidden">
        <div>
            <h1 class="text-white font-bold text-4xl font-sans">Astrovel</h1>
            <p class="text-white mt-1">{$t("astrovelDescription")}</p>
            <button class="block w-28 bg-white text-indigo-800 mt-4 py-2 rounded-2xl font-bold mb-2" on:click={() => window.open("https://astrovel.net", "_blank")}>
                {$t("readMore")}
            </button>
        </div>
    </div>
    <div class="flex md:w-1/2 justify-center py-10 items-center">
        <form on:click|preventDefault={() => {}}>
            <h1 class="text-gray-50 font-bold text-2xl mb-1">
                {#if urlParams.get("newUser") === "true"}
                    {$t("welcomeUser").replace("{name}",urlParams.get("name"))}
                {:else}
                    {$t("helloAgain")}
                {/if}
            </h1>
            <p class="text-sm font-normal text-gray-500 mb-7">{$t("welcomeBack")}</p>

            {#if $invalidLoginCredentials}
                <div role="alert" class="alert alert-error">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{$t("invalidLoginCredentials")}</span>
                </div>
                <br />
            {/if}

            <AlertMessage {alertMessage} {alertType} />
            <br>
            <EmailInput bind:email />
            <OtpRequestButton {handleRequestOTP} {otpResendEnabled} {otpSent} />
            {#if otpSent}
                <OtpInputFields {otpArray} {handleOtpInput} {handlePaste} />
            {/if}
            <RegisterButtons />
            <SocialLoginButtons />
        </form>
    </div>
</div>
