<script lang="ts">
    import { onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import { t } from 'svelte-i18n';
    import { resendVerificationEmail } from "../../../service/auth/AuthService";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";
    import { userEmailStore } from "../../../redux/stores";

    let isResendDisabled = writable(false);
    let timeLeft = writable(300); // 5 minutes countdown (in seconds)
    let timerInterval;

    let channel = new BroadcastChannel('email-validation-channel');

    function resendValidationEmail() {
        isResendDisabled.set(true);

        resendVerificationEmail($userEmailStore).then(() => {
            pushSuccessToast($t('resend_email_success')); // Use translation
        }).catch((error) => {
            const errorMsg = error.message || $t('resend_email_error'); // Use translation
            pushErrorToast(errorMsg);
        });

        // Start countdown for 5 minutes (300 seconds)
        startCountdown();
    }

    function startCountdown() {
        timeLeft.set(300); // Reset timer to 5 minutes
        timerInterval = setInterval(() => {
            timeLeft.update(n => {
                if (n > 1) return n - 1;
                clearInterval(timerInterval);
                isResendDisabled.set(false); // Re-enable button after countdown finishes
                return 0;
            });
        }, 1000);
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`; // Format as MM:SS
    }

    onMount(() => {
        channel.onmessage = (event) => {
            if (event.data.toString() == 'close') {
                window.close();
            }
        };
    });

    $: if ($isResendDisabled && $timeLeft <= 0) {
        clearInterval(timerInterval);
        isResendDisabled.set(false);
    }
</script>

<div class="flex justify-center items-center h-screen bg-base-200">
    <div class="content-box bg-base-100 shadow-xl rounded-lg p-8 md:max-w-md w-full">
        <h2 class="text-3xl font-semibold mb-6">{$t('email_verification_required')}</h2>

        <p class="text-lg mb-4">
            {$t('email_sent')} <strong>{$userEmailStore}</strong>. {$t('email_instructions')}
        </p>

        <div class="mt-4 text-center">
            <p class="text-sm text-gray-500 mb-2">{$t('did_not_receive_email')}</p>
            <button
                    class="btn btn-outline btn-primary w-full"
                    on:click={resendValidationEmail}
                    disabled={$isResendDisabled}
                    aria-live="polite"
            >
                {#if $isResendDisabled}
                    {$t('resend_email_wait').replace("{timeLeft}",formatTime($timeLeft))}
                {:else}
                    {$t('resend_email')}
                {/if}
            </button>
            {#if $isResendDisabled}
                <p class="text-sm text-gray-400 mt-2">{$t('resend_email_in_progress').replace("{timeLeft}",formatTime($timeLeft))}</p>
            {/if}
        </div>
    </div>
</div>

<style>
    .content-box {
        max-width: 600px;
    }
</style>
