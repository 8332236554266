<script lang="ts">
    import {getTransactions} from "../../../service/PaymentsService";
    import {breadcrumbs, userInfo} from "../../../redux/stores";
    import type {TransactionRequestDto} from "../../../service/dto/payments/TransactionRequestDto";
    import {UsageType} from "../../../model/billing/Usage";
    import {formatDate} from "../../../utils/Utils";
    import Landing from "../Landing.svelte";
    import BillingAndUsage from "../BillingAndUsage.svelte";
    import {onMount} from "svelte";
    import { t } from 'svelte-i18n';

    breadcrumbs.set([{ name: 'Home', component: Landing },
        { name: 'Billing & Usage', component: BillingAndUsage },
        {name: 'Usages', component: undefined}])

    let transactionRequest: TransactionRequestDto = { companyId: $userInfo?.companyId!! }
    let currencyType = 'ASTRO';
    let transactions: any[] = [];
    let loading = true;
    let error: any = null;
    let currentPage = 0;
    let totalPages = 0;

    async function loadTransactions(page: number = 0): Promise<void> {
        try {
            const data = await getTransactions(transactionRequest, page);
            transactions = data.content || [];
            currentPage = data.number;
            totalPages = data.totalPages;
        } catch (err) {
            error = err;
        } finally {
            loading = false;
        }
    }

    function changePage(page: number): void {
        if (page >= 0 && page < totalPages) {
            loadTransactions(page);
        }
    }

    function getPagesArray(): number[] {
        return Array.from({ length: totalPages }, (_, i) => i);
    }

    onMount(() => {
        loadTransactions();
    });


</script>

{#if loading}
    <p>...waiting</p>
{:else if error}
    <p style="color: red">{error.message}</p>
{:else}
    <div class="transactions-page min-h-screen p-4">
        <h1 class="text-4xl mb-6 text-theme-base-content">{$t('AllTransactions')}</h1>
        <button class="btn btn-primary mb-6" on:click={() => currencyType = currencyType === 'ASTRO' ? '$' : 'ASTRO'}>
            Switch to {currencyType === 'ASTRO' ? 'USD' : 'ASTRO'}
        </button>
        <div class="overflow-x-auto relative">
            <table class="table w-full text-sm text-theme-base-content">
                <thead class="text-xs uppercase bg-theme-base-300">
                <tr>
                    <th scope="col" class="py-3 px-6">#</th>
                    <th scope="col" class="py-3 px-6">{$t('Type')}</th>
                    <th scope="col" class="py-3 px-6">{$t('Amount')}</th>
                    <th scope="col" class="py-3 px-6">{$t('Date')}</th>
                </tr>
                </thead>
                <tbody>
                {#each transactions as transaction, i}
                        <tr class="hover:bg-theme-base-200">
                            <td class="py-4 px-6">{i+1+(currentPage*25)}</td>
                            <td class="py-4 px-6">{UsageType[transaction.usageType].replace("_", " ")}</td>
                            <td class="py-4 px-6">{currencyType === 'ASTRO' ? transaction.quantity+' ASTRO' : (transaction.quantity * 0.001).toFixed(2)+' USD'}</td>
                            <td class="py-4 px-6">{formatDate(transaction.createdAt)}</td>
                        </tr>
                {/each}
                </tbody>
            </table>
        </div>

        {#if totalPages > 0}
            <div class="pagination-controls mt-4">
                {#each getPagesArray() as page}
                    <button class="btn btn-primary" on:click={() => changePage(page)} class:btn-disabled={page === currentPage}>{page + 1}</button>
                {/each}
            </div>
        {/if}
    </div>
{/if}
