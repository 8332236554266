<script lang="ts">
    import { t } from 'svelte-i18n';
    import { pageStore, userInfo } from "../redux/stores";
    import OpenPositions from "./content/PositionsPage.svelte";
    import Landing from "./content/Landing.svelte";
    import SettingsPage from "./pages/SettingsPage.svelte";
    import BillingAndUsage from "./content/BillingAndUsage.svelte";
    import RecruiterManagement from "../views/RecruiterManagement.svelte";
    import ApplicantsDatabase from "./content/ApplicantsDatabase.svelte";
    import InterviewDatabasePage from "./pages/InterviewDatabasePage/InterviewDatabasePage.svelte";

    function changeComponent(component: any) {
        pageStore.set(component);
        closeDrawer();
    }

    function closeDrawer() {
        const drawerToggle = document.getElementById("my-drawer") as HTMLInputElement;
        if (drawerToggle) {
            drawerToggle.checked = false;
        }
    }

    let ecommerceOpen = false;
    let positionsOpen = false; // Controls if the Positions submenu is open or not
    let interviewsOpen = false;
</script>

<div class="drawer">
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />

    <div class="drawer-side">
        <label for="my-drawer" class="drawer-overlay"></label>
        <div class="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
            <div class="text-center mb-4">
                <h2 class="text-2xl font-bold">Astrovel x {$userInfo.companyName}</h2>
            </div>
            <ul id="sideMenu">
                <li class="mb-2 hover:bg-base-300 rounded-lg">
                    <a href="#" class="block p-2" on:click={() => { changeComponent(Landing) }}>
                        <i class="fa-solid fa-house mr-2"></i> {$t('Home')}
                    </a>
                </li>

                <li id="menu-items" class="menu-item mb-2 rounded-lg">
                    <a class="justify-between" on:click={() => ecommerceOpen = !ecommerceOpen}>
                        <span>
                            <i class="fas fa-users mr-2"></i> {$t('Applicants')}
                        </span>
                        <i class={`fa ${ecommerceOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </a>
                    <ul class={`p-2 ${ecommerceOpen ? '' : 'hidden'}`}>
                        <li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item" on:click={() => { changeComponent(ApplicantsDatabase) }}>
                                {$t('SeeAll')}
                            </a>
                        </li>
                        <!-- <li class="mb-2 rounded-lg">
                             <a href="#" class="menu-item" on:click={() => alert($t('NotYet'))}>
                                 {$userInfo.companyName}   {$t('DB')}
                             </a>
                         </li>-->
                         <!--<li class="mb-2 rounded-lg">
                             <a href="#" class="menu-item" on:click={() => alert($t('NotYet'))}>
                                 {$t('AstrovelDB')}
                             </a>
                         </li>-->
                    </ul>
                </li>

                <!-- Positions submenu -->
                <li id="menu-items" class="menu-item mb-2 rounded-lg">
                    <a class="justify-between" on:click={() => positionsOpen = !positionsOpen}>
                        <span>
                            <i class="fas fa-folder mr-2"></i> {$t('Positions')}
                        </span>
                        <i class={`fas ${positionsOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </a>
                    <ul class={`p-2 ${positionsOpen ? '' : 'hidden'}`}>
                        <li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item" on:click={() => { changeComponent(OpenPositions) }}>
                                {$t('OpenPositions')}
                            </a>
                        </li>
                        <li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item" on:click={() => { changeComponent(OpenPositions) }}>
                                {$t('NoLongerReceiving')}
                            </a>
                        </li>
                        <li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item" on:click={() => { changeComponent(OpenPositions) }}>
                                {$t('ClosedPositions')}
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- Interviews submenu -->
                <li id="menu-items" class="menu-item mb-2 rounded-lg">
                    <a class="justify-between" on:click={() => interviewsOpen = !interviewsOpen}>
                        <span>
                            <i class="fas fa-briefcase mr-2"></i> {$t('Interviews')}
                        </span>
                        <i class={`fas ${interviewsOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </a>
                    <ul class={`p-2 ${interviewsOpen ? '' : 'hidden'}`}>
                        <li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item"  on:click={() => { changeComponent(InterviewDatabasePage) }}>
                                {$t('InterviewsDB')}
                            </a>
                        </li>
                        <!--<li class="mb-2 rounded-lg">
                            <a href="#" class="menu-item" on:click={() => alert($t('NotYet'))}>
                                {$t('InterviewsConfig')}
                            </a>
                        </li>-->
                    </ul>
                </li>

                {#if $userInfo.isAdmin}
                    <li class="mb-2 hover:bg-base-300 rounded-lg">
                        <a href="#" class="block p-2" on:click={() => { changeComponent(RecruiterManagement) }}>
                            <i class="fas fa-chart-line mr-2"></i> {$t('Users')}
                        </a>
                    </li>
                    <!-- <li class="mb-2 hover:bg-base-300 rounded-lg">
                         <a href="#" class="block p-2" on:click={() => { changeComponent(AddRecruiterPage) }}>
                             <i class="fas fa-chart-line mr-2"></i> {$t('AddNewUser')}
                         </a>
                     </li>-->
                     <!--<li class="mb-2 hover:bg-base-300 rounded-lg">
                         <a href="#" class="block p-2">
                             <i class="fas fa-chart-line mr-2"></i> {$t('Reports')}
                         </a>
                     </li>-->
                    {#if $userInfo.isAdmin}
                        <li class="mb-2 hover:bg-base-300 rounded-lg">
                            <a href="#" class="block p-2" on:click={() => pageStore.set(BillingAndUsage)}>
                                <i class="fas fa-credit-card mr-2"></i> {$t('BillingUsage')}
                            </a>
                        </li>
                    {/if}
                {/if}

                <li class="mb-2 hover:bg-base-300 rounded-lg">
                    <a href="#" class="block p-2" on:click={() => pageStore.set(SettingsPage)}>
                        <i class="fas fa-cogs mr-2"></i> {$t('Settings')}
                    </a>
                </li>
                <li class="mb-2 hover:bg-base-300 rounded-lg">
                    <a href="#" class="block p-2" onclick="logout_modal.showModal()">
                        <i class="fas fa-sign-out-alt mr-2"></i> {$t('Logout')}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<style>


    #menu-items{
        margin-left: -0.6rem;
    }

</style>