    <!-- Register Redirect Button -->
    <button
            class="flex-auto mb-2 mt-4 w-full py-2 bg-gray-600  rounded-2xl text-white font-semibold"
            type="button"
            on:click={() => {
      window.location.replace(import.meta.env.VITE_REGISTER_URI);
    }}
    >
        Register
    </button>

