<script lang="ts">
    export let status: string

    const badgeColour = () => {if(status==='OPEN'){
        return "accent"
    }else if (status==='CLOSED'){
       return  "secondary"
    }else {
        return "primary"
    }}
</script>
<div class="badge badge-{badgeColour()}">{status}</div>