import type {EmploymentType} from "./EmploymentType";
import type {Currency} from "./Currency";
import type {ScreeningQuestion} from "./ScreeningQuestion";
import type {WorkplaceType} from "../position/WorkplaceType";
import type {Location} from "./Location";
import type {PositionPageData} from "./PositionPageData";
import type {YearsOfExperienceDto} from "./YearsOfExperienceDto";

export interface JobPostGeneratorDto {
    jobTitle: string;
    jobDescription: string;
    requirements: string;
    preferredQualifications: string;
    perksAndBenefits: string | null;
    minSalary?: number | null;
    maxSalary?: number | null;
    currency?: Currency | string;
    employmentType: EmploymentType | string;
    jobPost: string | null;
    workplaceType: WorkplaceType | string;
    location: Location;
    skills: string[];
    screeningQuestions: ScreeningQuestion[];
    language: string,
    yearsOfExperience?: YearsOfExperienceDto
}

export function mapPositionPageDataToJobPostGeneratorDto(positionPageData: PositionPageData): JobPostGeneratorDto {
    return {
        jobTitle: positionPageData.title,
        jobDescription: positionPageData.description,
        requirements: positionPageData.requirements,
        preferredQualifications: positionPageData.preferredQualifications,
        perksAndBenefits: positionPageData.perksAndBenefits || null,
        minSalary: positionPageData.salaryDto ? positionPageData.salaryDto.min : null,
        maxSalary: positionPageData.salaryDto ? positionPageData.salaryDto.max : null,
        currency: positionPageData.salaryDto ? positionPageData.salaryDto.currency : null,
        employmentType: positionPageData.employmentType,
        jobPost: positionPageData.textPost || null,
        workplaceType: positionPageData.workplaceType,
        location: positionPageData.location,
        skills: positionPageData.skills,
        screeningQuestions: positionPageData.screeningQuestions,
    };
}
