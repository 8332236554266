<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte';
    import { transferOwnership } from "../../service/RecruiterService";
    import { logout } from "../../service/auth/AuthService";
    import { t } from 'svelte-i18n'; // Import the i18n translation function

    const dispatch = createEventDispatcher();

    export let id: string;
    export let name: string;

    const expectedPhrase = $t("transferOwnershipPhrase"); // Translated phrase

    let dialog: HTMLDialogElement;
    let loading = false;
    let errorMessage = '';

    onMount(() => {
        if (dialog) {
            dialog.showModal();
        }
    });

    async function handleConfirm() {
        const input = document.getElementById('confirmationInput') as HTMLInputElement;
        if (input.value === expectedPhrase) {
            loading = true;
            errorMessage = '';
            try {
                await transferOwnership(id);
                dialog.close();
                logout();
            } catch (error) {
                errorMessage =$t('errorOccurred'); // Translated error message
            } finally {
                loading = false;
            }
        } else {
            errorMessage = $t('typeExactPhrase'); // Translated exact phrase message
        }
    }

    function handleCancel() {
        dialog.close();
        dispatch('cancel', {});
    }
</script>

<dialog bind:this={dialog} class="modal modal-open">
    <form method="dialog" class="modal-box">
        <h2 class="font-bold text-xl">{$t('transferOwnership')}</h2> <!-- Translated title -->
        <p class="py-4">
            {$t('transferOwnershipWarning', { name })} <!-- Translated warning message -->
        </p>
        <p class="py-2">
            {$t('confirmationPrompt')} <strong>{$t('transferOwnershipPhrase')}</strong> <!-- Translated confirmation prompt -->
        </p>
        <input
                type="text"
                id="confirmationInput"
                placeholder={$t('typePhrase')}
        class="input input-bordered w-full my-4"
        aria-label="Confirmation Phrase"
        />
        {#if errorMessage}
            <p class="text-error">{errorMessage}</p>
        {/if}
        <div class="modal-action">
            <button on:click={handleCancel} class="btn btn-secondary">{$t('cancel')}</button> <!-- Translated cancel button -->
            <button on:click={handleConfirm} class="btn btn-primary" disabled={loading}>
                {#if loading}
                    <span class="loading loading-spinner"></span>
                    {$t('processing')} <!-- Translated loading text -->
                {:else}
                    {$t('confirmTransfer')} <!-- Translated confirm button -->
                {/if}
            </button>
        </div>
    </form>
</dialog>
