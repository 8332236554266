<script lang="ts">
    import { t } from 'svelte-i18n';
    import { refreshOpenPositionsShort, updatePosition } from "../../service/JobPostService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import { pageStore, selectedPositionId } from "../../redux/stores";
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import type { Currency } from "../../model/jobs/Currency";
    import LoadingAnimation from "../LoadingAnimation.svelte";
    import PositionDetails from "../../views/PositionDetails.svelte";

    export let position: JobPostGeneratorDto;
    export let toogle: boolean;
    let input: HTMLInputElement;
    let isLoading = false;

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('update_position_modal').close();
        toogle = false;
    }

    const confirmUpdate = () => {
        isLoading = true;
        updatePosition(position, $selectedPositionId)
            .then(() => {
                pushSuccessToast($t('PositionUpdated'));
                pageStore.set(PositionDetails);
                refreshOpenPositionsShort();
                closeModal();
            })
            .catch((e) => {
                console.error(e);
                pushErrorToast($t('ErrorUpdatingPosition'));
            })
            .finally(() => {
                isLoading = false;
            });
    };

    function displaySalary(minSalary?: number | null, maxSalary?: number | null, currency?: string | Currency) {
        if (!minSalary && !maxSalary) {
            return $t('SalaryNotDisclosed');
        }
        return `${minSalary || $t('NotSpecified')} - ${maxSalary || $t('NotSpecified')} ${currency}`;
    }
</script>

<input type="hidden" class="btn" onclick="update_position_modal.showModal()" bind:this={input} />
<dialog id="update_position_modal" class="modal">
    {#if isLoading}
        <LoadingAnimation />
    {:else}
        <div class="modal-box">
            <h3 class="text-lg font-bold">{$t('ConfirmUpdatePosition')}</h3>
            <p class="py-4">{$t('ReviewDetails')}</p>

            <div class="mt-4">
                <h4 class="font-bold">{$t('OpenUntil')}:</h4>
                <p>{position.validUntil}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('JobTitle')}:</h4>
                <p>{position.jobTitle}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('JobDescription')}:</h4>
                <p>{position.jobDescription}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('JobRequirements')}:</h4>
                <p>{position.requirements}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('PreferredQualifications')}:</h4>
                <p>{position.preferredQualifications}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('PerksAndBenefits')}:</h4>
                <p>{position.perksAndBenefits}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('SalaryRange')}:</h4>
                <p>{displaySalary(position.minSalary, position.maxSalary, position.currency)}</p>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('EmploymentType')}:</h4>
                <h4>{position.employmentType}</h4>
                <h4 class="font-bold">{$t('WorkplaceType')}:</h4>
                <h4>{position.workplaceType}</h4>
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('Skills')}:</h4>
                {#each position.skills as skill}
                    <div class="badge badge-primary gap-2 cursor-pointer">
                        {skill}
                    </div>
                {/each}
            </div>
            <div class="mt-4">
                <h4 class="font-bold">{$t('ScreeningQuestions')}:</h4>
                <div class="flex items-center justify-between mb-2 bg-base-200 p-2 rounded">
                    {#each position.screeningQuestions as question}
                        <div>
                            <p class="text-sm font-semibold">{question.text}</p>
                            <p class="text-xs">{$t('IdealAnswer')}: {question.idealAnswer}</p>
                            <label class="flex items-center text-xs">
                                <input type="checkbox" checked={question.mustHave} class="checkbox checkbox-xs mr-1" disabled />
                                {$t('MustHaveQualification')}
                            </label>
                        </div>
                    {/each}
                </div>
            </div>

            <div class="modal-action">
                <button class="btn btn-primary" on:click={confirmUpdate}>{$t('YesUpdatePosition')}</button>
                <button class="btn btn-secondary" on:click={closeModal}>{$t('Cancel')}</button>
            </div>
        </div>
    {/if}
</dialog>
