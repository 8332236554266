<script lang="ts">
    import { PositionStatus } from "../../../model/jobs/Position";
    import { t } from "svelte-i18n";
    export let currentStatus: PositionStatus;
    export let setStatus: (status: PositionStatus) => void;
</script>

<div class="flex space-x-2 justify-center">
    <button
            class="btn btn-outline btn-primary rounded-full transition-all ease-in-out duration-300 shadow-md hover:scale-105"
            class:btn-primary={currentStatus === PositionStatus.OPEN}
            on:click={() => setStatus(PositionStatus.OPEN)}>
        {$t('OpenPositions')}
    </button>
    <button
            class="btn btn-outline btn-primary rounded-full transition-all ease-in-out duration-300 shadow-md hover:scale-105"
            class:btn-primary={currentStatus === PositionStatus.NO_LONGER_ACCEPTING}
            on:click={() => setStatus(PositionStatus.NO_LONGER_ACCEPTING)}>
        {$t('NoLongerAcceptingPositions')}
    </button>
    <button
            class="btn btn-outline btn-primary rounded-full transition-all ease-in-out duration-300 shadow-md hover:scale-105"
            class:btn-primary={currentStatus === PositionStatus.CLOSED}
            on:click={() => setStatus(PositionStatus.CLOSED)}>
        {$t('ClosedPositions')}
    </button>
</div>
