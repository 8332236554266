<script lang="ts">
    import { CompanySize } from "../../../../model/company/CompanySize";
    import { t } from 'svelte-i18n';

    export let companySize: CompanySize;
    export let isValid: boolean;

    // Get the key-value pairs of the CompanySize enum
    const companySizes = Object.entries(CompanySize);

    // Mark as valid if a company size is selected
    $: if (companySize) {
        isValid = true;
    }
</script>

<div class="flex flex-col gap-6 px-6 md:px-12 items-center mt-16 w-full">
    <!-- Company Size Selection -->
    <div class="flex gap-4 w-full dropdown">
        <select
                tabindex="0"
                class="select select-bordered text-l font-bold w-full"
                bind:value={companySize}
        >
            {#each companySizes as [key, value]}
                <option class="p-2 text-l font-bold" value={key}>
                    {value} <p class="text-xl font-bold mt-2">{$t('People')}</p><!-- Translate 'People' -->
                </option>
            {/each}
        </select>
    </div>
</div>

<style>
    .select:focus {
        border-color: white;
        border: solid;
        border-width: 2px;
    }
</style>