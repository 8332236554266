<script lang="ts">
    import { breadcrumbs, pageStore } from "../../redux/stores";
    import OpenPositions from "./PositionsPage.svelte";
    import BillingAndUsage from "./BillingAndUsage.svelte";
    import ApplicantsDatabase from "./ApplicantsDatabase.svelte";
    import { getLandingData } from "../../service/DashboardService";
    import SettingsPage from "../pages/SettingsPage.svelte";
    import InterviewDatabasePage from "../pages/InterviewDatabasePage/InterviewDatabasePage.svelte";
    import LoadingAnimation from "../LoadingAnimation.svelte";
    import CreatePositionForm from "../CreatePosition/CreatePositionForm.svelte";
    import { t } from 'svelte-i18n';

    breadcrumbs.set([{ name:$t('Home'), component: this }]);

    let quickLinks = [
        { name: $t('OpenPositions'), href: OpenPositions },
        { name: $t('ApplicantsDatabase'), href: ApplicantsDatabase },
        { name: $t('AddANewPosition'), href: CreatePositionForm },
        { name: $t('InterviewDatabasePage'), href: InterviewDatabasePage },
        { name: $t('BillingAndUsage'), href: BillingAndUsage },
        { name: $t('Settings'), href: SettingsPage },
    ];

    let interviewsFilter = 'today'; // Possible values: 'today', 'week', 'all'

    function filterInterviews(filter) {
        interviewsFilter = filter;
    }
</script>

{#await getLandingData()}
    <LoadingAnimation/>
{:then dashboardData}
    <div class="bg-base-100 text-base-content min-h-screen p-4">
        <div class="flex flex-col lg:flex-row lg:space-x-4">
            <!-- Dashboard Content -->
            <div class="flex-grow space-y-4">
                <!-- Header -->
                <div class="text-4xl mb-2">{$t('DashboardTitle')}</div>

                <!-- Open Positions & Candidates Overview -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="card bg-base-100 shadow-xl rounded-lg">
                        <div class="card-body">
                            <h2 class="card-title">{$t('OpenPositions')}</h2>
                            <p class="text-2xl">
                                {dashboardData.totalOpenPositions > 0
                                    ? `${dashboardData.totalOpenPositions} ${$t('OpenPositions')}`
                                    : 'No positions available.'}
                            </p>
                        </div>
                    </div>
                    <div class="card bg-base-100 shadow-xl rounded-lg">
                        <div class="card-body">
                            <h2 class="card-title">{$t('TotalApplicants')}</h2>
                            <p class="text-2xl">
                                {dashboardData.totalCandidates > 0
                                    ? `${dashboardData.totalCandidates} ${$t('Applicants')}`
                                    : $t('NoApplicantsYet')}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Tasks Summary -->
                <div class="card bg-base-100 shadow-xl rounded-lg">
                    <div class="card-body">
                        <h2 class="card-title">{$t('TasksSummary')}</h2>
                        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div class="flex justify-between items-center p-2">
                                <span>{$t('InterviewsScheduledForToday')}</span>
                                <span class="badge badge-secondary">{dashboardData.interviewsScheduledForToday}</span>
                            </div>
                            <div class="flex justify-between items-center p-2">
                                <span>{$t('NewApplications')}</span>
                                <span class="badge badge-secondary">{dashboardData.newApplications}</span>
                            </div>
                            <div class="flex justify-between items-center p-2">
                                <span>{$t('DiversityIndex')}</span>
                                <span class="badge badge-secondary">{dashboardData.diversityIndex}%</span>
                            </div>
                            <div class="flex justify-between items-center p-2">
                                <span>{$t('AverageInterviewDuration')}</span>
                                <span class="badge badge-secondary">{dashboardData.averageInterviewDuration} mins</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Upcoming Interviews Section -->
                <div class="card bg-base-100 shadow-xl rounded-lg">
                    <div class="card-body">
                        <h2 class="card-title">{$t('UpcomingInterviews')}</h2>
                        <div class="flex justify-between items-center mb-4">
                            <div class="btn-group">
                                <button class="btn btn-primary" on:click={() => filterInterviews('today')}>
                                    {$t('Today')}
                                </button>
                                <button class="btn btn-primary" on:click={() => filterInterviews('week')}>
                                    {$t('Week')}
                                </button>
                                <button class="btn btn-primary" on:click={() => filterInterviews('all')}>
                                    {$t('All')}
                                </button>
                            </div>
                        </div>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                <tr>
                                    <th>{$t('Applicant')}</th>
                                    <th>{$t('Position')}</th>
                                    <th>{$t('Time')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each dashboardData.upcomingInterviews as interview }
                                    {#if interviewsFilter === 'today' && interview.time.startsWith('Today') ||
                                    interviewsFilter === 'week' && !interview.time.startsWith('Today') ||
                                    interviewsFilter === 'all'}
                                        <tr>
                                            <td>{interview.candidate.second}</td>
                                            <td>{interview.position.second}</td>
                                            <td>{interview.time}</td>
                                        </tr>
                                    {/if}
                                {/each}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sidebar: Quick Stats and Links -->
            <div class="lg:w-1/4 space-y-4">
                <!-- Quick Stats -->
                <div class="card bg-base-100 shadow-xl rounded-lg">
                    <div class="card-body">
                        <h2 class="card-title">{$t('QuickStats')}</h2>
                        <div class="flex flex-col space-y-2">
                            <div class="stat">
                                <div class="stat-title">{$t('InterviewsToday')}</div>
                                <div class="stat-value">
                                    {dashboardData.interviewsScheduledForToday > 0 ? `${dashboardData.interviewsScheduledForToday}` : '0'}
                                </div>
                            </div>
                            <div class="stat">
                                <div class="stat-title">{$t('NewApplications')}</div>
                                <div class="stat-value">{dashboardData.newApplications}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Quick Links -->
                <div class="card bg-base-100 shadow-xl rounded-lg">
                    <div class="card-body">
                        <h2 class="card-title">{$t('QuickLinks')}</h2>
                        <div class="list-none space-y-2">
                            {#each quickLinks as link}
                                <div>
                                    <a href="#" on:click={() => pageStore.set(link.href)} class="text-primary hover:text-primary-focus font-semibold">
                                        {link.name}
                                    </a>
                                </div>
                            {/each}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{:catch error}
    <div class="alert alert-error">{error.message}</div>
{/await}

<style>
    /* Additional custom styling can go here */
</style>
