<script lang="ts">
    import { t } from 'svelte-i18n';
    import { locationToString } from "../../../model/jobs/Location";
    import type { PositionPageData } from "../../../model/jobs/PositionPageData";
    import {userInfo} from "../../../redux/stores";

    export let position: PositionPageData;
</script>

<div class="shadow-lg rounded-lg p-6 bg-base-100">
    <h2 class="text-2xl font-bold mb-2">{position.title}</h2>
    <p class="text-sm text-gray-500 mb-4">{$userInfo.companyName} · {locationToString(position.location)}</p>
    <div class="mt-4">
        <h3 class="text-xl font-semibold">{ $t('JobDescription') }</h3>
        <p><strong>{ $t('PositionTitle') }:</strong> {position.title}</p>
        <p><strong>{ $t('Location') }:</strong> {locationToString(position.location)}</p>
        <p><strong>{ $t('EmploymentType') }:</strong> <div class="badge badge-accent">{position.employmentType.toString().replace("_", " ")}</div>
        {#if position.jobDescription !== undefined}
            {position.jobDescription}
        {/if}
    </div>
</div>
