<script lang="ts">
    import { pageStore } from "../../../redux/stores.js";
    import CreatePositionForm from "../../CreatePosition/CreatePositionForm.svelte";
    import { t } from "svelte-i18n";

    function handlePostNewJob() {
        pageStore.set(CreatePositionForm);
    }
</script>

<div class="shadow-lg rounded-lg p-6 bg-base-100">
    <h3 class="text-lg font-semibold">{ $t('HiringForMoreRoles') }</h3>
    <button class="btn btn-primary mt-2" on:click={handlePostNewJob}>
        { $t('PostNewJob') }
    </button>
</div>
