<script lang="ts">
    import PersonalSettings from "./SettingsPage/PersonalSettings.svelte";
    import CompanySettings from "./SettingsPage/CompanySettings.svelte";
    import CreditCardSettings from "./ProfilePage/CreditCardSettings.svelte";
    import { userInfo } from "../../redux/stores";
    import { t } from 'svelte-i18n';

    let activeTab = 'personal';

    function changeTab(tab) {
        activeTab = tab;
    }
</script>

<div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold text-center mb-8">{$t('Settings')}</h1>

    <div class="tabs tabs-boxed">
        <a class="tab" class:tab-active={activeTab === 'personal'} on:click={() => changeTab('personal')} href="#personal">{$t('Personal')}</a>
        <a class="tab" class:tab-active={activeTab === 'company'} on:click={() => changeTab('company')} href="#company">{$t('Company')}</a>
        <!--    <a class="tab" class:tab-active={activeTab === 'job-posting'} on:click={() => changeTab('job-posting')} href="#job-posting">Job Posting</a>
         <a class="tab" class:tab-active={activeTab === 'interview-scheduling'} on:click={() => changeTab('interview-scheduling')} href="#interview-scheduling">Interview Scheduling</a>
         -->
        <a class="tab" class:tab-active={activeTab === 'billing'} on:click={() => changeTab('billing')} href="#billing">{$t('BillingPayments')}</a>
        <!--
        <a class="tab" class:tab-active={activeTab === 'privacy'} on:click={() => changeTab('privacy')} href="#privacy">Privacy & Compliance</a>
        <a class="tab" class:tab-active={activeTab === 'advanced'} on:click={() => changeTab('advanced')} href="#advanced">Advanced</a>
        -->
    </div>

    <div class="tab-content p-4" class:hidden={activeTab !== 'personal'}>
        <PersonalSettings name={$userInfo.name} surname={$userInfo.surname} email={$userInfo.email} birthday={$userInfo.birthDate}/>
    </div>

    <div class="tab-content p-4" class:hidden={activeTab !== 'company'}>
        <CompanySettings />
    </div>

    <div id="job-posting" class="tab-content p-4 hidden">
        <!-- Job Posting Settings Form -->
    </div>

    <div id="interview-scheduling" class="tab-content p-4 hidden">
        <!-- Interview Scheduling Form -->
    </div>

    <div class="tab-content p-4" class:hidden={activeTab !== 'billing'}>
        <CreditCardSettings />
    </div>

    <div id="privacy" class="tab-content p-4 hidden">
        <!-- Privacy & Compliance Form -->
    </div>

    <div id="advanced" class="tab-content p-4 hidden">
        <!-- Advanced Settings Form -->
    </div>

    <!--  <div class="form-control mt-6">
        <button class="btn btn-primary">{$t('SaveChanges')}</button>
    </div> -->
</div>
