<script lang="ts">

    import type {ApplicantPageData} from "../../model/jobs/PositionPageData";
    import {pushErrorToast, pushSuccessToast} from "../../service/ToastService";
    import {rejectApplicant} from "../../service/JobPostService";
    import type {RejectApplicantDto} from "../../service/dto/position/RejectApplicantDto";
    import {CurrentRating} from "../../model/applicant/CurrentRating";
    import { t } from "svelte-i18n";

    export let applicant: ApplicantPageData;
    export let toogle: boolean;
    export let positionId: string;
    let sendEmail = false;
    let input: HTMLInputElement;
    const rejectDto: RejectApplicantDto = { applicantId: applicant.id, positionId: positionId, reasons: "", sendEmail: sendEmail };

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('reject_applicant_modal').close();
        toogle = false;
    }

    async function reject() {
        try {
            await rejectApplicant(rejectDto);
            applicant.rejected = true;
            applicant.currentRating = CurrentRating.REJECTED;
            pushSuccessToast($t('rejectApplicant') + ` ${applicant.fullName} ${$t('hasBeenRejected')}.`);
        } catch {
            pushErrorToast($t('errorRejecting'));
        } finally {
            closeModal();
        }
    }
</script>

<input type="hidden" class="btn" onclick="reject_applicant_modal.showModal()" bind:this={input}/>

<dialog id="reject_applicant_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{$t('rejectApplicant')} {applicant.fullName}?</h3>
        <p class="py-4">{$t('rejectConfirmation').replace("{applicantName}",applicant.fullName)}</p>
        <label class="label cursor-pointer">
            <span class="label-text">{$t('sendRejectionEmail')}</span>
            <input type="checkbox" class="checkbox" bind:checked={sendEmail}>
        </label>
        <div class="py-4">
            <label class="label">
                <span class="label-text">{$t('reasonForRejection')}</span>
            </label>
            <textarea class="textarea textarea-bordered w-full" bind:value={rejectDto.reasons} placeholder="{$t('reasonForRejection')}"></textarea>
            <p class="text-sm text-gray-500 mt-2">
                {$t('internalReasonNote')}
            </p>
        </div>
        <div class="modal-action">
            <button class="btn btn-primary" on:click={reject}>{$t('yesReject')}</button>
            <button class="btn btn-secondary" on:click={closeModal}>{$t('cancel')}</button>
        </div>
    </div>
</dialog>