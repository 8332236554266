import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = get(t);

export function validateEmails(email: string, confirmEmail: string): ValidationResult {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
        return {
            isValid: false,
            message: translate('invalidEmail'), // Translation key for "Email is not valid."
        };
    }

    if (email !== confirmEmail) {
        return {
            isValid: false,
            message: translate('emailMismatch'), // Translation key for "Email and confirmation email do not match."
        };
    }

    return {
        isValid: true,
        message: translate('emailValid'), // Translation key for "Email is valid."
    };
}
