<script lang="ts">
    import { openGoogleConsent } from "../../service/auth/AuthService";
</script>

<button
        class="block w-full bg-red-600 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
        type="button"
        on:click={() => openGoogleConsent()}
>
    <i class="fa-brands fa-google"></i> Continue with Google
</button>
