<script lang="ts">
    import {t} from 'svelte-i18n';
    import type {JobPostGeneratorDto} from "../../model/jobs/JobPostGeneratorDto";
    import {isSalaryValid} from "../../validators/position/SalaryValidator";
    import {getEmploymentTypeName} from "../../model/jobs/EmploymentType";
    import {getWorkplaceTypeName} from "../../model/position/WorkplaceType";
    import {onMount} from "svelte";
    import TabKeyPrompt from "../Util/Kbd/TabKeyPrompt.svelte";
    import SpaceKeyPrompt from "../Util/Kbd/SpaceKeyPrompt.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";
    import {Currency} from "../../model/jobs/Currency";

    export let minSalary: number = 0;
    export let maxSalary: number = 0;
    export let currency: Currency = Currency.USD;

    export let positionData: JobPostGeneratorDto;

    export let handleNext: () => void;
    export let handleSkip: () => void;

    let validationMessage = '';
    let minSalInput: HTMLInputElement;

    function handleEnterKey(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const validationResult = isSalaryValid(minSalary, maxSalary);
            if (minSalary === 0 && maxSalary === 0) {
                handleSkip();
            } else if (validationResult.isValid) {
                handleNext();
            } else {
                validationMessage = validationResult.message;
            }
        }
        if (event.key === ' '){
            event.preventDefault();
            if (minSalary === 0 && maxSalary === 0) {
                handleSkip();
        }
        }
    }

    function validateSalaries() {
        const validationResult = isSalaryValid(minSalary, maxSalary);
        validationMessage = validationResult.message;
        return validationResult.isValid;
    }

    $: validationMessage = (minSalary !== 0 || maxSalary !== 0) ? '' : validationMessage;

    onMount(() => {
        minSalInput.focus();
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('SalaryRangeTitle')}</h2>
        <div class="mb-6">
            <p><strong>{$t('JobTitle')}:</strong> {positionData.jobTitle}</p>
            <p><strong>{$t('WorkplaceType')}:</strong> {getWorkplaceTypeName(positionData.workplaceType)}</p>
            <p><strong>{$t('Location')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
            <p><strong>{$t('JobType')}:</strong> {getEmploymentTypeName(positionData.employmentType)}</p>
        </div>
        <p class="text-center mb-6">{$t('SalaryRangePrompt')}</p>
        <TabKeyPrompt/>
        <div class="form-control mb-4 dropdown">
            <label class="label">Select a money</label>
           <select class="select select-bordered" bind:value={currency}>
               <option value={Currency.USD}>USD</option>
               <option value={Currency.EUR}>EUR</option>
               <option value={Currency.ARS}>ARS</option>
           </select>
        </div>

        <div class="form-control mb-4">
            <label class="label">
                <span class="label-text">{$t('MinimumSalary')}</span>
            </label>
            <input
                    type="number"
                    bind:value={minSalary}
                    placeholder={$t('EnterMinimumSalary')}
                    class="input input-bordered w-full"
                    min="0"
                    bind:this={minSalInput}
                    on:input={validateSalaries}
                    on:keydown={handleEnterKey}
            />
        </div>

        <div class="form-control mb-4">
            <label class="label">
                <span class="label-text">{$t('MaximumSalary')}</span>
            </label>
            <input
                    type="number"
                    bind:value={maxSalary}
                    placeholder={$t('EnterMaximumSalary')}
                    class="input input-bordered w-full"
                    min="0"
                    on:input={validateSalaries}
                    on:keydown={handleEnterKey}
            />
        </div>

        {#if validationMessage}
            <div class="text-error mb-4">{validationMessage}</div>
        {/if}

        {#if minSalary === 0 && maxSalary === 0}
            <SpaceKeyPrompt/>
        {/if}
        {#if !(!validateSalaries() || minSalary === 0 && maxSalary === 0)}
            <EnterToContinue/>
        {/if}

        <div class="flex justify-between mt-6">
            <button
                    disabled={minSalary !== 0 ||  maxSalary !== 0}
                    on:click={handleSkip}
                    class="btn btn-secondary"
            >
                {$t('Skip')}
            </button>
            <button
                    disabled={!validateSalaries() || (minSalary === 0 && maxSalary === 0)}
                    on:click={handleNext}
                    class="btn btn-primary"
            >
                {$t('Next')}
            </button>
        </div>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
    .text-error {
        color: red;
    }
</style>
