<script lang="ts">
    import { t } from 'svelte-i18n';
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import { createPosition, refreshOpenPositionsShort } from "../../service/JobPostService";
    import { getWorkplaceTypeName } from "../../model/position/WorkplaceType";
    import { getEmploymentTypeName } from "../../model/jobs/EmploymentType";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import LoadingAnimation from "../LoadingAnimation.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";
    import LoadingGif from "../Util/Animations/LoadingGif.svelte";

    export let positionData: JobPostGeneratorDto;
    export let handleNext: () => void;
    export let positionId: string;
    let isLoading = false;

    async function handleConfirm() {
        try {
            isLoading = true;
            const idWrapper = await createPosition(positionData);
            positionId = idWrapper.id;
            pushSuccessToast($t('PositionCreated'));
            handleNext();
            await refreshOpenPositionsShort();
        } catch (e) {
            pushErrorToast($t('ErrorCreatingPosition'));
        } finally {
            isLoading = false;
        }
    }
</script>

{#if isLoading}
    <LoadingGif/>
{:else}
    <div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
        <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
            <h2 class="text-2xl font-bold text-center mb-4">{$t('ConfirmJobPosition')}</h2>
            <div class="mb-6">
                <p><strong>{$t('JobTitle')}:</strong> {positionData.jobTitle}</p>
                <p><strong>{$t('JobDescription')}:</strong> {positionData.jobDescription}</p>
                <p><strong>{$t('Requirements')}:</strong> {positionData.requirements}</p>
                <p><strong>{$t('PreferredQualifications')}:</strong> {positionData.preferredQualifications}</p>
                <p><strong>{$t('PerksBenefits')}:</strong> {positionData.perksAndBenefits}</p>
                <p><strong>{$t('SalaryRange')}:</strong> {positionData.currency} {positionData.minSalary}
                    - {positionData.maxSalary}</p>
                <p><strong>{$t('JobType')}:</strong> {getEmploymentTypeName(positionData.employmentType)}</p>
                <p><strong>{$t('WorkplaceType')}:</strong> {getWorkplaceTypeName(positionData.workplaceType)}</p>
                <p><strong>{$t('Location')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
                <p><strong>{$t('Skills')}:</strong> {positionData.skills.join(', ')}</p>
                {#if positionData.yearsOfExperience != null}
                <p><strong>{$t('YearsOfExperience')}:</strong> {positionData.yearsOfExperience.min} - {positionData.yearsOfExperience.max || "N/A"}</p>
                {:else}
                <p><strong>{$t('YearsOfExperience')}:</strong> N/A </p>
                {/if}
            </div>
            <div class="mb-6">
                <h3 class="text-xl font-bold mb-2">{$t('ScreeningQuestions')}</h3>
                {#each positionData.screeningQuestions as question}
                    <div class="card card-bordered mb-2">
                        <div class="card-body">
                            <p><strong>{$t('Question')}:</strong> {question.text}</p>
                            <p><strong>{$t('IdealAnswer')}:</strong> {question.idealAnswer}</p>
                            <p><strong>{$t('MustHave')}:</strong> {question.mustHave ? $t('Yes') : $t('No')}</p>
                        </div>
                    </div>
                {/each}
            </div>
            <EnterToContinue action="to create the Position"/>
            <button on:click={handleConfirm} class="btn btn-primary w-full">{$t('ConfirmPosition')}</button>
            <input type="hidden" on:click={handleNext}>
        </div>
    </div>
{/if}

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
