<script lang="ts">
    import JobTitleForm from './JobTitleForm.svelte';
    import WorkplaceTypeForm from './WorkplaceTypeForm.svelte';
    import CountryForm from './LocationPositionForm.svelte';
    import PositionTypeForm from "./PositionTypeForm.svelte";
    import RoleForm from "./RoleForm.svelte";
    import SkillsForm from "./SkillsForm.svelte";
    import QualificationsForm from "./QualificationsForm.svelte";
    import LinkedinForm from "./LinkedinForm.svelte";
    import ConfirmPositionDataForm from "./ConfirmPositionDataForm.svelte";
    import SalaryRangeForm from "./SalaryRangeForm.svelte";
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import { EmploymentType } from "../../model/jobs/EmploymentType";
    import { Currency } from "../../model/jobs/Currency";
    import {WorkplaceType} from "../../model/position/WorkplaceType";
    import {breadcrumbs} from "../../redux/stores";
    import Landing from "../content/Landing.svelte";
    import OpenPositions from "../content/PositionsPage.svelte";
    import { t } from 'svelte-i18n';
    import InterviewLanguageForm from "./InterviewLanguageForm.svelte";
    import YearsOfExperienceForm from "./YearsOfExperienceForm.svelte";

    breadcrumbs.set([{ name: $t('Home'), component: Landing }, { name: $t('OpenPositions'), component: OpenPositions }, { name:$t('AddANewPosition'), component: undefined }]);

    let currentStep = 0;
    const totalSteps = 12;
    let positionId: string

    const positionData: JobPostGeneratorDto = {
        screeningQuestions: [],
        location: { country: "", city: "" },
        employmentType: EmploymentType.FULL_TIME,
        maxSalary: 0,
        minSalary: 0,
        jobPost: "This is the post you publish on LinkedIn",
        jobDescription: "",
        jobTitle: "",
        perksAndBenefits: "",
        preferredQualifications: "",
        requirements: "",
        currency: Currency.USD,
        skills: [],
        workplaceType: WorkplaceType.REMOTE,
        language: "",
        yearsOfExperience: undefined
    };

    function nextStep() {
        if (currentStep < totalSteps - 1) {
            currentStep += 1;
        }
    }

    function previousStep() {
        if (currentStep > 0) {
            currentStep -= 1;
        }
    }

    function handleFinish() {

    }
</script>

<div class="min-h-screen flex flex-col items-center justify-center bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <div class="mb-6 flex items-center">
            {#if currentStep > 0}
                <svg on:click={previousStep} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-secondary cursor-pointer mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            {/if}
            <progress class="progress progress-primary w-full" value={currentStep + 1} max={totalSteps}></progress>
        </div>
        {#if currentStep === 0}
            <JobTitleForm bind:positionTitle={positionData.jobTitle} handleNext={nextStep} />
        {:else if currentStep === 1}
            <WorkplaceTypeForm bind:workplaceType={positionData.workplaceType} handleNext={nextStep} handlePrevious={previousStep} />
        {:else if currentStep === 2}
            <CountryForm bind:location={positionData.location} handleNext={nextStep} />
        {:else if currentStep === 3}
            <PositionTypeForm bind:employmentType={positionData.employmentType} handleNext={nextStep}  />
        {:else if currentStep === 4}
            <RoleForm positionData={positionData} bind:preferredQualifications={positionData.preferredQualifications} bind:roleDescription={positionData.jobDescription} bind:requirements={positionData.requirements} bind:perksAndBenefits={positionData.perksAndBenefits} handleNext={nextStep} />
        {:else if currentStep === 5}
            <SkillsForm positionData={positionData} bind:skills={positionData.skills} handleNext={nextStep}  />
        {:else if currentStep === 6}
            <QualificationsForm positionData={positionData} bind:screeningQuestions={positionData.screeningQuestions} handleNext={nextStep}  />
        {:else if currentStep === 7}
            <InterviewLanguageForm positionData={positionData} bind:language={positionData.language} handleNext={nextStep}  />
        {:else if currentStep === 8}
            <SalaryRangeForm positionData={positionData} bind:minSalary={positionData.minSalary} bind:maxSalary={positionData.maxSalary} bind:currency={positionData.currency} handleNext={nextStep} handleSkip={nextStep}  />
        {:else if currentStep === 9}
            <YearsOfExperienceForm handleNext={nextStep} bind:yearsOfExperience={positionData.yearsOfExperience}/>
        {:else if currentStep === 10}
            <ConfirmPositionDataForm positionData={positionData} handleNext={nextStep} bind:positionId={positionId}/>
        {:else if currentStep === 11}
            <LinkedinForm positionData={positionData} handleFinish={handleFinish} bind:positionId={positionId}/>
        {/if}
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
