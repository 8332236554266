
import {makeGetRequest, makePatchRequest, makePostRequest} from "./Requests";
import type {Recruiter} from "../model/recruiter/Recruiter";
import type {CreateRecruiterDto} from "./dto/CreateRecruiterDto";
import type {UpdateRecruiterDto} from "./dto/UpdateRecruiterDto";
import type {CreateRecruiterExistingCompanyDto} from "./dto/recruiter/CreateRecruiterExistingCompanyDto";


export const getRecruiters = async (): Promise<Recruiter[]> => {
    return await makeGetRequest(import.meta.env.VITE_GET_RECRUITERS, undefined, false)
};

export const getRecruiterById = async (id: string): Promise<Recruiter> => {
    return await makeGetRequest(import.meta.env.VITE_GET_RECRUITER_BY_BY+id)
};

export const createRecruiter = async (createRecruiterDto: CreateRecruiterDto): Promise<Recruiter> => {
    return await makePostRequest(import.meta.env.VITE_POST_CREATE_RECRUITER,createRecruiterDto)
};

export const addNewRecruiter = async (newRecruiter: CreateRecruiterExistingCompanyDto): Promise<void> => {
    return await makePostRequest(import.meta.env.VITE_POST_ADD_NEW_RECRUITER,newRecruiter)
};

export const lockRecruiter = async (id: string): Promise<void> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_RECRUITER_LOCK+id)
};

export const unlockRecruiter = async (id: string): Promise<void> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_RECRUITER_UNLOCK+id)
};

export const makeRecruiterAdmin = async (id: string): Promise<void> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_RECRUITER_ADMIN+id)
};

export const transferOwnership = async (id: string): Promise<void> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_RECRUITER_OWNERSHIP+id)
};

export const removeRecruiterAdmin = async (id: string): Promise<void> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_RECRUITER_UN_ADMIN+id)
};

export const updateRecruiter = async (updateRecruiterDto: UpdateRecruiterDto): Promise<Recruiter> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_UPDATE_RECRUITER,updateRecruiterDto)
};