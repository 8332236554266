<script lang="ts">
    import { t } from 'svelte-i18n';
    import { refreshOpenPositionsShort, reopenPosition } from "../../service/JobPostService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import type { PositionPageData } from "../../model/jobs/PositionPageData";
    import { PositionStatus } from "../../model/jobs/Position";

    export let position: PositionPageData;
    export let toogle: boolean;
    let input: HTMLInputElement;

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('reopen_position_modal').close();
        toogle = false;
    }

    function reOpen() {
        reopenPosition(position.id)
            .then(() => {
                position.status = PositionStatus.OPEN;
                refreshOpenPositionsShort();
                pushSuccessToast($t('PositionReopened').replace("{title}", position.title));
            })
            .catch(() => pushErrorToast($t('ErrorReopeningPosition')))
            .finally(() => closeModal());
    }
</script>

<input type="hidden" class="btn" onclick="reopen_position_modal.showModal()" bind:this={input} />

<dialog id="reopen_position_modal" class="modal">
    <div class="modal-box">
        <h3 class="text-lg font-bold">{$t('ReopenPositionTitle').replace("{title}", position.title)}</h3>
        <p class="py-4">{$t('ReopenPositionConfirmation').replace("{title}", position.title)}</p>
        <div class="modal-action">
            <form method="dialog">
                <button class="btn btn-primary" on:click={() => reOpen()}>{$t('YesReopenPosition')}</button>
            </form>
            <button class="btn btn-secondary" on:click={() => closeModal()}>{$t('Cancel')}</button>
        </div>
    </div>
</dialog>
