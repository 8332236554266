<script lang="ts">
    import {fetchPositionsByCreator} from "../../../service/JobPostService";
    import { pageStore, selectedPositionId} from "../../../redux/stores";
    import { t } from 'svelte-i18n';
    import PositionDetails from "../../../views/PositionDetails.svelte";
    const getUniqueKey = (position, index) => position.id || position.title || index;
</script>

{#await fetchPositionsByCreator()}
    <p>...waiting</p>
{:then positions}
    <div class="overflow-x-auto">
        {#if positions && positions.length > 0}
            <table class="table w-full">
                <!-- head -->
                <thead>
                <tr>
                    <th>{$t('Position')}</th>
                    <th>{$t('CreatedAt')}</th>
                    <th>{$t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {#each positions as position, index (getUniqueKey(position, index))}
                    <tr>
                        <td>{position.title}</td>
                        <td>{position.createdAt}</td>
                        <td>
                            <button class="btn btn-xs btn-accent"
                                    on:click={() => { pageStore.set(PositionDetails); selectedPositionId.set(position.id); }}>
                                View Position</button>
                            <!-- Replace with any other actions needed for the position -->
                        </td>
                    </tr>
                {/each}
                </tbody>
            </table>
        {:else}
            <div class="alert alert-info">No positions available.</div>
        {/if}
    </div>
{:catch error}
    <div class="alert alert-error">{error.message}</div>
{/await}


