<script lang="ts">
    import {formatDateToReadable} from "../../../utils/DateUtils";
    import StatusBadge from "../Common/StatusBadge.svelte";
    import {t} from "svelte-i18n";
    export let positions;
    export let viewPosition: (id: string) => void;
</script>

<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {#each positions as position}
        <div class="card bg-base-100 shadow-xl rounded-lg">
            <div class="card-body">
                <h2 class="text-xl">
                    {position.title}
                    <StatusBadge status={position.status} />
                </h2>
                <p>{position.description}</p>
                <p><b>{position.applicantCount} {$t('Applicants')}</b> | {formatDateToReadable(position.createdAt)}</p>
                <div class="card-actions justify-end">
                    <button class="btn btn-outline btn-accent" on:click={() => viewPosition(position.id)}>
                        {$t('ViewPosition')}
                    </button>
                </div>
            </div>
        </div>
    {/each}
</div>
