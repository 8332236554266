<script lang="ts">
    import RejectApplicantModal from "../Modals/RejectApplicantModal.svelte";
    import type {ApplicantPageData} from "../../model/jobs/PositionPageData";
    import { t } from 'svelte-i18n';

    export let applicant: ApplicantPageData
    export let positionId: string
    export let rejected: boolean
    let showModal = false

    function showRejectModal(){
        showModal = true
    }
</script>
<button disabled={rejected} class="btn btn-error" on:click={()=>showRejectModal()}>{$t('Reject')}</button>
<RejectApplicantModal bind:applicant={applicant} bind:toogle={showModal} positionId={positionId}/>