<script lang="ts">
    import { t } from 'svelte-i18n';
    import { validateJobPositionName } from "../../validators/position/PositionNameValidator";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";

    export let positionTitle: string = '';
    export let handleNext: () => void;

    let validationResult = validateJobPositionName(positionTitle);

    $: validationResult = validateJobPositionName(positionTitle);

    function handleKeyPress(event: KeyboardEvent) {
        if (event.key === 'Enter' && validationResult.isValid) {
            handleNext();
        }
    }
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-96 bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('LetsGetStarted')}</h2>
        <p class="text-center mb-6">{$t('JobTitlePrompt')}</p>
        <input
                type="text"
                bind:value={positionTitle}
                placeholder={$t('EnterJobTitle')}
                class="input input-bordered w-full mb-4"
                on:keypress={handleKeyPress}
        />
        {#if !validationResult.isValid}
            <p class="text-red-500 text-center mb-4">{validationResult.message}</p>
        {/if}
        {#if validationResult.isValid}
        <EnterToContinue/>
        {/if}
        <button
                disabled={!validationResult.isValid}
                on:click={handleNext}
                class="btn btn-primary w-full mt-4"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
