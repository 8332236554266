<script lang="ts">
    import { userInfo } from "../../../redux/stores";
    import { updateCompany } from "../../../service/CompanyService";
    import type { RegisterCompanyDto } from "../../../service/auth/dto/RegisterCompanyDto";
    import { CompanyType, getCompanyTypeByOrdinal } from "../../../model/company/CompanyType";
    import { CompanySize, getCompanySizeByOrdinal } from "../../../model/company/CompanySize";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";
    import { t } from 'svelte-i18n';

    let company: RegisterCompanyDto = {
        name: $userInfo?.companyName!!,
        website: $userInfo?.web!!,
        size: $userInfo?.companySize!!,
        type: $userInfo?.companyType!!
    };

    async function onSubmit() {
        await updateCompany(company).then((r) => {
            $userInfo.companyName = r.name;
            $userInfo.web = r.website;
            $userInfo.companySize = getCompanySizeByOrdinal(parseInt(r.size));
            $userInfo.companyType = getCompanyTypeByOrdinal(parseInt(r.type));

            pushSuccessToast($t('CompanyDetailsUpdated'));
        }).catch(() => pushErrorToast($t('ErrorUpdatingCompanyDetails')));
    }
</script>

<div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold text-center mb-8">{$t('CompanyProfileSettings')}</h1>

    <form class="bg-base-200 p-6 rounded-lg shadow-lg" on:submit|preventDefault={onSubmit}>
        <!-- Company Name -->
        <div class="form-control w-full mb-4">
            <label class="label" for="name">
                <span class="label-text">{$t('CompanyName')}</span>
            </label>
            <input type="text" id="name" class="input input-bordered w-full" placeholder={$t('EnterCompanyName')}
                   bind:value={company.name} />
        </div>

        <!-- Company Website -->
        <div class="form-control w-full mb-4">
            <label class="label" for="website">
                <span class="label-text">{$t('Website')}</span>
            </label>
            <input type="text" id="website" class="input input-bordered w-full" placeholder={$t('EnterCompanyWebsite')}
                   bind:value={company.website} />
        </div>

        <!-- Company Type -->
        <div class="form-control w-full mb-4">
            <label class="label" for="type">
                <span class="label-text">{$t('CompanyType')}</span>
            </label>
            <select id="type" class="select select-bordered w-full" bind:value={company.type}>
                {#each Object.entries(CompanyType) as [value, text]}
                    <option value={value}>{$t(text)}</option>
                {/each}
            </select>
        </div>

        <!-- Company Size -->
        <div class="form-control w-full mb-4">
            <label class="label" for="size">
                <span class="label-text">{$t('CompanySize')}</span>
            </label>
            <select id="size" class="select select-bordered w-full" bind:value={company.size}>
                {#each Object.entries(CompanySize) as [value, text]}
                    <option value={value}>{$t(text)}</option>
                {/each}
            </select>
        </div>

        <!-- Submit Button -->
        <div class="form-control mt-6">
            <button class="btn btn-primary">{$t('SaveChanges')}</button>
        </div>
    </form>
</div>

<style>
    /* No custom styles needed here, DaisyUI classes are used */
</style>
