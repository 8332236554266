import type {Applicant} from "../applicant/Applicant";
import type {Page} from "../Page";

export interface Position {
    title: string
    company: string
    description: string
    shortDescription: string
    requirements: string,
    preferredQualifications: string,
    perksAndBenefits: string,
    textPost?: string,
    applicants: Page<Applicant>
    createdAt: Date,
    validUntil: Date,
    id: number
    status: PositionStatus
}

export enum PositionStatus {
    OPEN, NO_LONGER_ACCEPTING, CLOSED
}