<script lang="ts">
    import { userId, userInfo } from "../../../redux/stores";
    import type { UpdateRecruiterDto } from "../../../service/dto/UpdateRecruiterDto";
    import { updateRecruiter } from "../../../service/RecruiterService";
    import { t } from 'svelte-i18n';
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";

    export let name = '';
    export let surname = '';
    export let email = '';

    let pass: string;

    export let birthday;
    export let profilePic = ''; // This will hold the URL of the profile pic

    async function onSubmit() {
        let updateRecruiterDto: UpdateRecruiterDto = {
            birthday: birthday,
            email: email,
            id: $userId,
            name: name,
            surname: surname,
            pass: pass
        };

        await updateRecruiter(updateRecruiterDto)
            .then((r) => {
                $userInfo.name = r.name;
                $userInfo.surname = r.surname;
                $userInfo.birthDate = r.birthday;
                $userInfo.email = r.email;

                pushSuccessToast($t('PersonalDetailsUpdated'));
            })
            .catch(() => pushErrorToast($t('ErrorUpdatingPersonalDetails')));
    }

    // Method to handle file selection and updating the `profilePic`
    function handleFileSelect(event) {
        const file = event.target.files[0];
        if (file) {
            // Update `profilePic` to the file path, or use a FileReader to read the data if necessary
            profilePic = URL.createObjectURL(file);
        }
    }
</script>

<div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold text-center mb-8">{$t('PersonalSettings')}</h1>

    <form class="bg-base-200 p-6 rounded-lg shadow-lg flex gap-4" on:submit|preventDefault={onSubmit}>
        <div class="avatar placeholder">
            <div class="bg-base-100-focus rounded-full w-20 h-20">
                <!-- Conditional rendering based on $userInfo.profilePic -->
                {#if $userInfo.profilePic || profilePic}
                    <img src={$userInfo.profilePic || profilePic} alt="Profile Picture" class="rounded-full w-20 h-20">
                {:else}
                    <span class="text-3xl">{$userInfo.name.charAt(0)}</span>
                {/if}
            </div>
        </div>

        <div class="flex-grow">
            <!-- Form fields here -->
            <div class="form-control w-full mb-4">
                <label class="label" for="name">
                    <span class="label-text">{$t('Name')}</span>
                </label>
                <input type="text" id="name" class="input input-bordered w-full" placeholder="{$t('Name')}"
                       bind:value={name}/>
            </div>

            <div class="form-control w-full mb-4">
                <label class="label" for="surname">
                    <span class="label-text">{$t('Surname')}</span>
                </label>
                <input type="text" id="surname" class="input input-bordered w-full" placeholder="{$t('Surname')}"
                       bind:value={surname}/>
            </div>

            <div class="form-control w-full mb-4">
                <label class="label" for="email">
                    <span class="label-text">{$t('Email')}</span>
                </label>
                <input type="email" id="email" class="input input-bordered w-full" placeholder="{$t('Email')}"
                       bind:value={email}/>
            </div>

            <div class="form-control w-full mb-4">
                <label class="label" for="birthday">
                    <span class="label-text">{$t('Birthday')}</span>
                </label>
                <input type="date" id="birthday" class="input input-bordered w-full" bind:value={birthday}/>
            </div>

            <div class="form-control w-full mb-4">
                <label class="label" for="profilePic">
                    <span class="label-text">{$t('ChangeProfilePic')}</span>
                </label>
                <input type="file" id="profilePic" class="input input-bordered w-full" on:change={handleFileSelect}/>
            </div>

            <div class="form-control mt-6">
                <button class="btn btn-primary">{$t('SaveChanges')}</button>
            </div>
        </div>
    </form>
</div>