import type {JobPostGeneratorDto} from "../model/jobs/JobPostGeneratorDto";
import {makeGetRequest, makePatchRequest, makePostRequest, makePutRequest} from "./Requests";
import type {OpenPositionShortDto} from "../model/jobs/OpenPositionShortDto";
import type {PositionPageData} from "../model/jobs/PositionPageData";
import {writable} from "svelte/store";
import {getWorkplaceTypeNameUnderscore, WorkplaceType} from "../model/position/WorkplaceType";
import {type EmploymentType, getEmploymentTypeNameUnderScore} from "../model/jobs/EmploymentType";
import {Currency} from "../model/jobs/Currency";
import {formatToLocalDate} from "../utils/DateUtils";
import type {IdWrapper} from "./dto/IdWrapper";
import type {RejectApplicantDto} from "./dto/position/RejectApplicantDto";



export let needToFetchOpenPositions = true
export let needToFetchClosedPositions = true
export let needToFetchNoLongerAcceptingPositions = true

export const openPositions = writable< OpenPositionShortDto[]>([])
export const closedPositions = writable< OpenPositionShortDto[]>([])
export const noLongerAcceptingPositions = writable< OpenPositionShortDto[]>([])


let openPositionsCache: OpenPositionShortDto[] = []

export const fetchPositionsByCreator = async (): Promise<OpenPositionShortDto[]> => {
    return await makeGetRequest<OpenPositionShortDto[]>(import.meta.env.VITE_GET_POSITIONS_BY_CREATOR, undefined, true)
};

export const refreshOpenPositionsShort = async () => {
    needToFetchOpenPositions = true;
   return fetchOpenPositionsShort();
};

export const fetchOpenPositionsShort = async () => {
  if (needToFetchOpenPositions) {
      openPositionsCache = await makeGetRequest<OpenPositionShortDto[]>(import.meta.env.VITE_GET_OPEN_POSITIONS, undefined, true)
      openPositions.set(openPositionsCache)
      needToFetchOpenPositions = false
  }
};

export async function fetchNoLongerAcceptingPositionsShort() {
    if (needToFetchNoLongerAcceptingPositions) {
        openPositionsCache = await makeGetRequest<OpenPositionShortDto[]>(import.meta.env.VITE_GET_NO_LONGER_ACCEPTING_POSITIONS, undefined, true)
        noLongerAcceptingPositions.set(openPositionsCache)
        needToFetchNoLongerAcceptingPositions = false
    }
}

export async function fetchClosedPositionsShort() {
    if (needToFetchClosedPositions) {
        openPositionsCache = await makeGetRequest<OpenPositionShortDto[]>(import.meta.env.VITE_GET_CLOSED_POSITIONS, undefined, true)
        closedPositions.set(openPositionsCache)
        needToFetchClosedPositions = false
    }
}



export const getPositionById = async (id: string): Promise<PositionPageData> => {
    return await makeGetRequest(import.meta.env.VITE_GET_POSITION_BY_ID + id)
};


export const closePosition = async (id: string, sendEmail: boolean) => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_CLOSE_POSITION + id+"/"+sendEmail, undefined, false, false)
};

export const reopenPosition = async (id: string) => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_OPEN_POSITION + id, undefined, false, false)
};

export const stopAcceptingApplicants = async (id: string) => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_STOP_POSITION + id, undefined, false, false)
};


export const generateJobPost = async (jobPostGeneratorDto: JobPostGeneratorDto): Promise<any> => {
    return await makePostRequest<string>(import.meta.env.VITE_POST_GENERATE_JOB_POST, jobPostGeneratorDto)
}

export const createPosition = async (jb: JobPostGeneratorDto): Promise<IdWrapper> => {
   return await makePostRequest(import.meta.env.VITE_POST_CREATE_POSITION, parsePositionForServer(jb))
}

export const updateDescriptionById = async (id: String, description: String): Promise<any> => {
    return await makePatchRequest<string>(import.meta.env.VITE_UPDATE_DESCRIPTION_BY_ID + `${id}/${description}`)
}

export const rejectApplicant = async (rejectionDto: RejectApplicantDto): Promise<any> => {
    return await makePostRequest(import.meta.env.VITE_POST_REJECT_APPLICANT, rejectionDto)
}

export const updatePosition = async (jb: JobPostGeneratorDto, id: string)=> {
    return await makePutRequest(import.meta.env.VITE_PATCH_UPDATE_POSITION+id ,parsePositionForServer(jb))
}

function parsePositionForServer(jb: JobPostGeneratorDto) {
    if (typeof jb.workplaceType !== "string") jb.workplaceType = getWorkplaceTypeNameUnderscore(jb.workplaceType as WorkplaceType)
    if (typeof jb.employmentType !== "string") jb.employmentType = getEmploymentTypeNameUnderScore(jb.employmentType as EmploymentType)
    if (typeof jb.currency !== null && jb.currency !== "string" ) jb.currency = jb.currency as Currency
    return jb
}

