<script lang="ts">
    import { t } from 'svelte-i18n';
    import { uploadResume } from "../../service/ApplicantService";
    import { pushErrorToast, pushSuccessToast } from "../../service/ToastService";
    import type { ApplicantPageData, PositionPageData } from "../../model/jobs/PositionPageData";
    import Dropzone from "svelte-file-dropzone";
    import LoadingGif from "../Util/Animations/LoadingGif.svelte";

    export let toogle: boolean;
    export let position: PositionPageData;
    export let applicants: ApplicantPageData[];
    export let originalApplicants: ApplicantPageData[];
    export let selectedApplicant;
    export let resultCount: number;
    let showSpinner = false;
    let input: HTMLInputElement;

    let files = {
        accepted: [],
        rejected: []
    };

    $: if (toogle) {
        input.click();
    }

    function closeModal() {
        document.getElementById('upload_applicant_modal').close();
        files.accepted = [];
        toogle = false;
    }

    function handleFilesSelect(e) {
        const { acceptedFiles, fileRejections } = e.detail;
        const totalAccepted = files.accepted.length + acceptedFiles.length;

        if(totalAccepted > 50){
            const remainingSlots = 50 - files.accepted.length;
            const limitedAcceptedFiles = acceptedFiles.slice(0, remainingSlots);

            const exceededFiles = acceptedFiles.slice(remainingSlots)
            files.rejected = [...files.rejected, ...exceededFiles];
            files.accepted = [...files.accepted, ...limitedAcceptedFiles];
        }else{
            files.accepted = [...files.accepted, ...acceptedFiles];
        }

        files.rejected = [...files.rejected, ...fileRejections];
    }

    const confirmUpload = () => {
        if (files.accepted.length > 0) {
            const fileToUpload = files.accepted;
            showSpinner = true;
            uploadResume(position.id, fileToUpload).then(response => {

                const listApplicants = response.applicantListSuccessful;
                const listFailed = response.applicantListFailed;

                for (let i = 0; i < listApplicants.length; i++) {
                    applicants = [listApplicants[i], ...applicants];
                    originalApplicants = [listApplicants[i], ...originalApplicants];
                    resultCount++;
                    selectedApplicant = listApplicants[i];
                    position.applicantCount++;
                }

                showSpinner = false;
                closeModal();

                if(listFailed.length > 0){
                    pushErrorToast($t('ResumeUploadError').replace("{count}",listFailed.length));
                }
                if(listApplicants.length > 0){
                    pushSuccessToast($t('ApplicantUploadedSuccess'));
                }
            }).catch(() => {
                showSpinner = false;
                closeModal();
                pushErrorToast($t('ErrorUploadingResume'));
            });
        } else {
            pushErrorToast($t('NoFileSelected'));
        }
    };
</script>

<input type="hidden" class="btn" onclick="upload_applicant_modal.showModal()" bind:this={input}/>

<dialog id="upload_applicant_modal" class="modal">
    <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg">{$t('UploadResume')}</h3>
        <div class="p-4 border-2 border-dashed border-gray-300 rounded-lg hover:bg-gray-100 hover:border-primary transition-all duration-200">
            <Dropzone on:drop={handleFilesSelect} class="p-4 cursor-pointer text-center">
                <div class="text-gray-600">
                    <p class="mb-2">{$t('DragDropOrClick')}</p>
                    <p class="text-sm text-gray-500">{$t('AcceptedFileTypes')}</p>
                </div>
            </Dropzone>
        </div>
        {#if files.accepted.length > 0}
            {#each files.accepted as file}
            <p class="py-4">{$t('FileSelected')}: {file.name}</p>
            {/each}
        {/if}
        <div class="modal-action">
            <button type="button" class="btn" on:click={closeModal}>{$t('Cancel')}</button>
            <button type="button" class="btn btn-primary" on:click={confirmUpload}>{$t('Upload')}</button>
        </div>
        {#if showSpinner}
          <LoadingGif/>
        {/if}
    </form>
</dialog>

<style>
    .dropzone-wrapper {
        border: 2px dashed var(--daisyui-color-gray-300);
        border-radius: var(--rounded-lg);
        padding: var(--spacing-4);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        text-align: center;
    }

    .dropzone-wrapper:hover {
        background-color: var(--daisyui-color-gray-100);
        border-color: var(--daisyui-color-primary);
    }
</style>
