<div class="spinner-container">
    <span class="loading loading-spinner loading-lg"></span>
</div>
<style>
    .spinner-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading-spinner {
        font-size: 5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

</style>