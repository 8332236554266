<script lang="ts">
    import type {Scorecard} from "../../model/jobs/Scorecard";
    import { t } from 'svelte-i18n';

    export let resumeScorecard: Scorecard;

    const getRatingClass = (rating) => {
        switch (rating) {
            case "GOOD_FIT":
                return "badge-success";
            case "MAYBE":
                return "badge-warning";
            case "BAD_FIT":
                return "badge-error";
            case "REQUIRES_MANUAL_REVIEW":
                return "badge-info";
            default:
                return "badge-ghost";
        }
    };

    const calculatePercentage = (scoredPoints: number, maxPoints: number) => {
        if (!maxPoints || isNaN(maxPoints) || isNaN(scoredPoints)) {
            return 0;
        }
        return (scoredPoints / maxPoints) * 100;
    };
</script>

<div class="card bg-base-200 p-4 mb-4">
    <!-- Title and Rating Badge -->
    <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-bold">{$t('ResumeEvaluation')}</h2>
        <span class={`badge ${getRatingClass(resumeScorecard.rating)}`}>
            {resumeScorecard.rating.replace("_", " ")}
        </span>
    </div>

    <!-- Overall score and conclusion -->
    <div class="mb-6">
        <p class="text-lg font-semibold">Score: {resumeScorecard.score}</p>
        <p class="italic">{resumeScorecard.conclusion}</p>
    </div>

    <!-- Sections with progress bars and criteria -->
    <div class="space-y-6">
        {#each resumeScorecard.sections as section}
            <div class="mb-4">
                <!-- Section title and progress bar -->
                <div class="flex justify-between items-center mb-2">
                    <h3 class="text-lg font-medium">{section.name}</h3>
                    <span>{#if section.maxPoints === 0|| section.scoredPoints === 0}
                       0/0
                        {:else}
                        {section.scoredPoints}/{section.maxPoints}
                    {/if}
                    </span>
                </div>
                <progress class="progress progress-primary w-full" value={calculatePercentage(section.scoredPoints, section.maxPoints)} max="100"></progress>

                <!-- Criteria list -->
                <ul class="mt-2 space-y-2">
                    {#each section.scoreCardCriterias as criteria}
                        <li>
                            <div class="flex justify-between items-center">
                                <p class="font-medium">{criteria.name}</p>
                                <p>
                                    {#if criteria.maxPoints === 0|| criteria.scoredPoints === 0}
                                        0/0
                                    {:else}
                                        {criteria.scoredPoints}/{criteria.maxPoints}
                                    {/if}
                                  </p>
                            </div>
                            <ul class="pl-4 mt-1 list-disc">
                                {#each criteria.items as item}
                                    <li>
                                        <span>{item.name}</span> -
                                        {#if item.maxPoints === 0|| item.scoredPoints === 0}
                                            0/0
                                        {:else}
                                            {item.scoredPoints}/{item.maxPoints}
                                        {/if}
                                        <p class="text-sm italic opacity-75">Reason: {item.reason}</p>
                                    </li>
                                {/each}
                            </ul>
                        </li>
                    {/each}
                </ul>
            </div>
        {/each}
    </div>
</div>
