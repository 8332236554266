<script lang="ts">
    import type { ScreeningQuestion } from "../../../model/jobs/ScreeningQuestion";
    import { t } from "svelte-i18n";

    export let questions: ScreeningQuestion[] = [];
</script>

<div class="mt-6 shadow-lg rounded-lg p-6 bg-base-100">
    <h3 class="text-lg font-semibold mb-4">{ $t('ScreeningQuestions') }</h3>
    <div class="space-y-4">
        {#each questions as question (question.text)}
            <div class="flex flex-col space-y-2">
                <div class="flex items-center">
                    <span class="font-medium text-base">{question.text}</span>
                    {#if question.mustHave}
                        <span class="badge badge-warning ml-2">{ $t('MustHave') }</span>
                    {/if}
                </div>
                <div class="text-sm">
                    <span class="font-semibold">{ $t('IdealAnswer') }:</span> {question.idealAnswer}
                </div>
            </div>
        {/each}
    </div>
</div>
