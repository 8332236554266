<script lang="ts">

    import {onMount} from "svelte";
    import {loadStripe} from "@stripe/stripe-js";
    import {CardCvc, CardExpiry, CardNumber, Elements} from "svelte-stripe";
    import {registerNewUser} from "../../../service/auth/AuthService";
    import type {RegisterDto} from "../../../service/auth/dto/RegisterDto";
    import {pushErrorToast, pushSuccessToast} from "../../../service/ToastService";
    import LoadingAnimation from "../../LoadingAnimation.svelte";
    import {userEmailStore} from "../../../redux/stores";
    import {navigate} from "svelte-routing";
    import { t } from "svelte-i18n";
    import {Turnstile} from "svelte-turnstile";

    export let registerDto: RegisterDto
    let stripe = null;
    let cardElement;
    let name;
    let processing = false;
    let next: () => void

    let token: string | null = null;

    const onCaptchaSuccess = (event: CustomEvent<{ token: string }>) => {
        token = event.detail.token;
    };

    onMount(async () => {
        stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
    });

    async function handlePayment() {
        if (processing) return;
        if (!token){
            pushSuccessToast($t("missingCaptcha"))
            return
        }
        processing = true;

        const {paymentMethod, error} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { name },
        });

        if (error) {
            pushErrorToast($t("Unable to process payment method. Please check your card details and try again."));
            console.error("Error creating payment method:", error);
            processing = false;
            return;
        }

        if (paymentMethod.card.funding=='prepaid'){
            pushErrorToast($t("Hi! We don’t accept prepaid cards. Please use a credit or debit card. Thanks for understanding!"));
            processing = false;
            return
        }


        registerDto.paymentMethodId = paymentMethod.id
        pushSuccessToast($t("Creating your account..."));
        registerDto.captchaToken = token
        await registerNewUser(registerDto)
            .then(() => {
                pushSuccessToast($t("Welcome, {{name}}! Your account has been successfully created.").replace("{{name}}", registerDto.name));
                $userEmailStore = registerDto.email
                if (registerDto.googleToken==undefined){
                    navigate("/email-prompt")
                }
            })
            .catch(() => {
                pushErrorToast($t("Registration failed. Please try again or contact support."));
            })
            .finally(() => {
                processing = false;
            });
    }
</script>

<div class="flex flex-col w-full">
    <!-- Centered form area -->
        <div class="flex flex-col justify-center items-center w-full">
            {#if processing}
                <LoadingAnimation/>
            {/if}
            <form on:submit|preventDefault={handlePayment}>
                <Elements {stripe}>
                    <div class="w-full">
                        <div class="flex flex-grow gap-3 mb-2">
                            <input name="name" class="stripe-input" bind:value={name} placeholder={$t("Your name")} />
                        </div>
                        <div class="mb-2">
                            <CardNumber bind:element={cardElement} classes={{base: 'stripe-input'}} />
                        </div>
                        <div class="flex flex-grow gap-4">
                            <CardExpiry classes={{base: 'stripe-input '}} />
                            <CardCvc classes={{base: 'stripe-input '}} />
                        </div>
                    </div>
                </Elements>
                <div class="flex gap-4">
                    <button disabled={token==null} >{$t("Add Payment Data")}</button>
                    <div class="mt-1">
                        <Turnstile siteKey={import.meta.env.VITE_TURNSTILE_KEY} theme="dark" on:callback={onCaptchaSuccess} />
                    </div>
                </div>
            </form>
        </div>
</div>
<style>
    .error {
        color: tomato;
        margin: 2rem 0 0;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 2rem 0;
    }

    .row {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    input,
    :global(.stripe-input){
        border: solid 1px var(--gray-color);
        padding: 1rem;
        border-radius: 5px;
        background: white;
        font-size: 1.2rem;
        width: 100%;
        color: #1a1a1a;
    }

    .row :global(.stripe-input) {
        flex: 1;
        margin-bottom: 0;
    }

    button {
        font-size: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: -1rem;
        border-radius: 5px;
        border: solid 1px #ccc;
        color: white;
        background: var(--link-color);
        cursor: pointer;
    }
</style>
