import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

export function formatDateToReadable(date1: Date): string {
    const date = new Date(date1) //I have no idea why we need to this.
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatToLocalDate(date: Date): string {
    const newDate = new Date(date)
    if (isNaN(newDate.getTime())) {
       throw Error("Invalid Date")
    }
    return newDate.toISOString().split('T')[0];
}

export function getTimeAgo(aDate: Date): string {
    const now = new Date();
    const date = new Date(aDate);
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const intervals = {
        year: 31536000,  // 365 * 24 * 60 * 60
        month: 2592000,  // 30 * 24 * 60 * 60
        week: 604800,    // 7 * 24 * 60 * 60
        day: 86400,      // 24 * 60 * 60
        hour: 3600,      // 60 * 60
        minute: 60,
        second: 1
    };

    const translate = get(t);

    for (const interval in intervals) {
        const timePeriod = Math.floor(seconds / intervals[interval]);
        if (timePeriod >= 1) {
            const key = `timeAgo_${interval}${timePeriod > 1 ? 's' : ''}`;
            return translate(key).replace('{count}', timePeriod.toString());
        }
    }

    return translate('timeAgo_justNow');
}