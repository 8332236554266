<script lang="ts">
    import { t } from 'svelte-i18n';
    import ApplicantsDatabaseTableRow from "./ApplicantsDatabaseTableRow.svelte";
    import type { ApplicantDBTableData } from "../../../model/applicant/ApplicantDBTableData";
    import type { Page } from "../../../model/Page";

    export let applicants: Page<ApplicantDBTableData>;
</script>

<div class="overflow-x-auto">
    <table class="table w-full">
        <thead>
        <tr>
            <th>{$t('Name')}</th>
            <th>{$t('Email')}</th>
            <th>{$t('LastRole')}</th>
            <th>{$t('LastCompany')}</th>
            <th>{$t('PositionApplied')}</th>
            <th>{$t('YearsOfExperience')}</th>
            <th>{$t('DateApplied')}</th>
            <th>{$t('Actions')}</th>
        </tr>
        </thead>
        <tbody>
        {#each applicants.content as applicant}
            <ApplicantsDatabaseTableRow applicant={applicant} bind:applicants={applicants} />
        {/each}
        </tbody>
    </table>
</div>
