<script lang="ts">
    import {NextStep} from "../../model/applicant/NextStep";

    export let nextStep: NextStep

    function getBadgeColour(){
        switch (nextStep.toString()){
            case NextStep.REJECTED.toString():
                return "error"
            case "WAITING_FOR_SCREENING_INTERVIEW":
                return "primary"
            case NextStep.WAITING_TO_SCHEDULE_SCREENING.toString():
                return "primary"
            default:
                return "neutral"
        }
    }
</script>

<div class="badge badge-{getBadgeColour()}">{nextStep.toString().replaceAll("_", " ")}</div>