<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onMount, onDestroy } from "svelte";
    import type { JobPostGeneratorDto } from "../../model/jobs/JobPostGeneratorDto";
    import { getWorkplaceTypeName } from "../../model/position/WorkplaceType";
    import { getEmploymentTypeName } from "../../model/jobs/EmploymentType";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";
    import TabKeyPrompt from "../Util/Kbd/TabKeyPrompt.svelte";

    export let positionData: JobPostGeneratorDto;
    export let handleNext: () => void;

    let showEnterToNextMsg = false

    export let skills: string[] = [];
    let newSkill: string = '';
    let nextButton: HTMLButtonElement;
    let skillInput: HTMLInputElement;

    function addSkill() {
        if (newSkill && skills.length < 10 && !skills.includes(newSkill)) {
            skills = [...skills, capitalizeFirstLetter(newSkill)];
            newSkill = '';
        }
    }

    function removeSkill(skill: string) {
        skills = skills.filter(s => s !== skill);
    }

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (newSkill) {
                addSkill();
            } else {
                handleNext();
            }
        } else if (event.key === 'Tab') {
            event.preventDefault();
            nextButton.focus();
        }
    }

    function handleNextKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
        }
    }

    function handleNextFocus() {
        showEnterToNextMsg = true
    }

    onMount(() => {
        skillInput.focus();
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-full max-w-3xl bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('SkillsTitle')}</h2>
        <div class="mb-6">
            <p><strong>{$t('JobTitle')}:</strong> {positionData.jobDescription}</p>
            <p><strong>{$t('WorkplaceType')}:</strong> {getWorkplaceTypeName(positionData.workplaceType)}</p>
            <p><strong>{$t('Country')}:</strong> {positionData.location.country}, {positionData.location.city}</p>
            <p><strong>{$t('JobType')}:</strong> {getEmploymentTypeName(positionData.employmentType)}</p>
            <p><strong>{$t('RoleDescription')}:</strong> {positionData.jobDescription}</p>
        </div>
        <p class="text-center mb-6">{$t('SkillsPrompt')}</p>
        <div class="flex flex-wrap gap-2 mb-4">
            {#each skills as skill}
                <span class="badge badge-outline badge-success">
                    {skill} <button class="ml-2 text-error" on:click={() => removeSkill(skill)}>✕</button>
                </span>
            {/each}
        </div>
        <div class="flex mb-4">
            <input
                    type="text"
                    bind:value={newSkill}
                    placeholder={$t('AddSkill')}
                    class="input input-bordered flex-grow mr-2"
                    bind:this={skillInput}
            />
            <button
                    on:click={addSkill}
                    class="btn btn-primary"
                    disabled={skills.length >= 10}
            >
                {$t('Add')}
            </button>
        </div>
        {#if !showEnterToNextMsg || skills.length < 1}
            <EnterToContinue action="add a skill"/>
            {#if skills.length >= 1}
                <div class="flex w-full justify-center items-center mb-4">
                    <span class="ml-2">Or</span>
                </div>
                <TabKeyPrompt action="to continue"/>
            {/if}
        {:else}
            <EnterToContinue/>
        {/if}

        <button
                bind:this={nextButton}
                disabled={skills.length < 1}
                on:click={handleNext}
                on:keydown={handleNextKeydown}
                on:focus={handleNextFocus}
        class="btn btn-primary w-full focus-green"
        >
        {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
    .btn-primary:focus {
        background-color: green;
        color: white;
    }
</style>
