<script lang="ts">
    export let profilePic: string | null;
    export let name: string;
</script>

<div class="avatar">
    <div class="rounded-full w-12 h-12 flex items-center justify-center bg-gray-300 text-xl text-gray-700">
        {#if profilePic}
            <img src={profilePic} alt="Profile Picture" class="rounded-full" />
        {:else}
            <span class="flex items-center justify-center h-full w-full">{name.charAt(0)}</span>
        {/if}
    </div>
</div>

<style>
    .avatar img {
        width: 100%;
        height: 100%;
    }
</style>
