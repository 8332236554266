<script lang="ts">
    import RecruiterManagmentRow from "./RecruiterManagmentRow.svelte";
    import { t } from 'svelte-i18n'; // Import the i18n function

    export let recruiters: Array<any>;
</script>

<div class="overflow-x-auto">
    <table class="table w-full">
        <thead>
        <tr>
            <th>{$t('profile')}</th>
            <th>{$t('name')}</th>
            <th>{$t('email')}</th>
            <th>{$t('birthday')}</th>
            <th>{$t('joined')}</th>
            <th>{$t('access')}</th>
            <th>{$t('status')}</th>
            <th>{$t('actions')}</th>
        </tr>
        </thead>
        <tbody>
        {#each recruiters as recruiter}
            <RecruiterManagmentRow {recruiter} />
        {/each}
        </tbody>
        <br/>
        <br/>
        <br/>
    </table>
</div>
