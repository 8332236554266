<script lang="ts">
    import type {Writable} from "svelte/store";
    import { t } from 'svelte-i18n';

    export let currentStep: Writable<number>;
</script>

<div class="ml-4 w-1/4 flex flex-col space-y-4 mt-5">
    <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full {$currentStep >= 1 || $currentStep >= 4 ? 'bg-primary' : 'bg-base-300'}"></div>
        <span>{$t("AboutYou")}</span>
    </div>
    <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full {$currentStep >= 5 || $currentStep >= 8? 'bg-primary' : 'bg-base-300'}"></div>
        <span>{$t("AboutYourCompany")}</span>
    </div>
    <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full {$currentStep >= 9 ? 'bg-primary' : 'bg-base-300'}"></div>
        <span>{$t("PaymentDetails")}</span>
    </div>
</div>
