import type { CreateRecruiterExistingCompanyDto } from "../service/dto/recruiter/CreateRecruiterExistingCompanyDto";
import { isAgeValid } from "./common/AgeValidation";

import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = (key: string): string => get(t)(key);

export function validateRecruiterData(recruiter: CreateRecruiterExistingCompanyDto): ValidationResult {
    // Name validation
    if (recruiter.name === undefined || recruiter.name.trim().length === 0) {
        return {
            isValid: false,
            message: translate('nameRequired'),
        };
    }

    const namePattern = /^[a-zA-Z\s]+$/;
    if (recruiter.name.length < 2) {
        return {
            isValid: false,
            message: translate('nameMinLength')
        };
    }

    if (recruiter.name.length > 50) {
        return {
            isValid: false,
            message: translate('nameMaxLength'),
        };
    }

    if (!namePattern.test(recruiter.name)) {
        return {
            isValid: false,
            message: translate('namePattern'),
        };
    }

    // Surname validation
    if (recruiter.surname.trim().length === 0) {
        return {
            isValid: false,
            message: translate('surnameEmpty'),
        };
    }

    if (recruiter.surname.length < 2) {
        return {
            isValid: false,
            message: translate('surnameMinLength'),
        };
    }

    if (recruiter.surname.length > 50) {
        return {
            isValid: false,
            message: translate('surnameMaxLength'),
        };
    }

    if (!namePattern.test(recruiter.surname)) {
        return {
            isValid: false,
            message: translate('surnamePattern'),
        };
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(recruiter.email)) {
        return {
            isValid: false,
            message: translate('emailInvalid'),
        };
    }

    const isBirthDate = isAgeValid(recruiter.birthday);
    if (!isBirthDate.isValid) return isBirthDate;

    return {
        isValid: true,
        message: translate('recruiterDataValid'),
    };
}
