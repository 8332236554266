<script lang="ts">
    import { t } from 'svelte-i18n';
    export let action = $t("to continue")
</script>
<div class="flex w-full justify-center items-center mb-4">
    <span class="ml-2">{$t("Press")}</span>
    <span class="ml-2"></span>
    <kbd class="kbd kbd-md">Enter</kbd>
    <span class="ml-2">{action}</span>
</div>
<style>
    .ml-2 {
        margin-left: 0.5rem; /* Adjusts spacing between Enter key and text */
    }
</style>