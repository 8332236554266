<script lang="ts">
    import { t } from "svelte-i18n";
    import {
        fetchApplicantsForPositionPageDataSortedByAge,
        fetchApplicantsForPositionPageDataSortedByLocation,
        fetchApplicantsForPositionPageDataSortedByName,
        fetchApplicantsForPositionPageDataSortedByRelevance,
        fetchApplicantsForPositionPageDataSortedBySurname,
        fetchApplicantsForPositionPageDataSortedByYOE
    } from "../../../service/ApplicantService";
    import { pushErrorToast, pushSuccessToast } from "../../../service/ToastService";

    export let positionId: string;
    export let currentPage: number = 0;

    let currentSort: string = 'relevance';

    // Sorting functions
    function sortByYoe() {
        fetchApplicantsForPositionPageDataSortedByYOE(positionId, currentPage).then(() => {
            currentSort = 'years_of_experience';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }

    function sortByLocation() {
        fetchApplicantsForPositionPageDataSortedByLocation(positionId, currentPage).then(() => {
            currentSort = 'location';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }


    function sortByAge() {
        fetchApplicantsForPositionPageDataSortedByAge(positionId, currentPage).then(() => {
            currentSort = 'age';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }

    function sortByName() {
        fetchApplicantsForPositionPageDataSortedByName(positionId, currentPage).then(() => {
            currentSort = 'name';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }

    function sortBySurname() {
        fetchApplicantsForPositionPageDataSortedBySurname(positionId, currentPage).then(() => {
            currentSort = 'surname';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }

    function sortByRelevance() {
        fetchApplicantsForPositionPageDataSortedByRelevance(positionId, currentPage).then(() => {
            currentSort = 'relevance';
            pushSuccessToast($t('success_message'));
        }).catch(() => {
            pushErrorToast($t('error_message'));
        });
    }
</script>

<div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" tabindex="0">
        {$t(currentSort)}
        <svg fill="none" stroke="currentColor" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
        </svg>
    </button>
    <ul class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
        <li><a href="#" on:click={() => sortByRelevance()}>{$t('relevance')}</a></li>
        <li><a href="#" on:click={() => sortByName()}>{$t('name')}</a></li>
        <li><a href="#" on:click={() => sortBySurname()}>{$t('surname')}</a></li>
        <li><a href="#" on:click={() => sortByAge()}>{$t('age')}</a></li>
        <li><a href="#" on:click={() => sortByLocation()}>{$t('location')}</a></li>
        <li><a href="#" on:click={() => sortByYoe()}>{$t('years_of_experience')}</a></li>
    </ul>
</div>
