const today: Date = new Date();
export const minDate: string = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate()).toISOString().split('T')[0];
export const maxDate18Yoe: string = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0];


export const formatDate = (dateString: string): string=> {
    const dateObj = new Date(dateString);
    return `${dateObj.getMonth() + 1}-${dateObj.getDate()}-${dateObj.getFullYear()}`;
}

export const formatToThousandK = (balance: bigint): string=> {
    const numBalance = Number(balance);
    return numBalance >= 1000 ? `${(numBalance / 1000).toFixed(1)}K` : numBalance.toString();
}

export const getUrlParam = (param:string):  string | null => {

    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    // Use URLSearchParams to get the query parameters
    const searchParams = new URLSearchParams(url.search);

    // Get the 'code' parameter
    return searchParams.get(param);
}

export async function getIpLocation(){
   return await fetch("http://ip-api.com/json");
}