<script lang="ts">
    import UsageGraph from "./billingAndUsage/UsageGraph.svelte";
    import CurrentBalance from "./billingAndUsage/CurrentBalance.svelte";
    import RecentTransactions from "./billingAndUsage/RecentTransactions.svelte";
    import {getBillingDashboardStats} from "../../service/PaymentsService";
    import {breadcrumbs, pageStore, userInfo} from "../../redux/stores";
    import TransactionsPage from "./payments/TransactionsPage.svelte";
    import Landing from "./Landing.svelte";
    import { t } from 'svelte-i18n';

    breadcrumbs.set([{ name: $t('Home'), component: Landing }, { name: $t('BillingUsage'), component: undefined }])

    let selectedCurrency = 'USD'; // Default currency

    if (!$userInfo.isAdmin) pageStore.set(Landing)

</script>

{#await getBillingDashboardStats($userInfo.companyId)}
    <p>...waiting</p>
{:then billingData}
    <div class="usage-billing-dashboard bg-dark-900 text-white min-h-screen px-4 py-8">
        <div class="grid grid-cols-1 md:grid-cols-12 gap-6">
            <div class="md:col-span-8 col-span-1">
               <UsageGraph monthlyUsageChartAstro={billingData.astroStats.monthlyUsageChart} monthlyUsageChartUSD={billingData.usdStats.monthlyUsageChart}  currency={selectedCurrency}/>
            </div>
            <div class="md:col-span-4 col-span-1 flex flex-col space-y-6">
                <button on:click={() => selectedCurrency = selectedCurrency === 'ASTRO' ? 'USD' : 'ASTRO'} class="load-more-astro bg-blue-500 hover:bg-blue-700 text-white text-lg font-bold py-2 px-4 rounded transition ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50">
                    {$t('SwitchTo')} {selectedCurrency === 'ASTRO' ? 'USD' : 'ASTRO'}
                </button>
                 <CurrentBalance astroBalance={billingData.astroStats.monthlyUsage} usdBalance={billingData.usdStats.monthlyUsage} currency={selectedCurrency}/>

              <RecentTransactions recentTransactionsListAstro={billingData.astroStats.recentTransactions}  recentTransactionsListUSD={billingData.usdStats.recentTransactions}  currency={selectedCurrency}/>
                   <button on:click={ ()=> pageStore.set(TransactionsPage)} class="load-more-astro bg-blue-500 hover:bg-blue-700 text-white text-lg font-bold py-2 px-4 rounded transition ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50">
                       {$t('SeeAllUsages')}
               </button>
             </div>
         </div>
     </div>
 {:catch error}
     <p style="color: red">{error.message}</p>
 {/await}



 <style>

 </style>
