import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = get(t);

export function isCompanyNameValid(companyName: string): ValidationResult {
    const companyNamePattern = /^[a-zA-Z0-9&.\-, ]{2,100}$/;

    if (!companyNamePattern.test(companyName)) {
        return {
            isValid: false,
            message: translate('invalidCompanyNamePattern'),
        };
    }

    if (companyName.trim() !== companyName) {
        return {
            isValid: false,
            message: translate('companyNameTrimError'),
        };
    }

    return {
        isValid: true,
        message: translate('companyNameValid'),
    };
}
