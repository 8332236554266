<script lang="ts">
    import {
        cachedApplicantsForPosition,
        getApplicantsByKeyword,
        getApplicantsForPositionPageData
    } from "../../service/ApplicantService";
    import type {ApplicantPageData} from "../../model/jobs/PositionPageData";
    import app from "../../main";

    export let totalPages: number
    export let isSearch: boolean
    export let searchKeyword: string | undefined
    export let positionId: string
    export let applicants: ApplicantPageData[]
    export let toggle: boolean

    let currentPage = 0;

    $: if(!toggle){
        changePage(currentPage)
    }

    async function changePage(pageNumber: number) {
        if (pageNumber >= 0 && pageNumber < totalPages) {
            currentPage = pageNumber;
            if (isSearch){
                let response =  (await getApplicantsByKeyword(searchKeyword!!, positionId, pageNumber))
                applicants = response.content
                totalPages = response.totalPages
            }else {
                let response = (await getApplicantsForPositionPageData(positionId, pageNumber))
                applicants = response.content
                totalPages = response.totalPages
            }

        }
    }
</script>

<div class="join">
    {#each Array.from({ length: totalPages }, (_, i) => i) as page}
        {#if (page === currentPage || (page < 10 && currentPage < 10)) ||
        (page === totalPages - 1 ||
            (page > totalPages - 10 && currentPage > totalPages - 10)) ||
        (Math.abs(page - currentPage) < 2)}
            <button
                    class="join-item btn {page === currentPage ? 'btn-primary' : ''}"
                    on:click={() => changePage(page)}>
                {page + 1}
            </button>
        {:else if (page === currentPage + 2 || page === currentPage - 2)}
            <button class="join-item btn btn-disabled">...</button>
        {/if}
    {/each}
</div>
