<script lang="ts">
    import { t } from 'svelte-i18n';
    import { onMount, onDestroy } from "svelte";
    import type { Location } from "../../model/jobs/Location";
    import CountrySelect from "../Common/CountrySelect.svelte";
    import EnterToContinue from "../Util/Kbd/EnterToContinue.svelte";
    import UpAndDownArrowKeys from "../Util/Kbd/UpAndDownArrowKeys.svelte";

    export let location: Location;
    export let handleNext: () => void;
    let selectElement: HTMLSelectElement;

    onMount(() => {
        document.addEventListener('keydown', handleKeydown);
    });

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeydown);
    });

    function handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (location.country && location.city !== "") {
                handleNext();
            }
        } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            try {
                selectElement.focus();
                selectElement.click();
            }catch (e) {
                //Realmente no importa este error, es para que no se loguee a sentry
            }
        }
    }
</script>

<div class="flex flex-col items-center justify-center min-h-screen bg-base-200">
    <div class="card w-96 bg-base-100 shadow-xl p-6">
        <h2 class="text-2xl font-bold text-center mb-4">{$t('Location')}</h2>
        <p class="text-center mb-6">{$t('CountryPrompt')}</p>
        <UpAndDownArrowKeys/>
        <div class="w-full mb-4">
            <CountrySelect bind:location={location} bind:this={selectElement} />
        </div>
        <div class="w-full mb-4">
            <input
                    type="text"
                    bind:value={location.city}
                    placeholder={$t('EnterCity')}
                    class="input input-bordered w-full"
            />
        </div>
        <br>
        {#if location.country || location.city !== ""}
            <EnterToContinue/>
        {/if}
        <button
                disabled={!location.country || location.city === ""}
                on:click={handleNext}
                class="btn btn-primary w-full"
        >
            {$t('Next')}
        </button>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 100vh;
    }
</style>
