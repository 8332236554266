import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

const translate = get(t);
export function isAgeValid(date: Date) {
    const birthday = new Date(date);

    // Check if the date is valid
    if (isNaN(birthday.getTime())) {
        return {
            isValid: false,
            message: translate('invalidBirthday'), // Using translation key for invalid date
        };
    }

    const today = new Date();
    const age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    const dayDifference = today.getDate() - birthday.getDate();

    // Check if the user is at least 18 years old
    if (age < 18 || (age === 18 && (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))) {
        return {
            isValid: false,
            message: translate('mustBeAtLeast18'), // Using translation key for age validation
        };
    }

    return {
        isValid: true,
        message: translate('ageValid'), // Valid age message
    };
}

// Function to validate day
export function isDayValid(day: number, month: number, year: number) {
    // Check if the month is valid
    if (!isMonthValid(month)) {
        return {
            isValid: false,
            message: translate('invalidMonth'), // Using translation key for invalid month
        };
    }

    // Check for an invalid day
    if (day <= 0) {
        return {
            isValid: false,
            message: translate('invalidDay'), // Using translation key for invalid day
        };
    }

    // Define number of days in each month
    const daysInMonth = [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Check if the day exceeds the days in the given month
    if (day > daysInMonth[month - 1]) {
        return {
            isValid: false,
            message: translate('invalidDayMonth'), // Using translation key for invalid day/month combo
        };
    }

    // If all checks pass, return valid
    return {
        isValid: true,
        message: translate('dateValid'), // Using translation key for valid date
    };
}

// Helper function to check leap year
function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

// Function to validate month
export function isMonthValid(month: number) {
    return !(month <= 0 || month > 12);
}

// Function to validate year
export function isYearValid(year: number) {
    const currentYear = new Date().getFullYear();
    const minimumYear = currentYear - 18; // Recruiter must be at least 18 years old

    if (year > currentYear) {
        return {
            isValid: false,
            message: translate('futureYear'), // Using translation key for future year
        };
    }

    if (year <= 0) {
        return {
            isValid: false,
            message: translate('positiveYear'), // Using translation key for positive year
        };
    }

    if (year > currentYear || year < 1900) {
        return {
            isValid: false,
            message: translate('yearRange').replace('{minimumYear}', minimumYear.toString()), // Dynamic replacement for year range
        };
    }

    return {
        isValid: true,
        message: translate('yearValid'), // Using translation key for valid year
    };
}
