<script lang="ts">
    import { t } from 'svelte-i18n';
    import { Currency } from "../model/jobs/Currency";
    import { EmploymentType } from "../model/jobs/EmploymentType";
    import { WorkplaceType } from "../model/position/WorkplaceType";
    import CountrySelect from "../lib/Common/CountrySelect.svelte";
    import { breadcrumbs, pageStore, positionToEdit } from "../redux/stores";
    import Landing from "../lib/content/Landing.svelte";
    import OpenPositions from "../lib/content/PositionsPage.svelte";
    import ErrorAlert from "../lib/Alerts/ErrorAlert.svelte";
    import { validateJobPositionName } from "../validators/position/PositionNameValidator";
    import { isSalaryValid } from "../validators/position/SalaryValidator";
    import SkillsInputManager from "../lib/Skills/SkillsInputManager.svelte";
    import ScreeningQuestionsFormInput from "../lib/Position/ScreeningQuestionsFormInput.svelte";
    import ConfirmUpdatePositionModal from "../lib/Modals/ConfirmUpdatePositionModal.svelte";
    import PositionDetails from "./PositionDetails.svelte";
    import LanguageFormInput from "../lib/Position/LanguageFormInput.svelte";

    breadcrumbs.set([{ name: 'Home', component: Landing }, {
        name: 'Open Positions',
        component: OpenPositions
    }, { name: 'Position Page', component: PositionDetails }, { name: 'Edit Position', component: undefined }]);

    let jobPost = $positionToEdit;
    if (jobPost.currency == undefined) jobPost.currency = Currency.USD;
    let showModal = false;

    $: isTitleValid = validateJobPositionName(jobPost.jobTitle);
    $: isSalaryValidResult = isSalaryValid(jobPost.minSalary!!, jobPost.maxSalary!!);
    $: isDescriptionValid = jobPost.jobDescription && jobPost.jobDescription.length >= 50;
    $: isRequirementsValid = jobPost.requirements && jobPost.requirements.length >= 50;
    $: isPreferredValid = !jobPost.preferredQualifications || jobPost.preferredQualifications.length >= 50;

    $: areValid = isTitleValid.isValid &&
        isSalaryValidResult.isValid &&
        isDescriptionValid &&
        isRequirementsValid &&
        isPreferredValid;

    const handleSubmit = () => {
        if (areValid) {
            showModal = true;
        } else {
            console.log("Validation errors", {
                title: isTitleValid,
                salary: isSalaryValidResult,
                description: isDescriptionValid,
                requirements: isRequirementsValid,
                preferred: isPreferredValid,
            });
        }
    };
</script>

<div class="p-6 rounded-lg shadow-lg">
    <h2 class="text-2xl font-bold mb-4">{$t('EditJobPost')}</h2>
    <div>
        <!-- Job Title -->
        <div class="mb-4">
            <label for="jobTitle" class="block text-sm font-medium">{$t('JobTitle')}</label>
            <input type="text"
                   id="jobTitle"
                   class="input input-bordered w-full"
                   style="background-color: #1D232A; border: 1px solid #3A3F44;"
                   bind:value={jobPost.jobTitle}
                   required>
            {#if !isTitleValid.isValid}
                <ErrorAlert msg={$t('InvalidTitle')} />
            {/if}
        </div>

        <!-- Job Description -->
        <div class="mb-4">
            <label for="jobDescription" class="block text-sm font-medium">{$t('JobDescription')}</label>
            <textarea id="jobDescription" class="textarea textarea-bordered w-full" rows="5"
                      bind:value={jobPost.jobDescription} required></textarea>
            {#if !isDescriptionValid}
                <ErrorAlert msg={$t('InvalidDescription')} />
            {/if}
        </div>

        <!-- Requirements -->
        <div class="mb-4">
            <label for="requirements" class="block text-sm font-medium">{$t('Requirements')}</label>
            <textarea id="requirements" class="textarea textarea-bordered w-full" rows="5"
                      bind:value={jobPost.requirements} required></textarea>
            {#if !isRequirementsValid}
                <ErrorAlert msg={$t('InvalidRequirements')} />
            {/if}
        </div>

        <!-- Preferred Qualifications -->
        <div class="mb-4">
            <label for="preferredQualifications" class="block text-sm font-medium">{$t('PreferredQualifications')}</label>
            <textarea id="preferredQualifications" class="textarea textarea-bordered w-full" rows="5"
                      bind:value={jobPost.preferredQualifications}></textarea>
            {#if !isPreferredValid}
                <ErrorAlert msg={$t('InvalidPreferred')} />
            {/if}
        </div>

        <!-- Perks and Benefits -->
        <div class="mb-4">
            <label for="perksAndBenefits" class="block text-sm font-medium">{$t('PerksAndBenefits')}</label>
            <textarea id="perksAndBenefits" class="textarea textarea-bordered w-full" rows="5"
                      bind:value={jobPost.perksAndBenefits}></textarea>
        </div>

        <!-- Salary Range -->
        <div class="mb-4 grid grid-cols-2 gap-4">
            <div>
                <label for="minSalary" class="block text-sm font-medium">{$t('MinSalary')}</label>
                <input type="number" id="minSalary" class="input input-bordered w-full"
                       bind:value={jobPost.minSalary}>
            </div>
            <div>
                <label for="maxSalary" class="block text-sm font-medium">{$t('MaxSalary')}</label>
                <input type="number" id="maxSalary" class="input input-bordered w-full"
                       bind:value={jobPost.maxSalary}>
            </div>
        </div>
        {#if !isSalaryValidResult.isValid}
            <ErrorAlert msg={$t('InvalidSalary')} />
        {/if}

        <!-- Currency -->
        <div class="mb-4">
            <label for="currency" class="block text-sm font-medium">{$t('Currency')}</label>
            <select id="currency" class="select select-bordered w-full"
                    bind:value={jobPost.currency} required>
                <option value={Currency.USD} selected>{Currency.USD}</option>
                <option value={Currency.EUR} selected>{Currency.EUR}</option>
                <option value={Currency.ARS} selected>{Currency.ARS}</option>
            </select>
        </div>

        <!-- Employment Type -->
        <div class="mb-4">
            <label for="employmentType" class="block text-sm font-medium">{$t('EmploymentType')}</label>
            <select id="employmentType" class="select select-bordered w-full"
                    bind:value={jobPost.employmentType} required>
                {#each Object.values(EmploymentType) as type}
                    <option value={type}>{type.toString()}</option>
                {/each}
            </select>
        </div>

        <!-- Workplace Type -->
        <div class="mb-4">
            <label for="workplaceType" class="block text-sm font-medium">{$t('WorkplaceType')}</label>
            <select id="workplaceType" class="select select-bordered w-full"
                    bind:value={jobPost.workplaceType} required>
                {#each Object.values(WorkplaceType) as type}
                    <option value={type}>{type.toString()}</option>
                {/each}
            </select>
        </div>

        <!-- Location -->
        <div class="mb-4">
            <label for="location" class="block text-sm font-medium">{$t('Location')}</label>
            <CountrySelect bind:location={jobPost.location} />
        </div>

        <div class="mb-4">
            <label for="city" class="block text-sm font-medium">{$t('City')}</label>
            <input type="text" id="city" class="input input-bordered w-full"
                   bind:value={jobPost.location.city} required>
        </div>

        <!-- Skills -->
        <div class="mb-4">
            <label for="skills" class="block text-sm font-medium">{$t('Skills')}</label>
            <SkillsInputManager bind:skills={jobPost.skills}/>
        </div>

        <!-- Screening Questions -->
        <div class="mb-4">
            <label for="screeningQuestions" class="block text-sm font-medium">{$t('ScreeningQuestions')}</label>
            <ScreeningQuestionsFormInput bind:screeningQuestions={jobPost.screeningQuestions}/>
        </div>
        <div class="mb-4">
            <label for="screeningQuestions" class="block text-sm font-medium">{$t('Please select the language in which the interview will be conducted')}</label>
            <LanguageFormInput bind:language={jobPost.language}/>
        </div>

        <!-- Submit Button -->
        <div class="mt-6">
            <button type="submit" class="btn btn-primary w-full" disabled={!areValid}  on:click={handleSubmit}>{$t('SaveChanges')}</button>
        </div>
        <div class="mt-6">
            <button type="submit" class="btn btn-error w-full"  on:click={() => { pageStore.set(PositionDetails) }}>{$t('Cancel')}</button>
        </div>
    </div>
</div>

<ConfirmUpdatePositionModal bind:toogle={showModal} position={jobPost}/>
