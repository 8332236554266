
import {makeGetRequest, makePatchRequest, makePostRequest} from "./Requests";
import type {Recruiter} from "../model/recruiter/Recruiter";
import type {CreateRecruiterDto} from "./dto/CreateRecruiterDto";
import type {UpdateRecruiterDto} from "./dto/UpdateRecruiterDto";
import type {RegisterCompanyDto} from "./auth/dto/RegisterCompanyDto";



export const updateCompany = async (registerCompanyDto: RegisterCompanyDto): Promise<RegisterCompanyDto> => {
    return await makePatchRequest(import.meta.env.VITE_PATCH_UPDATE_COMPANY,registerCompanyDto)
};