import type {Country} from "../location/Country";
import {formatToTitleCase} from "../../utils/TextUtils";

export interface Location{
    country: Country,
    city: string
}

export function locationToString(location: Location){
    return formatToTitleCase(location.country.toString())+", "+formatToTitleCase(location.city)
}