<script lang="ts">
    export let email: string;
</script>

<input
        bind:value={email}
        type="text"
        placeholder="Email"
        class="input input-bordered w-full mb-2"
/>
