import { get } from 'svelte/store';
import { t } from 'svelte-i18n';

export enum EmploymentType {
    CONTRACT = "CONTRACT",
    SELF_EMPLOYED = "SELF_EMPLOYED",
    PART_TIME= "PART_TIME",
    FULL_TIME= "FULL_TIME",
    INTERNSHIP= "INTERNSHIP",
    APPRENTICESHIP= "APPRENTICESHIP",
    FREELANCE= "FREELANCE",
}

export const employmentTypeMap = new Map<string, string>(
    Object.keys(EmploymentType)
        .filter(key => isNaN(Number(key)))
        .map(key => [key, EmploymentType[key as keyof typeof EmploymentType].toString()])
);

export function getEmploymentTypeName(type: EmploymentType): string {
    const translate = get(t);

    switch (type) {
        case EmploymentType.CONTRACT:
            return translate('EmploymentTypeContract');
        case EmploymentType.SELF_EMPLOYED:
            return translate('EmploymentTypeSelfEmployed');
        case EmploymentType.PART_TIME:
            return translate('EmploymentTypePartTime');
        case EmploymentType.FULL_TIME:
            return translate('EmploymentTypeFullTime');
        case EmploymentType.INTERNSHIP:
            return translate('EmploymentTypeInternship');
        case EmploymentType.APPRENTICESHIP:
            return translate('EmploymentTypeApprenticeship');
        case EmploymentType.FREELANCE:
            return translate('EmploymentTypeFreelance');
        default:
            throw new Error(`Invalid employment type: ${type}`);
    }
}


export function getEmploymentTypeNameUnderScore(type: EmploymentType){
    return getEmploymentTypeName(type).replace("_","_")
}